import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import "./Ticker.css"
import { BiTask } from "react-icons/bi"
import moment from "moment"
import formatDuration from './FormatDuration'
import _ from 'lodash'

function CalendarAnimatedList({nItemsDisplay, maxCalendarItems, todayOnly, currentTime}) {
    const dispatch = useDispatch()
    const [index, setIndex] = useState(0)
    const [animationState, setAnimationState] = useState(1) // 0 = fade out, 1 = idle, 2 = fade in
    const [listToDisplay, setListToDisplay] = useState([])

    const personalCalendar = useSelector(state => state.calendar.personal)
    const dailyCalendar = useSelector(state => state.calendar.daily)

    const getCalendarListToDisplay = () => {
        // join the two calendars and sort them
        /* let joinedCalendar = personalCalendar.concat(dailyCalendar)
        joinedCalendar.sort((a, b) => {
            if (a.start.hasOwnProperty('dateTime') && b.start.hasOwnProperty('dateTime')) {
                return moment(a.start.dateTime) - moment(b.start.dateTime)
            } else if (a.start.hasOwnProperty('dateTime') && !b.start.hasOwnProperty('dateTime')) {
                return moment(a.start.dateTime) - moment(b.start.date)
            } else if (!a.start.hasOwnProperty('dateTime') && b.start.hasOwnProperty('dateTime')) {
                return moment(a.start.date) - moment(b.start.dateTime)
            } else {
                return moment(a.start.date) - moment(b.start.date)
            }
        }) */
        if (personalCalendar == null || personalCalendar.length == 0) {
            return []
        }
        return(personalCalendar.filter(x => {
            if (x.start.hasOwnProperty('dateTime'))
            {
                if (todayOnly) {
                    return moment(x.start.dateTime).isSame(currentTime, 'day') && moment(x.start.dateTime) > currentTime
                }
                return moment(x.start.dateTime) > currentTime
            } else {
                if (todayOnly) {
                    return moment(x.start.date).isSame(currentTime, 'day') && moment(x.start.date) > currentTime
                }
                return moment(x.start.date) > currentTime
            }
        }).slice(0, maxCalendarItems))
    }

    const [data, setData] = useState([
        {text: 'Hello World'},
        {text: 'Hello World 2'},
        {text: 'Hello World 3'},
        {text: 'Hello World 4'},
        {text: 'Hello World 5'}
    ])

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex(index => index + nItemsDisplay)
        }, 7500)
        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        let joinedCalendar = _.cloneDeep(getCalendarListToDisplay())
        // console.log(joinedCalendar.length)
        if (joinedCalendar.length > 0 && joinedCalendar.length > nItemsDisplay) {
            // if joinedCalendar length is not divisible by nItemsDisplay, add empty items to the end
            if (joinedCalendar.length % nItemsDisplay != 0) {
                let nItemsToAdd = nItemsDisplay - (joinedCalendar.length % nItemsDisplay)
                for (let i = 0; i < nItemsToAdd; i++) {
                    joinedCalendar.push({summary: ''})
                }
            }
        }
        if (joinedCalendar.length > 0) {
            if (joinedCalendar.length > nItemsDisplay) {
            // Mark the first item in joinedCalendar with the property 'first'
            joinedCalendar[0].first = true
            // Fade out the current text by setting animationState to 0
            setAnimationState(0)
            // Wait for the fade out animation to finish
            setTimeout(() => {
                // Get props.nItems items from the data array
                let list = []
                if (joinedCalendar.length < nItemsDisplay) {
                    list = joinedCalendar
                } else
                {
                    for (let i = 0; i < nItemsDisplay; i++) {
                        list.push(joinedCalendar[(index + i) % joinedCalendar.length])
                    }
                }
                // Set the list to display
                setListToDisplay(list)
                // Fade in the new text by setting animationState to 2
                setAnimationState(2)
            }
            , 500)
        } else if (joinedCalendar.length >= 1) {
            joinedCalendar[0].first = true
            setListToDisplay(joinedCalendar)
        }
        } else {
            setListToDisplay([])
        }
    }, [index])

    return(
        <>
            {<div className={`${animationState == 0 ? 'TickerOut' : (animationState == 2 ? 'TickerIn' : '')}`}>
                <div style={{listStyle: 'none', padding: 0}}>
                    {listToDisplay.map((item, i) => {
                    if (item.summary == '')
                    {
                        return <div className={`CalendarSidebarEntry`}></div>
                    }
                    return(<div className={`CalendarSidebarEntry`} style={{color: item.hasOwnProperty('first') && item.first ? 'gold' : ''}}>
                        <div><span style={{marginRight: 5}}><BiTask></BiTask></span>{
                        // first 7 characters of item.summary
                        item.summary.length > 12 ? item.summary.slice(0, 12) + '...' : item.summary
                        }</div> 
                        <div style={{flex: 1, flexDirection: 'column'}}>
                            <div>In {item.start.dateTime && formatDuration(currentTime, moment(item.start.dateTime)) + ', at'} {item.start.dateTime && moment(item.start.dateTime).format("H:mm")}</div>
                            <div>{item.start.date && 'In ' + formatDuration(currentTime, moment(item.start.date))}</div>
                        </div>
                    </div>)
                    })}
                </div>
            </div>}
        </>
    )
}

export default CalendarAnimatedList