import React from 'react';
import {AiOutlineTag, AiOutlineFlag, AiFillFlag} from 'react-icons/ai';
import {BiCheck} from 'react-icons/bi';
import ellipsizeText from "./EllipsizeText";

import { markSubSubAsDone } from './MarkAsDone';
import token from './Token';


const ActiveStamp = ({currentSubSub, showFlaggedTasksOnly, flaggedDoneSubTasks, flaggedSubTasks,
                    setSubtasksHighlighted, setHighlightedSubSubHelperMemoized, memoizedFlagSubTask,
                subtasksHighlighted, sortSubSubsWithFlaggedSubSubsFirst,
                    loadTaskHandler, stampId}) => {
    
    const onSubSubClick = async (currentSubSub, it) => {
        console.log(currentSubSub)
        console.log(it)
        await markSubSubAsDone(token, 'default', currentSubSub.sn, currentSubSub.sname, stampId, it.sn, it.name, it.finish)
        if (loadTaskHandler) {
            loadTaskHandler()
        }
    }
    
    return(
        <>
            <div className='SubTaskActive'>
                
                {currentSubSub.hasOwnProperty('subsubs') && sortSubSubsWithFlaggedSubSubsFirst(currentSubSub.subsubs).map((it) => { 
                    if (showFlaggedTasksOnly && (flaggedDoneSubTasks.indexOf(currentSubSub.sname + it.name) > -1 || flaggedSubTasks.indexOf(currentSubSub.sname + it.name) == -1))
                    {
                        return(<></>)
                    } else {
                        return (
                            <div className={`${currentSubSub.sname + '/' + it.name == subtasksHighlighted ? 'highlightedSubtask' : 'subtaskEntry'}  ${it.name.startsWith('*') && (!it.name.startsWith('**')) && (!it.name.startsWith('***')) ? 'green' : 
                            ((it.name.startsWith('**')) && (!it.name.startsWith('***')) ? 'yellow' : (  it.name.startsWith('***') ? 'red' : ''  ))} `}>
                                <span className={`subtaskFlag`} onClick={() => memoizedFlagSubTask(currentSubSub.sname, it.name)}> {flaggedSubTasks.indexOf(currentSubSub.sname + it.name) == -1 ? <AiOutlineFlag></AiOutlineFlag> : (flaggedDoneSubTasks.indexOf(currentSubSub.sname + it.name) == -1 ? <AiFillFlag></AiFillFlag> : <BiCheck></BiCheck>)} </span> <span onClick={() => {it.name == subtasksHighlighted ? setSubtasksHighlighted("") : setHighlightedSubSubHelperMemoized(currentSubSub.sname + '/' + it.name)}} onContextMenu={(e) => {e.preventDefault(); onSubSubClick(currentSubSub, it)}}  >{it.name.replace(/#|\*/g,'')}</span><span style={{opacity: 0.5}}> {it.finish}</span>
                            </div>
                        )
                    }
                    })}   
            </div>
            {currentSubSub.tags && currentSubSub.tags !== '' && <div className="TagAreaOfSubTask">
                    <AiOutlineTag style={{marginRight: 10}}></AiOutlineTag> <span>{
                        currentSubSub.tags? currentSubSub.tags.split(',').map((t) => {
                            return(<span className={`tag ${t}`}>{ellipsizeText(t,3)}</span>)
                        }) : ''
                    }</span>
            </div>}
        </>
    )
}

export default React.memo(ActiveStamp);