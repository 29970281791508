import React, { useState, useEffect, useCallback } from "react";
import { BiTimer } from "react-icons/bi";
import convertToHourMinute from "./ConvertToHourMinute";
import { ProgressBar } from "react-bootstrap";
import { BiTask } from "react-icons/bi";
import { AiOutlineFlag, AiFillFlag, AiOutlineTag, AiOutlineTags } from "react-icons/ai";
import { BiCheck } from "react-icons/bi";
import moment from "moment";
import { MdCalendarToday, MdFeedback, MdTimer } from "react-icons/md";

import ellipsizeText from "./EllipsizeText";
import { getRemainingDays } from "./DaysUntilEvent";

function RemainingTasksHome({ subtasks, currentSubSubsname, showFlaggedTasksOnly, flaggedSubTasks, flaggedDoneSubTasks, flagSubTask, flaggedSubTasksContainsSname, selectSubSub, showOnlyNonZerosAndCountUp, flagAllSubTasks }) {
    const [currentTime, setCurrentTime] = React.useState(moment())
    const [tagInfo, setTagInfo] = React.useState([])

    const hideActiveTaskFromList = (tasks) => {
        let tasksExtended = Object.assign([], tasks)
        return tasksExtended.filter((t) => {
            if (t.sname == currentSubSubsname) {
                return false
            } else {
                return true
            }
        })
    }

    const determiningTheClassNameOfExpiryDate = (expiryDate) => {
        // ensure expiryDate is not null or undefined
        if (expiryDate == null || expiryDate == undefined) {
            return ''
        }
        let d = moment(expiryDate.toDate()).diff(moment(), 'days')
        if (d <= 1) {
            return 'ExpiryDateRed'
        } else if (d <= 7) {
            return 'ExpiryDateYellow'
        } else {
            return 'ExpiryDateGreen'
        }
    }

    const getUniqueTagsFromSubtasks = (subtasks) => {
        let tagName = []
        let tags = []
        if (!subtasks) {
            return []
        }
        subtasks.map((t) => {
            if (t.validFromDate != null) {
                if (moment(t.validFromDate.toDate()).isAfter(moment())) {
                    return // skip this subtask because it is not valid yet (validFromDate is in the future)
                }
            }
            if (t.tags) {
                t.tags.split(',').map((tag) => {
                    if (tagName.indexOf(tag) == -1) {
                        tags.push({
                            tag: tag,
                            remaining: t.finish * 2 * (25 * 60 * 1000) / 60000
                        })
                        tagName.push(tag)
                    } else {
                        tags[tagName.indexOf(tag)].remaining += t.finish * 2 * (25 * 60 * 1000) / 60000
                    }
                })
            }
        })
        return tags
    }

    useEffect(() => {
        let tags = getUniqueTagsFromSubtasks(subtasks)
        setTagInfo(tags)
    }, [subtasks])


    const flagManySubTasks = (subtask) => {
        try {
            let subsubs = subtask.subsubs
            const snames = subsubs.map((s) => {
                return s.name
            })
            flagAllSubTasks(subtask.sname, snames)
        } catch (e) {
            console.log(e)
        }
    }

    return (<>
        {hideActiveTaskFromList(subtasks).map((item) => {
            // if item has countUp property and it is equal to 1, then don't show it

            // if item has validFromDate property and it is in the future, then don't show it
            if (item.hasOwnProperty('validFromDate') && moment(item.validFromDate.toDate()).diff(moment(currentTime), 'second') > 0) {
                return (<></>)
            }

            if (item.hasOwnProperty('countUp') && item.countUp === 1) {
                if (showFlaggedTasksOnly && !flaggedSubTasksContainsSname(item.sname)) {
                    return (<></>)
                }
                return (
                    <>
                        <div className={`SubTaskHome SubTaskHomeRemaining`}>
                            <div className={`${showFlaggedTasksOnly ? "TitleLeft" : "Title"}`} style={{ flexDirection: 'column' }}>

                                <div onClick={() => selectSubSub(item)}>{showFlaggedTasksOnly ? <span style={{ marginRight: 10 }}><BiTask></BiTask></span> : <></>}<span>{item.sname}</span> {item.allowFeedback > 0 ? <MdFeedback></MdFeedback> : <></>}</div>
                                {!showFlaggedTasksOnly && <div>
                                    <span><AiOutlineFlag onClick={() => flagManySubTasks(item)}></AiOutlineFlag> <BiTimer></BiTimer> {item.hasOwnProperty('tuCounted') ? item.tuCounted : ''}</span>
                                    <span>{item.hasOwnProperty("expiryDate") && (<>• <span className={`ExpiryDateSub ${determiningTheClassNameOfExpiryDate(item.expiryDate)}`}><MdCalendarToday></MdCalendarToday> {getRemainingDays(item.expiryDate.toDate()).toFixed(1)}</span></>)
                                    }</span>
                                </div>}


                            </div>
                            {(item.hasOwnProperty("time")) && !showFlaggedTasksOnly ? (<ProgressBar style={{ marginTop: 7 }} now={item.finish < 0 ? 100 : 100 - ((item.finish * 2 * (25 * 60 * 1000) / 60000) / ((item.initialFinish + 0.000001) * 2 * (25 * 60 * 1000) / 60000) * 100)}></ProgressBar>) : (<></>)}
                            {item.hasOwnProperty('subsubs') && item.subsubs.map((it) => {
                                if (showFlaggedTasksOnly && (flaggedDoneSubTasks.indexOf(item.sname + it.name) > -1 || flaggedSubTasks.indexOf(item.sname + it.name) == -1)) {
                                    return (<></>)
                                } else {
                                    return (
                                        <div className={`subtaskEntry ${it.name.startsWith('*') && (!it.name.startsWith('**')) && (!it.name.startsWith('***')) ? 'green' :
                                            ((it.name.startsWith('**')) && (!it.name.startsWith('***')) ? 'yellow' : (it.name.startsWith('***') ? 'red' : ''))}`}>
                                            <span className={`subtaskFlag`} onClick={() => flagSubTask(item.sname, it.name)}> {flaggedSubTasks.indexOf(item.sname + it.name) == -1 ? <AiOutlineFlag></AiOutlineFlag> : (flaggedDoneSubTasks.indexOf(item.sname + it.name) == -1 ? <AiFillFlag></AiFillFlag> : <BiCheck></BiCheck>)} </span> <span> {it.name.replace(/#|\*/g, '')}</span><span style={{ opacity: 0.5 }}> {it.finish}</span>
                                        </div>
                                    )
                                }
                            })}
                            {item.tags && item.tags !== '' && <div className="TagAreaOfSubTask">
                                <AiOutlineTag style={{ marginRight: 10 }}></AiOutlineTag> <span>{
                                    item.tags ? item.tags.split(',').map((t) => {
                                        return (<span className={`tag ${t}`}>{ellipsizeText(t, 3)}</span>)
                                    }) : ''
                                }</span>
                            </div>}
                        </div>
                    </>
                )
            }
            // normal subtask display with time
            if (showFlaggedTasksOnly && !flaggedSubTasksContainsSname(item.sname)) {
                return (<></>)
            }
            if (showOnlyNonZerosAndCountUp && item.finish < 0.000001) {
                return (<></>) // this is not a countup task, and the finish is almost 0 so don't show it
            }
            return (
                <>
                    <div className={`SubTaskHome SubTaskHomeRemaining`}>
                        <div className={`${showFlaggedTasksOnly ? "TitleLeft" : "Title"} ${item.finish < 0 ? "TitleRouge" : ""}`} style={{ flexDirection: 'column' }}>
                            <div onClick={() => selectSubSub(item)}>{showFlaggedTasksOnly ? <span style={{ marginRight: 10 }}><BiTask></BiTask></span> : <></>}<span>{item.sname}</span> {item.allowFeedback > 0 ? <MdFeedback></MdFeedback> : <></>}</div>
                            {!showFlaggedTasksOnly && <div>
                                <span style={{ marginLeft: 5, marginRight: 5 }}><AiOutlineFlag onClick={() => flagManySubTasks(item)}></AiOutlineFlag> <MdTimer></MdTimer> {item.hasOwnProperty("time") && (' ' + convertToHourMinute((item.finish * 2 * (25 * 60 * 1000) / 60000).toFixed(0)))}</span>
                                <span>{item.hasOwnProperty("expiryDate") && (<> • <span className={`ExpiryDateSub ${determiningTheClassNameOfExpiryDate(item.expiryDate)}`}><MdCalendarToday></MdCalendarToday> {getRemainingDays(item.expiryDate.toDate()).toFixed(1)}</span></>)
                                }</span>
                            </div>}


                        </div>
                        {(item.hasOwnProperty("time")) && !showFlaggedTasksOnly ? (<ProgressBar style={{ marginTop: 7 }} now={item.finish < 0 ? 100 : 100 - ((item.finish * 2 * (25 * 60 * 1000) / 60000) / ((item.initialFinish + 0.000001) * 2 * (25 * 60 * 1000) / 60000) * 100)}></ProgressBar>) : (<></>)}
                        {item.hasOwnProperty('subsubs') && item.subsubs.map((it) => {
                            if (showFlaggedTasksOnly && (flaggedDoneSubTasks.indexOf(item.sname + it.name) > -1 || flaggedSubTasks.indexOf(item.sname + it.name) == -1)) {
                                return (<></>)
                            } else {
                                return (
                                    <div className={`subtaskEntry ${it.name.startsWith('*') && (!it.name.startsWith('**')) && (!it.name.startsWith('***')) ? 'green' :
                                        ((it.name.startsWith('**')) && (!it.name.startsWith('***')) ? 'yellow' : (it.name.startsWith('***') ? 'red' : ''))}`}>
                                        <span className={`subtaskFlag`} onClick={() => flagSubTask(item.sname, it.name)}> {flaggedSubTasks.indexOf(item.sname + it.name) == -1 ? <AiOutlineFlag></AiOutlineFlag> : (flaggedDoneSubTasks.indexOf(item.sname + it.name) == -1 ? <AiFillFlag></AiFillFlag> : <BiCheck></BiCheck>)} </span> <span> {it.name.replace(/#|\*/g, '')}</span><span style={{ opacity: 0.5 }}> {it.finish}</span>
                                    </div>
                                )
                            }
                        })}
                        {item.tags && item.tags !== '' && <div className="TagAreaOfSubTask">
                            <AiOutlineTag style={{ marginRight: 10 }}></AiOutlineTag> <span>{
                                item.tags ? item.tags.split(',').map((t) => {
                                    return (<span className={`tag ${t}`}>{ellipsizeText(t, 3)}</span>)
                                }) : ''
                            }</span>
                        </div>}
                    </div>
                </>
            )
        })}
        <div className="TimeRemainingByTag">
            {tagInfo.map((tag) => {
                return (
                    <div className="TimeRemainingByTagEntry">
                        <span className={`tag ${tag}`}>{ellipsizeText(tag.tag, 3)}</span>
                        <span>{convertToHourMinute(tag.remaining)}</span>
                    </div>
                )
            })}
        </div>
    </>)
}

export default React.memo(RemainingTasksHome);