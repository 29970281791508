import moment from 'moment';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button'
import ApiCalendar from 'react-google-calendar-api';
import './Calendar.css'
import { useSelector, useDispatch } from 'react-redux'
import { setPersonal, setDaily, setEventActiveDaily, setEventReadyDaily, addNotification } from './calendarReducers'
import formatDuration from './FormatDuration'

function CalendarMedicine()
{
    // Redux states
    const medicineCalendar = useSelector(state => state.calendar.medicine)
    const dispatch = useDispatch()

    // Internal component states
    const [neTime, setNETime] = useState(null)
    const [neDuration, setNEDuration] = useState(30 * 60 * 1000)
    const [neeTime, setNEETime] = useState(null)
    const [currentTime, setCurrentTime] = useState(moment())
    const [neSummary, setNESummary] = useState('')
    const [readyState, setReadyState] = useState(false)

    const [eventShowing, setEventShowing] = useState(null)

    const setUpFirstEvent = () => {
        // Find the first event that should be displayed
        let searchOn = true
        medicineCalendar.forEach((ev) => {
            if (searchOn)
            {
                let eventStart
                if (ev.start.hasOwnProperty("dateTime"))
                {
                    eventStart = moment(ev.start.dateTime)
                } else {
                    eventStart = moment(ev.start.date)
                }
                if (eventStart > moment())
                {
                    searchOn = false
                    setNETime(eventStart)
                    setNESummary(ev.summary)
                    setEventShowing(ev)
                    try {
                        let minutesToNotifyInAdvance = Number(ev.description.match(/\{([^)]+)\}/)[1])
                        if (minutesToNotifyInAdvance > 0 && minutesToNotifyInAdvance < 6 * 60)
                        {
                            setNEDuration(minutesToNotifyInAdvance * 60 * 1000)
                        }
                    } catch (ex)
                    {
                        console.log(ex)
                        console.log("Will set NE duration to 30 minutes")
                        setNEDuration(30 * 60 * 1000)
                    }
                } // eventStart > moment()
            } // searchOn
        })
    }

    const setUpActiveEvent = () => {
        let searchOn = true
        medicineCalendar.forEach((ev) => {
            let eventStart
            let eventEnd
                if (ev.start.hasOwnProperty("dateTime"))
                {
                    eventStart = moment(ev.start.dateTime)
                } else {
                    eventStart = moment(ev.start.date)
                }
                if (eventStart < moment())
                {
                    if (ev.end.hasOwnProperty("dateTime"))
                    {
                        eventEnd = moment(ev.end.dateTime)
                    } else {
                        eventEnd = moment(ev.end.date)
                    }
                    if ((eventEnd > moment()) && searchOn)
                    {
                        /* console.log('1Set now event')
                        console.log(ev)
                        console.log('---') */
                        setNEETime(eventEnd)
                        searchOn = false
                        // Event is in active
                        
                    }
                }
        })
        if (searchOn)
        {
            /* console.log('0Set now event')
            console.log('---') */
            return
        }   
    }

     // When dailyCalendar changes, choose the first event to display and start the timer
    useEffect(() => {
        if (medicineCalendar.length > 0)
        {
            setUpFirstEvent()
            setUpActiveEvent()
        }
        
    }, [medicineCalendar])

    // When neTime is set (which should be when the event is set to be displayed, start the timer)
    
    useEffect(() => {
        let timerHandler = setInterval(() => {
            setCurrentTime(moment())
        }, 60000)
        
        return () => clearInterval(timerHandler)
    }, [])

    // Resetup first event if the event expires!
    useEffect(() => {
        if (neTime < currentTime)
        {
            setUpFirstEvent()
        }
        if ((neeTime===null) || (neeTime < currentTime))
        {
            setUpActiveEvent()
        }
        try {
            if (neTime.valueOf() - currentTime.valueOf() < neDuration)
            {
                setReadyState(true)
                let descriptionToShow = eventShowing.description
                if (neDuration !== 30 * 60 * 1000)
                {
                    // The description should contain the instruction about the duration to notify in advance
                    descriptionToShow = descriptionToShow.replace(descriptionToShow.match(/\{([^)]+)\}/)[0], "")
                }
                let eventStart
                if (eventShowing.start.hasOwnProperty("dateTime"))
                {
                    eventStart = moment(eventShowing.start.dateTime)
                } else {
                    eventStart = moment(eventShowing.start.date)
                }
                let timeToEvent = 'ST'
                let tag2 = ''
                if (moment() < eventStart)
                {
                    timeToEvent = formatDuration(moment(), eventStart)
                    // if the different between now and eventStart is less than 1 minute
                    let minuteUntilEvent = moment.duration(eventStart.diff(moment())).asMinutes()
                    // console.log('minuteUntilEvent: ' + minuteUntilEvent)
                    if (minuteUntilEvent < 15 && minuteUntilEvent >= 0)
                    {
                        // To blink notification for imminent events
                        tag2 = 'ST' // first level of notification
                        if (minuteUntilEvent < 1)
                        {
                            tag2 = 'ST2' // second level notification
                        }
                    }
                }
                dispatch(addNotification({
                    message: eventShowing.summary,
                    description: descriptionToShow,
                    tag: timeToEvent,
                    tag3: eventStart.toISOString(),

                }))
            } else {
                setReadyState(false)
            }
        } catch (ex)
        {
            // Do nothing
        }
    }, [neTime, neeTime, neDuration, currentTime, eventShowing])

    
    return(
        <></>
    )
}

export default CalendarMedicine