import moment from 'moment'

const paddingTimeWithZeros = (t) => {
    if (t<10)
    {
        return "0" + String(t)
    } else {
        return String(t)
    }
}

const formatDuration = (now, end) => {
    var duration = moment.duration(end.diff(now));
    let minusSign = ""
    if (end < now)
    {
        // console.log("end > now")
        duration = moment.duration(now.diff(end));
        minusSign = "-"
    }

    //Get Days and subtract from duration
    var days = Math.floor(duration.asDays());
    duration.subtract(moment.duration(days,'days'));

    //Get hours and subtract from duration
    var hours = duration.hours();
    duration.subtract(moment.duration(hours,'hours'));

    //Get Minutes and subtract from duration
    var minutes = duration.minutes();
    duration.subtract(moment.duration(minutes,'minutes'));

    //Get seconds
    var seconds = duration.seconds();
    if (days==0)
    {
        // return paddingTimeWithZeros(hours) + ":" + paddingTimeWithZeros(minutes) + ":" + paddingTimeWithZeros(seconds) + ""
        return minusSign + hours + ":" + paddingTimeWithZeros(minutes)
    } else {
        // return days + " d " + paddingTimeWithZeros(hours) + ":" + paddingTimeWithZeros(minutes) + ":" + paddingTimeWithZeros(seconds) + ""
        return minusSign + days + " d " + hours + ":" + paddingTimeWithZeros(minutes)
    }
}

export default formatDuration