import React from 'react'

const ProfilePic = ({ profilePic, username, ordoscopeVersion }) => {

    return(
        <div className='ProfilePicWrapper' onClick={() => {
            if (ordoscopeVersion && ordoscopeVersion !== '' && ordoscopeVersion !== null && ordoscopeVersion !== undefined) {
                window.location.href = `/?scope=${ordoscopeVersion}`;
            } else {
                window.location.href = '/';
            }
        }}>
            <div style={{width: 32, height: 32}}>
                <img src={profilePic} alt="Profile Picture" style={{width: 32, height: 32, borderRadius: 16}} />
            </div>
            <div className='username'>
                {username}
            </div>
        </div>
    )
}

export default React.memo(ProfilePic)