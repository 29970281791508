import { useEffect, useState } from "react";
import db from "./Firebase";
import {
  collection,
  getDocs,
  query,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";
import "./TodoEdit.css"

import Button from "react-bootstrap/Button";
import { AiFillDelete, AiFillCheckCircle, AiFillSave, AiOutlineSortAscending, AiOutlinePlusCircle, AiFillForward } from "react-icons/ai"

import _ from "lodash";
import moment from "moment";

import mqtt from "precompiled-mqtt"
import { Container, Row, Col } from "react-bootstrap";
import token from "./Token";

import mqttUsername from "./MqttUsername"

import Spinner from "react-bootstrap/Spinner";

function TodoEdit() {
  const mqttUpdate = () => {
    const client = mqtt.connect("wss://paymemobile.fr:9001", {
      clientId: "web_" + Math.random().toString(16).substr(2, 8),
      username: "root",
      password: "tQ@*9XXiJZ^G$v"
    });
    // MQTT client setup
    client.on("connect", () => {
      console.log("MQTT connected");
      client.subscribe("control_" + mqttUsername, (err) => {
        if (err) {
          console.log("MQTT subscribe to control channel error: ", err);
        } else {
          client.publish("control_" + mqttUsername, "todos");
          client.end()
        }
      });
    });

    client.on("error", (err) => {
      console.log("MQTT error: ", err);
    });

    client.on("close", () => {
      console.log("MQTT connection closed");
    });
  }

  const [todos, setTodos] = useState([]);
  const [savingStamp, setSavingStamp] = useState(false);

  useEffect(() => {
    // Change document title
    document.title = "Edit Todos";

    // If locked redirect to home
    if (localStorage.getItem('locked') == 'true') {
      window.location.href = '/'
    }
    
    loadTodosFromNewServer();
  }, [])

  const getRandomString = (len) => {
    let randomString = "";
    let randomAscii;
    for (let i = 0; i < len; i++) {
      randomAscii = Math.floor(Math.random() * 25 + 97);
      randomString += String.fromCharCode(randomAscii);
    }
    return randomString;
  }

  const loadTodosFromNewServer = async () => {
    try {
      const response = await fetch("https://paymemobile.fr/allTodos?token=" + token);
      const data = await response.json();
      if (data.hasOwnProperty("todo")) {
        let todos = _.cloneDeep(data.todo)
        todos.forEach((todo) => {
          todo.id = getRandomString(48)
          if (todo.deferUntil === undefined) {
            todo.deferStr = ""
          } else {
            todo.deferStr = moment(todo.deferUntil).format("DD/MM/YYYY HH:mm:ss")
            todo.deferUntil = moment(todo.deferUntil).toDate()
          }
        })
        setTodos(todos)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const loadTodos = async () => {
    console.log("loadTodos");
    const documentSnapshot = await getDoc(doc(db, "todo", "default"))
    let enumeratedTodos = _.cloneDeep(documentSnapshot.data().todo)
    enumeratedTodos.forEach((todo) => {
      todo.id = getRandomString(48)
      if (todo.deferUntil === undefined) {
        todo.deferStr = ""
      } else {
        todo.deferStr = moment(todo.deferUntil.toDate()).format("DD/MM/YYYY HH:mm:ss")
        todo.deferUntil = todo.deferUntil.toDate()
      }
    })
    setTodos(enumeratedTodos)
  }

  const handleTextChange = (todo, e, fieldName) => {
    let mTodo = _.cloneDeep(todos)
    if (fieldName === 'title') {
      // Find the matching todo in mTodo 
      mTodo.forEach((item) => {
        if (item.id === todo.id) {
          item.content = e.target.value
        }
      })
    } else if (fieldName === 'defer') {
      // Find the matching todo in mTodo 
      mTodo.forEach((item) => {
        if (item.id === todo.id) {
          item.deferStr = e.target.value
          try {
            item.deferUntil = moment(e.target.value, "DD/MM/YYYY HH:mm:ss").toDate()
          } catch (err) {
            // do nothing 
          }
        }
      })
    } else if (fieldName === 'notes') {
      // Find the matching todo in mTodo 
      mTodo.forEach((item) => {
        if (item.id === todo.id) {
          item.notes = e.target.value
        }
      })
    }
    setTodos(mTodo)
  }

  const deleteTodo = (todo) => {
    let mTodo = _.cloneDeep(todos)
    // remove the todo from mTodo
    mTodo = mTodo.filter((item) => item.id !== todo.id)
    setTodos(mTodo)
  }

  const addTodo = () => {
    let mTodo = _.cloneDeep(todos)
    mTodo.unshift({
      id: getRandomString(48),
      content: "",
      deferStr: "",
      notes: ""
    })
    setTodos(mTodo)
  }

  const sortTodos = () => {
    let mTodo = _.cloneDeep(todos)
    // Sort the todos by deferUntil
    mTodo.sort((a, b) => {
      if (a.deferUntil === undefined) {
        return 1
      } else if (b.deferUntil === undefined) {
        return -1
      } else {
        return a.deferUntil - b.deferUntil
      }
    })
    setTodos(mTodo)
  }

  const saveTodo = async () => {
    let mTodos = _.cloneDeep(todos)
    // remove id, deferStr and add deferUntil if not exist
    mTodos.forEach((todo) => {
      if (todo.deferStr !== undefined) {
        delete todo.deferStr
      }
      delete todo.id
      if (todo.deferUntil === undefined) {
        todo.deferUntil = moment().toDate()
      }
    })
    let saveObj = { todo: mTodos }

    // Save to firebase
    await setDoc(doc(db, "todo", "default"), saveObj)
    // Update MQTT
    mqttUpdate()
    alert("Saved")
  }

  const saveTodoToNewServer = async () => {
    setSavingStamp(true)
    let mTodos = _.cloneDeep(todos)
    // remove id, deferStr and add deferUntil if not exist
    mTodos.forEach((todo) => {
      if (todo.deferStr !== undefined) {
        delete todo.deferStr
      }
      delete todo.id
      if (todo.deferUntil === undefined) {
        todo.deferUntil = moment().toDate()
      }
    })
    let saveObj = { todo: mTodos, practicalId: 'default' }

    // Save to firebase
    const payload = "data=" + JSON.stringify(saveObj)

    let response = await fetch("https://paymemobile.fr/setAllTodos?token=" + token, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: payload
    })
    let data = await response.json()
    console.log(data)
    // Update MQTT
    mqttUpdate()
    setSavingStamp(false)
  }

  const getTheNext2am = () => {
    let now = moment()
    let next2am = moment().startOf('day').add(2, 'hour')
    if (now.isAfter(next2am)) {
      next2am = next2am.add(1, 'day')
    }
    // return next2am.add(1, 'day') // for tomorrow print
    return next2am // for today print
  }

  function getNext(dayOfWeek) {
    let today = moment();
    if (dayOfWeek === 'MON') {
      let daysUntilMonday = 1 - today.day();
      if (daysUntilMonday <= 0) daysUntilMonday += 7;
      return today.add(daysUntilMonday, 'days').hour(2).minute(0).second(0)
    } else if (dayOfWeek === 'TUE') {
      let daysUntilTuesday = 2 - today.day();
      if (daysUntilTuesday <= 0) daysUntilTuesday += 7;
      return today.add(daysUntilTuesday, 'days').hour(2).minute(0).second(0)
    } else if (dayOfWeek === 'WED') {
      let daysUntilWednesday = 3 - today.day();
      if (daysUntilWednesday <= 0) daysUntilWednesday += 7;
      return today.add(daysUntilWednesday, 'days').hour(2).minute(0).second(0)
    } else if (dayOfWeek === 'THU') {
      let daysUntilThursday = 4 - today.day();
      if (daysUntilThursday <= 0) daysUntilThursday += 7;
      return today.add(daysUntilThursday, 'days').hour(2).minute(0).second(0)
    } else if (dayOfWeek === 'FRI') {
      let daysUntilFriday = 5 - today.day();
      if (daysUntilFriday <= 0) daysUntilFriday += 7;
      return today.add(daysUntilFriday, 'days').hour(2).minute(0).second(0)
    } else if (dayOfWeek === 'SAT') {
      let daysUntilSaturday = 6 - today.day();
      if (daysUntilSaturday <= 0) daysUntilSaturday += 7;
      return today.add(daysUntilSaturday, 'days').hour(2).minute(0).second(0)
    } else if (dayOfWeek === 'SUN') {
      let daysUntilSunday = 7 - today.day();
      if (daysUntilSunday <= 0) daysUntilSunday += 7;
      return today.add(daysUntilSunday, 'days').hour(2).minute(0).second(0)
    }
  }

  const advanceTodos = () => {
    let mTodos = _.cloneDeep(todos)
    let todosTouche = []
    mTodos.forEach((todo) => {
      if (todo.hasOwnProperty("deferUntil")) {
        let next2am = getTheNext2am()
        if (moment(todo.deferUntil).isBefore(next2am)) {
          todo.deferUntil = next2am.toDate()
          todo.deferStr = moment(todo.deferUntil).format("DD/MM/YYYY HH:mm:ss")
          todosTouche.push(todo.content)
        }
      }
    })
    setTodos(mTodos)
    alert("Advanced todos: " + todosTouche.join(", "))
    // sortTodos()
  }

  const modifyTodoDeferShortcut = (todo, param) => {
    let next2am = getTheNext2am()
    let mTodos = _.cloneDeep(todos)
    mTodos.forEach((item) => {
      if (item.id === todo.id) {
        if (param == 'NX') {
          item.deferUntil = moment().toDate()
          item.deferStr = moment(item.deferUntil).format("DD/MM/YYYY HH:mm:ss")
        }
        if (param == '1') {
          item.deferUntil = next2am.toDate()
          item.deferStr = moment(item.deferUntil).format("DD/MM/YYYY HH:mm:ss")
        } else if (param == '+1') {
          item.deferUntil = moment(item.deferUntil).add(1, 'day').hour(2).minute(0).second(0).toDate()
          item.deferStr = moment(item.deferUntil).format("DD/MM/YYYY HH:mm:ss")
        } else if (param == '-1') {
          item.deferUntil = moment(item.deferUntil).subtract(1, 'day').hour(2).minute(0).second(0).toDate()
          item.deferStr = moment(item.deferUntil).format("DD/MM/YYYY HH:mm:ss")
        } else if (param == 'MO') {
          item.deferUntil = getNext('MON').toDate()
          item.deferStr = moment(item.deferUntil).format("DD/MM/YYYY HH:mm:ss")
        } else if (param == 'TU') {
          item.deferUntil = getNext('TUE').toDate()
          item.deferStr = moment(item.deferUntil).format("DD/MM/YYYY HH:mm:ss")
        } else if (param == 'WE') {
          item.deferUntil = getNext('WED').toDate()
          item.deferStr = moment(item.deferUntil).format("DD/MM/YYYY HH:mm:ss")
        } else if (param == 'TH') {
          item.deferUntil = getNext('THU').toDate()
          item.deferStr = moment(item.deferUntil).format("DD/MM/YYYY HH:mm:ss")
        } else if (param == 'FR') {
          item.deferUntil = getNext('FRI').toDate()
          item.deferStr = moment(item.deferUntil).format("DD/MM/YYYY HH:mm:ss")
        } else if (param == 'SA') {
          let nextSaturday = getNext('SAT').toDate()
          item.deferUntil = nextSaturday
          item.deferStr = moment(item.deferUntil).format("DD/MM/YYYY HH:mm:ss")
        } else if (param == 'SU') {
          let nextSunday = getNext('SUN').toDate() // next Sunday
          item.deferUntil = nextSunday
          item.deferStr = moment(item.deferUntil).format("DD/MM/YYYY HH:mm:ss")
        }
      }
    })
    setTodos(mTodos)
  }

  return (
    <div className='AppRootDesktop'><div className='AppRootInnerOverlay'>
      <Container>
        <Row>
          <div className="TodoEdit_actionBar">
            <Container>
              <Row>
                <Col xs={12} md={3}><Button variant="primary" onClick={() => addTodo()}><AiOutlinePlusCircle></AiOutlinePlusCircle> ADD</Button></Col>
                <Col xs={12} md={3}><Button variant="primary" onClick={() => sortTodos()}><AiOutlineSortAscending></AiOutlineSortAscending> ASCENDING</Button></Col>
                <Col xs={12} md={3}><Button variant="primary" onClick={() => { advanceTodos() }}><AiFillForward></AiFillForward> ADVANCE</Button></Col>
                <Col xs={12} md={3}>{!savingStamp && <Button variant="success" onClick={() => saveTodoToNewServer()}><AiFillSave></AiFillSave> SAVE</Button>}</Col>
              </Row>
            </Container>
          </div>

          <div className="TodoEdit">
            <Container>
              {savingStamp && <div className='LoadingIcon'>
                <Spinner animation='border' role='status'></Spinner>
              </div>}
              <Row>
                {todos.map((todo) => {
                  return (
                    <Col xs={12} md={6} className=" TodoEdit_wrapper">
                      <div className="TodoEdit_wrapper_inner">
                        <div className="TodoEdit_title">
                          <input className="content" type="text" value={todo.content} onChange={(e) => handleTextChange(todo, e, 'title')} />
                        </div>
                        <div className="TodoEdit_defer">
                          <input className="defer" type="text" value={todo.deferStr} placeholder="Defer until" onChange={(e) => handleTextChange(todo, e, 'defer')} style={{ marginTop: 8 }} />
                        </div>
                        <div className="TodoEdit_notes">
                          <input className="notes" type="text" value={todo.notes} placeholder="Notes" onChange={(e) => handleTextChange(todo, e, 'notes')} style={{ marginTop: 8 }} />
                        </div>
                        {todo.deferUntil && <div className={`TodoEdit_caption ${moment(todo.deferUntil).isBefore(moment()) ? 'warn' : ''}`}>
                          Due {moment(todo.deferUntil).fromNow()}, {moment(todo.deferUntil).format("dddd")}
                        </div>}
                        <div style={{ marginTop: 8, flexDirection: 'row' }}>
                          <Button variant="dark" onClick={() => { modifyTodoDeferShortcut(todo, 'NX') }}>NX</Button>
                          <Button variant="dark" onClick={() => { modifyTodoDeferShortcut(todo, '+1') }}>+1</Button>
                          <Button variant="dark" onClick={() => { modifyTodoDeferShortcut(todo, '-1') }}>-1</Button>
                          <Button variant="dark" onClick={() => { modifyTodoDeferShortcut(todo, 'MO') }}>MO</Button>
                          <Button variant="dark" onClick={() => { modifyTodoDeferShortcut(todo, 'TU') }}>TU</Button>

                        </div>
                        <div style={{ marginTop: 8, flexDirection: 'row' }}>
                          <Button variant="dark" onClick={() => { modifyTodoDeferShortcut(todo, 'WE') }}>WE</Button>
                          <Button variant="dark" onClick={() => { modifyTodoDeferShortcut(todo, 'TH') }}>TH</Button>
                          <Button variant="dark" onClick={() => { modifyTodoDeferShortcut(todo, 'FR') }}>FR</Button>
                          <Button variant="dark" onClick={() => { modifyTodoDeferShortcut(todo, 'SA') }}>SA</Button>
                          <Button variant="dark" onClick={() => { modifyTodoDeferShortcut(todo, 'SU') }}>SU</Button>
                        </div>
                        <div style={{ marginTop: 8 }}>
                          <Button variant="danger" onClick={() => deleteTodo(todo)}><AiFillDelete></AiFillDelete> DELETE</Button>
                        </div>
                      </div>
                    </Col>
                  )
                })}

              </Row>
            </Container>


          </div>


        </Row>
      </Container>
    </div>
    </div>
  )
}

export default TodoEdit
