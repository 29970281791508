import moment from 'moment';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button'
import ApiCalendar from 'react-google-calendar-api';
import './Calendar.css'
import { useSelector, useDispatch } from 'react-redux'
import formatDuration from './FormatDuration'
import formatDurationWithSeconds from './formatDurationWithSeconds'
import ClockStatus from './ClockStatus';
import { AiOutlineCaretDown } from 'react-icons/ai';

function CalendarNowDaily( { onToggleCalendar })
{
    const imminentCalendarBar = useSelector(state => state.calendar.imminentCalendarBar)
    
    // Redux states
    const eventActiveDaily = useSelector(state => state.calendar.eventActiveDaily)
    const dispatch = useDispatch()
    const globalClock = useSelector(state => state.calendar.globalClock)

    const [currentTime, setCurrentTime] = useState(moment())
    let timerHandler = null

    useEffect(() => {
        if ((eventActiveDaily!==null) && (timerHandler===null))
        {
            timerHandler = setInterval(() => {
                setCurrentTime(moment())
            }, 30000)
        }

        return () => {
            if (timerHandler!==null)
            {
                clearInterval(timerHandler)
                timerHandler = null
            }
        }

        console.log(eventActiveDaily)
    }, [eventActiveDaily])
    
    return(
        <>
            
            { eventActiveDaily!==null &&  
            (<div className={`TextBarNow ${imminentCalendarBar == 'imminent' ? 'TextBarImminent' : (imminentCalendarBar == 'active' ? 'TextBarActive' : '')}`}>
                <div className='Text Left'><AiOutlineCaretDown></AiOutlineCaretDown> {eventActiveDaily !== null && eventActiveDaily.hasOwnProperty('end') && eventActiveDaily.end.hasOwnProperty('dateTime') ? formatDurationWithSeconds(globalClock, moment(eventActiveDaily.end.dateTime)) + ' ' : 'NOW '}</div>
                <div className='Text Right'>• {eventActiveDaily === null ? ' ' : (eventActiveDaily.summary.length > 18 ? eventActiveDaily.summary.substring(0,18) + '...' : eventActiveDaily.summary)}</div>
                <div className='Text Clock'><ClockStatus onToggleCalendar={onToggleCalendar}></ClockStatus></div>
            </div>)
            }
        </>
    )
}

export default CalendarNowDaily
// <div className='Text Left'>{eventActivePersonal !== null ? formatDuration(eventActivePersonal.end.dateTime !== null ? moment(eventActivePersonal.end.dateTime, currentTime ) : moment(eventActivePersonal.end.date, currentTime )) + ' ' : ''} </div>