import './Chip.css';

function Chip(props)
{
    return(
        <div className={`chip ${props.color == 'blue' ? 'chip-blue' : ''} ${props.color == 'red' ? 'chip-red' : ''} ${props.color == 'green' ? 'chip-green' : ''} ${props.color == 'yellow' ? 'chip-yellow' : ''}`}>
            {props.text}
        </div>
    )
}

export default Chip 