export const markTaskAsDone = async (x, token, loadTaskHandler) => {
    // Example: 
    // {
    //     "doneListForSubs": [
    //       {
    //         "sn": "DFMIAG-H4RB0W-XCCSJC-6JM27Q-M0DOQU-70LR9M",
    //         "sname": "Dishes Dinner",
    //         "stampId": "HOUSE",
    //         "finish": 0.5
    //       },
    //       {
    //         "sn": "KGZSFF-ACQBGN-OAYCUE-LV9YA3-JTHAZ2-W7V9FQ",
    //         "sname": "Brush E - Go to bed",
    //         "stampId": "HOUSE",
    //         "finish": 0.5
    //       }
    //     ],
    //     "practicalId": "default",
    //     "username": "thinhhoang"
    //   }
    console.log("marking task as done")
    // console.log(x)
    // Create a doneListForSubs
    let doneListForSubs = {
        doneListForSubs: [
            {
                sn: x.sn,
                sname: x.sname,
                stampId: x.stampId,
                finish: x.finish
            }
        ],
        practicalId: "default"
    }

    
    
    // Create a POST request to the server
    try {
        const response = await fetch('https://paymemobile.fr/appendToDoneListForSubs?token=' + token, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'data=' + JSON.stringify(doneListForSubs)
        });

        if (response.ok) {
            console.log("Task marked as done");
        } else {
            console.error("Failed to mark task as done. Status:", response.status);
            throw new Error("Server responded with an error");
        }
    } catch (error) {
        console.error("Error marking task as done:", error.message);
        alert("Error marking task as done: " + error.message)
    }

    if (loadTaskHandler) {
        try {
            loadTaskHandler();
        } catch (error) {
            console.error("Error in loadTaskHandler:", error.message);
        }
    }

}

export const markSubSubAsDone = async (token, practicalId, sn, sname, stampId, sssn, ssname, ssfinish) => {
    // Create a HTTP POST request to the server
    
    try {
        const response = await fetch('https://paymemobile.fr/appendToDoneList?token=' + token, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'practicalId=' + practicalId + '&sn=' + sn + '&sname=' + sname + '&stampId=' + stampId + '&sssn=' + sssn + '&ssname=' + ssname + '&ssfinish=' + ssfinish
        });

        if (response.ok) {
            console.log("Subsub marked as done");
        } else {
            console.error("Failed to mark subsub as done. Status:", response.status);
            throw new Error("Server responded with an error");
        }
    } catch (error) {
        console.error("Error marking subsub as done:", error.message);
        alert("Error marking subsub as done: " + error.message)
    }
}