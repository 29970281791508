import { useState, useEffect, memo } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import './CalendarDayView.css';

const CalendarDayView = ({ theme='dark', height = 600 }) => {
    const [dayEvents, setDayEvents] = useState([]);
    const [currentTime, setCurrentTime] = useState(moment().toISOString());

    const eventsPersonal = useSelector(state => state.calendar.personal);
    const eventsDaily = useSelector(state => state.calendar.daily);

    const events = eventsPersonal.concat(eventsDaily);

    const colorStrings = [
        'rgba(255, 105, 97, 0.25)',
        'rgba(255, 179, 71, 0.25)',
        'rgba(253, 253, 150, 0.25)',
        'rgba(144, 238, 144, 0.25)',
        'rgba(100, 149, 237, 0.25)',
        'rgba(153, 50, 204, 0.25)',
        'rgba(255, 182, 193, 0.25)'
      ];

    const colorStringsWhiteTheme = [
        'rgba(255, 105, 97, 0.75)',
        'rgba(255, 179, 71, 0.75)',
        'rgba(253, 253, 150, 0.75)',
        'rgba(144, 238, 144, 0.75)',
        'rgba(100, 149, 237, 0.75)',
        'rgba(153, 50, 204, 0.75)',
        'rgba(255, 182, 193, 0.75)'
    ]

    const getColor = (title) => {
        let colorIndex = 0;
        for (let i=0; i<title.length; i++) {
            colorIndex += title.charCodeAt(i);
        }
        if (theme==='white') return colorStringsWhiteTheme[colorIndex % colorStringsWhiteTheme.length];
        return colorStrings[colorIndex % colorStrings.length];
    }

    const convertFromGoogleCalendar = (events) => {
        let newEvents = [];
        events.forEach(event => {
            if (event.start.dateTime == null || event.end.dateTime == null) return;
            // if event is not within 24 hours from now, skip
            if (moment(event.start.dateTime).diff(moment(), 'hours') > 24) return;
            newEvents.push({
                title: event.summary,
                // start: minutes from now
                start: moment(event.start.dateTime).diff(moment(), 'minutes'),
                end: moment(event.end.dateTime).diff(moment(), 'minutes'),
                startDateTime: moment(event.start.dateTime).toISOString(),
                color: getColor(event.summary)
            });
        });
        newEvents = newEvents.sort((a, b) => a.start - b.start);
        newEvents = adjustEventsForOverlap(newEvents);
        return newEvents;
    }

    const adjustEventsForOverlap = (events) => {
        let adjustedEvents = [...events]; // Clone to avoid mutating the original array
    
        for (let i = 0; i < adjustedEvents.length; i++) {
            let overlappingEvents = [adjustedEvents[i]];
    
            // Find overlapping events
            for (let j = i + 1; j < adjustedEvents.length; j++) {
                if (adjustedEvents[j].start < adjustedEvents[i].end) {
                    overlappingEvents.push(adjustedEvents[j]);
                } else {
                    break; // Assumes events are sorted by start time
                }
            }
    
            // Calculate width and left for each overlapping event
            const width = 100 / overlappingEvents.length;
            overlappingEvents.forEach((event, index) => {
                event.width = `${width}%`;
                event.left = `${index * width}%`;
            });
    
            i += overlappingEvents.length - 1; // Skip checked events
        }
    
        return adjustedEvents;
    }
    

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(moment().toISOString());
        }, 30000)
        return () => clearInterval(interval);
    }, [])
    
    useEffect(() => {
        // Set the events from props
        if (events!=null && events.length>0) {
            let e = convertFromGoogleCalendar(events);
            setDayEvents(e);
        } else {
            setDayEvents([
                {
                    title: 'Event 1',
                    start: 10 * 60, // minutes from midnight
                    end: 10 * 60 + 60 // minutes from midnight
                },
                {
                    title: 'Event 2',
                    start: 90, // minutes from midnight
                    end: 150 // minutes from midnight
                }
            ]);
        }
    }, [eventsPersonal, eventsDaily, currentTime]);

    const heightPxPerMinute = height / (24 * 60) * 1.5 // fixed
    
    return (
        <div style={{ height: height, width: '100%', overflow: 'hidden' }}>
            
        {/* <div className="currentTime" style={{ position: 'absolute', top: 0, width: '100%', borderBottom: '1px solid red', zIndex: 10 }}></div>
        <div className="currentTimeClock" style={{ position: 'absolute', top: 0, left: 0, color: 'white', fontSize: 10, backgroundColor: 'red', paddingLeft: 4, paddingRight: 4, borderRadius: '0px 5px 0px 5px', fontWeight: 'bold', zIndex: 10 }}>{moment().format('HH:mm')}</div> */}

        {Array.from({ length: 30 }).map((_, i) => {
            return (
            <div
                key={i}
                style={{
                position: 'absolute',
                top: (60 - moment(currentTime).minutes() + (i-1) * 60) * heightPxPerMinute,
                width: '100%',
                borderBottom: '1px solid rgba(255,255,255,0.1)',
                boxSizing: 'border-box',
                zIndex: 1
                }}
            >
                <div
                style={{
                    position: 'absolute',
                    color: '#999',
                    fontSize: 10,
                    marginTop: -7
                }}
                >
                { (i + moment(currentTime).hours()) % 24 }
                </div>
            </div>
            );
        })}


        {dayEvents.map((event, index) => {
            return (
            <div
                key={index}
                className='CalendarDayViewEvent'
                style={{ 
                top: event.start * heightPxPerMinute,
                height: Math.max((event.end - event.start) * heightPxPerMinute, 15 * heightPxPerMinute),
                backgroundColor: event.color,
                width: event.width, // Adjusted width
                left: event.left, // Adjusted left position
                backdropFilter: 'blur(15px)',
                }}
            >
                <div style={styles.textInEvent}>
                    <div>{event.title} • {moment(event.startDateTime).format('H:mm')}</div>
                </div>
            </div>
            );
        })}
        </div>
    );
}

const styles = {
    textInEvent: {
        fontSize: 12
    }
}

export default memo(CalendarDayView);