import './Keypad.css';
import { useEffect, useState } from 'react';

const Keypad = ({onPassCorrect}) => {
    const [keypadValue, setKeypadValue] = useState('')
    const [keypadScrambled, setKeypadScrambled] = useState('')

    function useStickyState(defaultValue, key) {
        const [value, setValue] = useState(() => {
            const stickyValue = window.localStorage.getItem(key);
            return stickyValue !== null
                ? JSON.parse(stickyValue)
                : defaultValue;
        });
        useEffect(() => {
            window.localStorage.setItem(key, JSON.stringify(value));
        }, [key, value]);
        return [value, setValue];
    }

    const [correctKey, setCorrectKey] = useStickyState('', 'correctKey')

    useEffect(() => {
        // For each character in keypadValue, replace it with a dot
        let scrambled = ''
        for (let i = 0; i < keypadValue.length; i++) {
            scrambled += '•'
        }
        setKeypadScrambled(scrambled)
    }, [keypadValue])

    const handleKeypadClick = (digit) => {
        // If the keypadValue is less than 4, append the digit to the end of the keypadValue
        if (keypadValue.length < 4) {
            setKeypadValue(keypadValue + digit)
        }
        if (keypadValue.length == 3) {
            if (correctKey == '') {
                setCorrectKey(keypadValue + digit)
                alert('Key set!')
            } else {
                if (keypadValue + digit == correctKey) {
                    if (onPassCorrect) {
                        onPassCorrect()
                    }
                    // alert('Correct key!')
                } else {
                    setKeypadValue('')
                }
            }
            
        }
    }

    return(
        <div className="keypad">
            <div className="keypad-digits">
                {keypadScrambled}
            </div>
            <div className="keypad-row">
                <div onClick={() => handleKeypadClick(1)}>1</div>
                <div onClick={() => handleKeypadClick(2)}>2</div>
                <div onClick={() => handleKeypadClick(3)}>3</div>
            </div>
            <div className="keypad-row">
                <div onClick={() => handleKeypadClick(4)}>4</div>
                <div onClick={() => handleKeypadClick(5)}>5</div>
                <div onClick={() => handleKeypadClick(6)}>6</div>
            </div>
            <div className="keypad-row">
                <div onClick={() => handleKeypadClick(7)}>7</div>
                <div onClick={() => handleKeypadClick(8)}>8</div>
                <div onClick={() => handleKeypadClick(9)}>9</div>
            </div>
            <div className="keypad-row">
                <div onClick={() => handleKeypadClick(0)}>0</div>
                <div onClick={() => setKeypadValue('')}>C</div>
            </div>
        </div>
    )
}

export default Keypad;