import moment from 'moment';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button'
import ApiCalendar from 'react-google-calendar-api';
import './Calendar.css'
import { useSelector, useDispatch } from 'react-redux'
import { setPersonal, setDaily, setEventActivePersonal, setEventReadyPersonal, 
    addNotification, setMedicine, setOverlayNotification, setNeTimePersonal } from './calendarReducers'
import formatDuration from './FormatDuration'
import formatDurationWithSeconds from './formatDurationWithSeconds'
import ClockStatus from './ClockStatus';

import axios from 'axios';
import token from './Token';

function CalendarPersonal({ onToggleCalendar })
{
    // Global imminent calendar bar
    const imminentCalendarBar = useSelector(state => state.calendar.imminentCalendarBar)
    const eventReadyPersonal = useSelector(state => state.calendar.eventReadyPersonal)
    
    // Internal component states
    const [signedIn, setSignedIn] = useState(true)
    // Redux states
    const dailyCalendar = useSelector(state => state.calendar.daily)
    const personalCalendar = useSelector(state => state.calendar.personal)
    const calendarUpdateToken = useSelector(state => state.calendar.calendarUpdateToken)
    const globalClock = useSelector(state => state.calendar.globalClock)
    const dispatch = useDispatch()

    // Internal component states
    const [neTime, setNETime] = useState(null)
    const [neDuration, setNEDuration] = useState(30 * 60 * 1000)
    const [neeTime, setNEETime] = useState(null)
    const [currentTime, setCurrentTime] = useState(moment())
    const [neSummary, setNESummary] = useState('')
    const [simultaneousEventTag, setSimultaneousEventTag] = useState('')

    const [nextSync, setNextSync] = useState(moment())
    const [readyState, setReadyState] = useState(false)

    const [eventShowing, setEventShowing] = useState(null)

    const setUpFirstEvent = () => {
        // Find the first event that shouldß be displayed
        let searchOn = true
        personalCalendar.forEach((ev) => {
            if (searchOn)
            {
                let eventStart
                let eventEnd
                let eventDescription = ''
                if (ev.description)
                {
                    eventDescription = ev.description
                }
                if (ev.start.hasOwnProperty("dateTime"))
                {
                    eventStart = moment(ev.start.dateTime)
                } else {
                    eventStart = moment(ev.start.date)
                }
                if (eventStart > moment())
                {
                    searchOn = false
                    if (ev.end.hasOwnProperty("dateTime"))
                    {
                        eventEnd = moment(ev.end.dateTime)
                    } else {
                        eventEnd = moment(ev.end.date)
                    }
                    setNETime(eventStart)
                    dispatch(setNeTimePersonal({neTimePersonal: eventStart.toISOString()}))

                    // Count how many events are within 30 minutes from eventStart
                    let simultaenousEvents = countEventsFrom(eventStart.add(-1, 'second')) // to make sure at least 1 event is counted
                                        
                    if (simultaenousEvents > 1)
                    {
                        setSimultaneousEventTag(' +' + (simultaenousEvents - 1)) // to make sure it is a string
                    } else {
                        setSimultaneousEventTag('')
                    }

                    setNESummary(ev.summary)
                    // console.log('Breakpoint')
                    // console.log(ev)
                    try {
                        let minutesToNotifyInAdvance = Number(eventDescription.match(/\{([^)]+)\}/)[1])
                        if (minutesToNotifyInAdvance > 0 && minutesToNotifyInAdvance < 6 * 60)
                        {
                            setNEDuration(minutesToNotifyInAdvance * 60 * 1000)
                        }
                    } catch (ex)
                    {
                        console.log(ex)
                        // console.log("Will set NE duration to 30 minutes")
                        setNEDuration(30 * 60 * 1000)
                    }
                    // console.log("First event setup")
                    // console.log(ev)
                    setEventShowing(ev)
                    // console.log('---')
                } // eventStart > moment()
            } // searchOn
        })
    }

    const countEventsFrom = (fromTime) => {
        // console.log('Counting events from ' + fromTime.toISOString())
        let maxTime = moment(fromTime).add(30, 'minute')

        let eventCount = 0 
        personalCalendar.forEach((ev) => {
            let eventStart
            if (ev.start.hasOwnProperty("dateTime"))
            {
                eventStart = moment(ev.start.dateTime)
            } else {
                eventStart = moment(ev.start.date)
            }
            if (eventStart > fromTime && eventStart < maxTime)
            {
                eventCount++
            }
        })
        // console.log('Found ' + eventCount + ' events')
        return eventCount
    }

    const notifyUpcomingEvent = () => {
        personalCalendar.forEach((ev) => {
            let eventStart
            let eventEnd
            let eventDescription = ''
            if (ev.description)
            {
                eventDescription = ev.description
            }
            if (ev.start.hasOwnProperty("dateTime"))
            {
                eventStart = moment(ev.start.dateTime)
            } else {
                eventStart = moment(ev.start.date)
            }
            let minutesToNotifyInAdvance = 30
            try {
                minutesToNotifyInAdvance = Number(eventDescription.match(/\{([^)]+)\}/)[1])
                if (minutesToNotifyInAdvance > 0 && minutesToNotifyInAdvance < 6 * 60)
                {
                    // Good! No need to correct the minutesToNotifyInAdvance
                } else {
                    minutesToNotifyInAdvance = 30 // 30 minutes default
                }
            } catch (ex)
            {
                console.log(ex)
                //console.log('But this is fine, the default value of 30 minutes will still be used')
            }
            if (eventStart < moment().add(minutesToNotifyInAdvance, 'minute'))
            {
                // Dispatch a notification if the upcoming event is within "range"
                let descriptionToShow = eventDescription
                try {
                descriptionToShow = eventDescription.replace(eventDescription.match(/\{([^)]+)\}/)[0], "")
                }
                catch (e) {
                    // console.log('The description does not contain any time indicator. Skipping it entirely.')
                }
                let timeToEvent = 'ST'
                let tag2 = ''
                if (moment() < eventStart)
                {
                    timeToEvent = formatDuration(moment(), eventStart)
                    // if the different between now and eventStart is less than 1 minute
                    let minuteUntilEvent = moment.duration(eventStart.diff(moment())).asMinutes()
                    // console.log('minuteUntilEvent: ' + minuteUntilEvent)
                    if (minuteUntilEvent < 15 && minuteUntilEvent >= 0)
                    {
                        // To blink notification for imminent events
                        tag2 = 'ST' // first level of notification
                        if (minuteUntilEvent < 1)
                        {
                            tag2 = 'ST2' // second level notification
                        }
                    }
                } else {
                    // Event has already started, dispatch an overlayNotification
                    // If eventStarted is less than 1 minute ago
                    let minuteSinceEvent = moment.duration(moment().diff(eventStart)).asMinutes()
                    if (minuteSinceEvent < 1)
                    {
                        dispatch(setOverlayNotification({
                            title: 'Event ' + ev.summary + ' has started',
                            message: descriptionToShow,
                            show: true
                        }))
                    }
                }
                // console.log('eventStart', eventStart)
                // console.log('timetoevent', timeToEvent)
                // console.log('description', descriptionToShow)

                dispatch(addNotification({
                    message: ev.summary,
                    description: descriptionToShow,
                    tag: timeToEvent,
                    tag2: tag2,
                    tag3: eventStart.toISOString()
                }))
                
            }
        })
    }

    const setUpActiveEvent = () => {
        let searchOn = true
        personalCalendar.forEach((ev) => {
            let eventStart
            let eventEnd
                if (ev.start.hasOwnProperty("dateTime"))
                {
                    eventStart = moment(ev.start.dateTime)
                } else {
                    eventStart = moment(ev.start.date)
                }
                if (eventStart < moment())
                {
                    if (ev.end.hasOwnProperty("dateTime"))
                    {
                        eventEnd = moment(ev.end.dateTime)
                    } else {
                        eventEnd = moment(ev.end.date)
                    }
                    if ((eventEnd > moment()) && searchOn)
                    {
                        /* console.log('1Set now event')
                        console.log(ev)
                        console.log('---') */
                        setNEETime(eventEnd)
                        searchOn = false
                        // Event is in active
                        dispatch(setEventActivePersonal({
                            event: ev
                        }))
                    }
                }
        })
        if (searchOn)
        {
            /* console.log('0Set now event')
            console.log('---') */
            dispatch(setEventActivePersonal({
                event: null
            }))
            return
        }   
    }

     // When dailyCalendar changes, choose the first event to display and start the timer
    useEffect(() => {
        if (personalCalendar.length > 0)
        {
            setNEETime(null) // If the calendar is updated after an event has been set active (now it is not active anymore), NEETime needs to be cleared (since it is only set in the setupActiveEvent function). Clearing this allows the setupActiveEvent function to set the new active event (the second useEffect)
            setUpFirstEvent()
            setUpActiveEvent()
        }
    }, [personalCalendar])

    // When neTime is set (which should be when the event is set to be displayed, start the timer)
    const [timerHandler, setTimerHandler] = useState(null)

    useEffect(() => {
        // console.log('Timer status: ', timerHandler)
        let t = null
        
        // console.log('Timer started')
        t = setInterval(() => {
            setCurrentTime(moment())
        }, 30000)
        // setTimerHandler(t)
        
        return () => clearInterval(t)
    }, [])

    // Resetup first event if the event expires!
    useEffect(() => {
        if (neTime < currentTime)
        {
            setUpFirstEvent()
        }
        if ((neeTime===null) || (neeTime < currentTime))
        {
            setUpActiveEvent()
        }
        // console.log(currentTime.format("HH:mm:ss"))
        try
        {
        // console.log(nextSync.format("HH:mm:ss"))
        } catch (e) {
            console.log(e)
        }
        // Sync the calendar again after x minutes
        try{
            if (currentTime > nextSync)
            {
                getEvents()
                setNextSync(moment().add(5, 'minute'))
            }
        } catch (e)
        {
            console.log(e)
        }
        // If it's about time, set the "event ready" (green calendar event text)
        try {
            if (neTime.valueOf() - currentTime.valueOf() < neDuration)
            {
                setReadyState(true)
                dispatch(setEventReadyPersonal({event: eventShowing}))
                
                /*let descriptionToShow = eventShowing.description
                if (neDuration !== 30 * 60 * 1000)
                {
                    // The description should contain the instruction about the duration to notify in advance
                    descriptionToShow = descriptionToShow.replace(descriptionToShow.match(/\{([^)]+)\}/)[0], "")
                }
                let eventStart
                if (eventShowing.start.hasOwnProperty("dateTime"))
                {
                    eventStart = moment(eventShowing.start.dateTime)
                } else {
                    eventStart = moment(eventShowing.start.date)
                }
                let timeToEvent = 'ST'
                if (moment() < eventStart)
                {
                    timeToEvent = formatDuration(moment(), eventStart)
                }
                dispatch(addNotification({
                    message: eventShowing.summary,
                    description: descriptionToShow,
                    tag: timeToEvent
                }))*/
            } else {
                setReadyState(false)
                dispatch(setEventReadyPersonal({event: null}))
            }
            // Try to dispatch notifications for upcoming events
            notifyUpcomingEvent()
        } catch (ex)
        {
            // Do nothing
        }
    }, [neTime, neeTime, currentTime, nextSync, eventShowing, neDuration])

    useEffect(() => {
        getEvents()
    }, [calendarUpdateToken])

    const handleCalendarSignIn = (name) => {
        if (name === 'sign-in') {
            setSignedIn(true)
        }
        // The following code is not used, since we have switched to PMM instead of Google Calendar API
            /* ApiCalendar.handleAuthClick()
            .then(() => {
              console.log('sign in succesful!');
              setSignedIn(true)
              getEvents()
            })
            .catch((e) => {
              console.error(`sign in failed ${e}`);
            })
          } else if (name === 'sign-out') {
            ApiCalendar.handleSignoutClick();
          } */
    }

    const getEvents = () => {
        // getEventsLegacy() // via Google Calendar API
        setNextSync(moment().add(5, 'minute'))
        getEventsViaPmm() // via PMM
    }

    const getEventsViaPmm = () => {
        // Ajax call to http://paymemobile.fr:443/hdinhthinh and get JSON
        axios.get('https://paymemobile.fr/hdinhthinh?token='+token)
            .then(response => {
                // console.log(response.data);
                let events = response.data
                dispatch(setPersonal(
                    {
                        'calendar': events
                    }
                ))
            }
        ).catch(error => {
            console.log(error)
        })

        // Ajax call to http://paymemobile.fr:443/housekeeping and get JSON
        axios.get('https://paymemobile.fr/housekeeping?token='+token)
            .then(response => {
                // console.log(response.data);
                let events = response.data
                dispatch(setDaily(
                    {
                        'calendar': events
                    }
                ))
            }
        ).catch(error => {
            console.log(error)
        })

        // Ajax call to http://paymemobile.fr:443/medicine and get JSON
        axios.get('https://paymemobile.fr/medicine?token='+token)
            .then(response => {
                // console.log(response.data);
                let events = response.data
                dispatch(setMedicine(
                    {
                        'calendar': events
                    }
                ))
            }
        ).catch(error => {
            console.log(error)
        })
    }

    const getEventsLegacy = () => {
        setNextSync(moment().add(5, 'minute'))
        if (ApiCalendar.sign)
        {
            // Personal calendar
            ApiCalendar.listUpcomingEvents(10, 'hdinhthinh@gmail.com').then(({ result }) => {
                let events = result.items
                // console.log(events)
                dispatch(setPersonal(
                    {
                        'calendar': events
                    }
                ))
            });
            // Daily calendar
            ApiCalendar.listUpcomingEvents(10, 'jc10b5l3ket6odaqqd9gom0lv4@group.calendar.google.com').then(({ result }) => {
                let events = result.items
                //console.log('Daily calendar')
                //console.log(events)
                // setAllEvents(events)
                dispatch(setDaily(
                    {
                        'calendar': events
                    }
                ))
            });
            // Medicine calendar
            ApiCalendar.listUpcomingEvents(10, '2jrc2b39uuq8a1krjcoq6shrhs@group.calendar.google.com').then(({ result }) => {
                let events = result.items
                //console.log('Daily calendar')
                //console.log(events)
                // setAllEvents(events)
                dispatch(setMedicine(
                    {
                        'calendar': events
                    }
                ))
            });
        }
    }
    
    return(
        <>
            {!signedIn && 
            (<div className={`TextBar`}>
                <div className='Text Left' onClick={() => handleCalendarSignIn('sign-in')}>Sign In</div>
                <div className='Text Right'><span>• PayMe Ecosystems</span></div>
                <div className='Text Clock'><ClockStatus onToggleCalendar = {onToggleCalendar}></ClockStatus></div>
            </div>)}
            {signedIn && 
            (<div className={`${ readyState ? "TextBarReady" : "TextBar"} ${imminentCalendarBar == 'imminent' ? 'TextBarImminent' : (imminentCalendarBar == 'active' ? 'TextBarActive' : '')}`}>
                <div className='Text Left'>{neTime !== null ? (eventReadyPersonal !== null ? formatDurationWithSeconds(globalClock, neTime) : formatDuration(currentTime, neTime)) + ' ' : ''} </div>
                <div className='Text Right'>• {(neSummary && neSummary.length > 18)? neSummary.substring(0,18) + '...' : neSummary} {simultaneousEventTag}</div>
                <div className='Text Clock'><ClockStatus onToggleCalendar = {onToggleCalendar}></ClockStatus></div>
            </div>)
            }
        </>
    )
}

export default CalendarPersonal