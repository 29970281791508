import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import "./Ticker.css"
import moment from 'moment';
import Chip from './Chip';

function Ticker(props) {
    const dispatch = useDispatch()
    const [index, setIndex] = useState(0)
    const [animationState, setAnimationState] = useState(0) // 0 = fade out, 1 = idle, 2 = fade in
    const [textToDisplay, setTextToDisplay] = useState('')
    const [daysUntil, setDaysUntil] = useState(0)
    const [thereIsSomethingToShow, setThereIsSomethingToShow] = useState(false)
    const [totalItems, setTotalItems] = useState(0)

    /* const [data, setData] = useState([
        {text: 'Hello World'},
        {text: 'Hello World 2'},
        {text: 'Hello World 3'},
        {text: 'Hello World 4'},
        {text: 'Hello World 5'}
    ]) */

    useEffect(() => {
        if (window.goalSlideshowTimer){
            clearInterval(window.goalSlideshowTimer)
            window.goalSlideshowTimer = undefined
        }
        if (totalItems > 1) { // start the slideshow only if there are more than 1 items
            window.goalSlideshowTimer = setInterval(() => {
                setIndex(index => index + 1)
            }, 5000)
        }
        return () => { 
            if (window.goalSlideshowTimer)
            {
                clearInterval(window.goalSlideshowTimer) 
                window.goalSlideshowTimer = undefined
            }
        }
    }, [totalItems])

    useEffect(() => {
        if (props.data && props.data.length > 0) {
            let props_data = []
            // caution: if done in the props, it will rerender. Workaround: use useMemo() for the filter if it is done in the props
            // otherwise, leave it here like this
            props_data = props.data.filter((t) => {
                // Filter all todos starting with *
                if (t.content.startsWith('*'))
                {
                    return true
                } else {
                    return false
                }
            })
            // console.log(props_data)
            if (props.onlyXDaysFromNow) {
                props_data = props_data.filter(x => {
                    let daysUntil = moment(x.deferUntil).diff(moment(), 'days')
                    return daysUntil <= props.onlyXDaysFromNow
                })
            }
            if (props_data.length > 0) {
                setThereIsSomethingToShow(true)
            } else {
                setThereIsSomethingToShow(false)
            }
            setTotalItems(props_data.length)
            // Fade out the current text by setting animationState to 0
            setAnimationState(0)
            // Wait for the fade out animation to finish
            setTimeout(() => {
                // Set the text to display to the next text in the array
                // Remove all * from the text
                let daysUntil = moment(props_data[index % props_data.length].deferUntil).diff(moment(), 'days')
                setDaysUntil(daysUntil)
                // console.log(props_data[index % props_data.length])
                setTextToDisplay(props_data[index % props_data.length].content.replace(/\*/g, ''))
                // Fade in the new text by setting animationState to 2
                setAnimationState(2)
            }
            , 500)
        } else {
            setThereIsSomethingToShow(false)
        }
    }, [index, props.data, props.onlyXDaysFromNow])

    return(
        <>
            {thereIsSomethingToShow && (<div className={`${animationState == 0 ? 'TickerOut' : (animationState == 2 ? 'TickerIn' : '')}`}><Chip text={daysUntil.toString()} color={
                (() => {
                    if (daysUntil < 7)
                    {
                        return 'red'
                    } else {
                        return 'green'
                    }
                }
                )()
            }></Chip><span style={{paddingLeft: 6}}></span>{textToDisplay}</div>)}
        </>
    )
}

export default React.memo(Ticker)