import React from 'react';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import { MdFeedback } from 'react-icons/md';

const TimerControlHistory = ({ timerHistory, deleteHistory }) => {

    const formatTimerWithoutComparisonToCalendar = (t) => {
        return moment.utc(Math.abs(t)).format('m')
    }
    
    return(
        <>
            {timerHistory.map((it) => {
                return(
                    <div className='TimerHistoryEntry'>
                        <div className="TimerHistoryTitle">Subtask: {it.subsub} {it.allowFeedback > 0 ? <MdFeedback></MdFeedback> : <></>}</div>
                        <div>Done: {moment(it.done).format("HH:mm:ss")}</div>
                        <div>Duration: {formatTimerWithoutComparisonToCalendar(it.duration)}m</div>
                        <div>Note: {it.note}</div>
                        <Button variant="danger" onClick={() => deleteHistory(it)}>Delete</Button>
                    </div>
                )
            })}
        </>
    )

}

export default React.memo(TimerControlHistory);