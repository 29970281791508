import moment from 'moment';
import { useEffect, useState } from 'react';
import './Calendar.css'
import ClockStatus from './ClockStatus';

function CalendarEmpty()
{
    
    return(
        <div className={`TextBar`}>
            <div className='Text NoEvent'>No Events</div>
            <div className='Text Clock'><ClockStatus></ClockStatus></div>
        </div>
    )
}

export default CalendarEmpty
// <div className='Text Left'>{eventActivePersonal !== null ? formatDuration(eventActivePersonal.end.dateTime !== null ? moment(eventActivePersonal.end.dateTime, currentTime ) : moment(eventActivePersonal.end.date, currentTime )) + ' ' : ''} </div>