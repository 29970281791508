import axios from 'axios';
import token from './Token'

import moment from 'moment'

export const queryNeedToFeedback = async (taskIds) => {
    const url = 'https://paymemobile.fr/doINeedToSave';
    const queryParams = "taskId=" + taskIds + "&datetime=" + moment().toISOString() + "&token=" + token

    // console.log('Querying need to feedback with params: ', params)

    try {
        const response = await fetch(url + "?" + queryParams, {
            method: 'GET', // or 'PUT'
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        let data = await response.json()
        // const response = await axios.get(url, { params });
        // console.log("FeedbackHelpers.js: queryNeedToFeedback: response: ", response)
        // console.log(response)
        // console.log(response.data)
        // Handle the response data here
        return data
    } catch (error) {
        // Handle the error here
        console.error(error);
        return false;
    }
};
