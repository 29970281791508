import moment from 'moment';
import React, { useEffect, useState } from 'react';
import './CalendarDescr.css'
import { useSelector, useDispatch } from 'react-redux'
import { addNotification, dismissNotification } from './calendarReducers'
import DivWithProgress from './DivWithProgressNew';
import formatDurationWithSeconds from './formatDurationWithSeconds';

function Notifications({showSeconds})
{
    // Redux states
    const notis = useSelector(state => state.calendar.notifications)
    const globalClock = useSelector(state => state.calendar.globalClock)

    const dispatch = useDispatch()

    const dismissNoti = (message) => {
        dispatch(dismissNotification({
            message: message
        }))
    }

    const timeTommss = (time) => {
        return formatDurationWithSeconds(moment(), moment(time))
    }

    const addNoti = () => {
        dispatch(addNotification({
            'message': 'Blue',
            'description': 'Blue',
            'tag': '0:25',
            'tag2': '', // blinking if tag2 = 'ST2',
            'tag3': moment().add(25, 'minute').toISOString()
        }))

        dispatch(addNotification({
            'message': 'Yellow',
            'description': 'Yellow',
            'tag': '0:15',
            'tag2': '', // blinking if tag2 = 'ST2',
            'tag3': moment().add(15, 'minute').toISOString()
        }))

        dispatch(addNotification({
            'message': 'Red',
            'description': 'Red',
            'tag': '0:05',
            'tag2': '', // blinking if tag2 = 'ST2',
            'tag3': moment().add(5, 'second').toISOString()
        }))

        dispatch(addNotification({
            'message': 'Red2',
            'description': 'Red2',
            'tag': 'ST',
            'tag2': '', // blinking if tag2 = 'ST2',
            'tag3': moment().add(-9, 'minute').add(-50, 'second').toISOString()
        }))

        dispatch(addNotification({
            'message': 'Blue2',
            'description': 'Blue2',
            'tag': 'ST',
            'tag2': '', // blinking if tag2 = 'ST2',
            'tag3': moment().add(-15, 'minute').toISOString()
        }))
    }

    useEffect(() => {
        // addNoti()
    }, [])

    // handle the notification color logic
    const getDressing = (tag, tag2, tag3, showSeconds) => {
        let dressing = {
            color: 'blue',
            blinkBorder: false,
            borderColor: 'blue',
            portionFilled: 0
        }

        // blinking when tag2 = 'ST2'
        if (tag2 === 'ST2')
        {
            dressing.blinkBorder = true
        }

        // compute portionFilled
        let portionFilled = 0.9999
        if (tag !== 'ST')
        {
            // Split h:mm to h and m
            let time = tag.split(':')
            let sign = time[0].charAt(0) === '-' ? -1 : 1
            let hour = parseInt(time[0].replace('-', ''))
            let minute = parseInt(time[1])
            let minutes = sign * (hour * 60 + minute)
            if (minutes > 0 && minutes < 30)
            {
                portionFilled = 1 - minutes / 30
            } else if (minutes >= 30)
            {
                portionFilled = 0
            } else {
                // minutes <= 0
                portionFilled = 0.9999
            }
        } else {
            // tag === 'ST'
            portionFilled = 0.9999
        }
        dressing.portionFilled = portionFilled

        // compute color
        if (!showSeconds)
        {
            // Legacy appearance, without showing seconds
            dressing.color = 'blue'
            dressing.borderColor = 'blue'
        } else {
            // showSeconds
            if (portionFilled >= 0.667 && portionFilled <= 1)
            {
                if (tag3 && moment(tag3).add(10, 'minute') < moment())
                { 
                    // 10 minutes have passed since the tag3
                    dressing.color = 'blue'
                    dressing.borderColor = 'blue'
                } else if (tag3 && moment(tag3).add(10, 'minute') >= moment())
                {
                    // 10 minutes have not passed since the tag3
                    dressing.color = 'red'
                    dressing.borderColor = 'red'
                } else {
                    // tag3 is not defined
                    dressing.color = 'blue'
                    dressing.borderColor = 'blue'
                }
            } else if (portionFilled > 0.333 && portionFilled <= 0.667)
            {
                dressing.color = 'yellow'
                dressing.borderColor = 'yellow'
            } else if (portionFilled <= 0.333 || portionFilled >= 1)
            {
                dressing.color = 'blue'
                dressing.borderColor = 'blue'
            } else {
                dressing.color = 'blue'
                dressing.borderColor = 'blue'
            }
        }
        return dressing
    }

    const getDressingMemo = React.useCallback(getDressing, [])

    const getDescrClassName = (color) => {
        let className = ''
        if (color === 'red')
        {
            className += ' dwp-desc-border-red'
        } else if (color === 'yellow')
        {
            className += ' dwp-desc-border-yellow'
        } else if (color === 'blue')
        {
            className += ' dwp-desc-border-blue'
        }
        return className
    }

    const getDescrClassNameMemo = React.useCallback(getDescrClassName, [])

    const getNotificationText = (tag, tag2, tag3, message, showSeconds) => {
        if (!showSeconds)
        {
            return message + ' (' + tag + ')'
        }

        if (!tag3) {
            return message + ' (' + tag + ')'
        }

        if (tag3 && (moment(tag3).add(-10, 'minute') <= moment()) && (moment() <= moment(tag3).add(10, 'minute'))) {
            return message + ' (' + timeTommss(tag3) + ')'
        } else if (tag3 && moment(tag3).add(10, 'minute') < moment()) {
            return message // no time required if 10 minutes have passed since the tag3 and if tag3 is defined
        }
        else {
            return message + ' (' + tag + ')'
        }
        
    }

    const getNotificationTextMemo = React.useCallback(getNotificationText, [])
    
    return(
        <>
        
        {notis.filter((n) => (!n.dismissed)).map((n) => 
        { 
            let dressing = getDressingMemo(n.tag, n.tag2, n.tag3, showSeconds)
            return(
                // New appearance, showing seconds if portionFilled >= 0.75
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 5}}>
                <div className={`NotificationBar ${dressing.blinkBorder ? 'BlinkingNotification' : ''}`}>
                    <div className='TextBarNotiCentered' onClick={() => {dismissNoti(n.message)}}>
                        <DivWithProgress p={dressing.portionFilled} text={getNotificationTextMemo(n.tag, n.tag2, n.tag3, n.message, showSeconds)} color={dressing.color}></DivWithProgress>
                        
                        <div className={`DetailsCalWithoutMargin ${getDescrClassNameMemo(dressing.color)}`} style={{display: n.description ? 'block' : 'none'}}>
                        {n.description ? <div className='NotiDescription' dangerouslySetInnerHTML={{__html: n.description}}></div> : "No description"}
                        </div>

                    </div>
                </div>
            </div>
            )
        })}
        </>
    )
}

export default React.memo(Notifications)