import { useEffect, useState } from 'react';
import axios from 'axios';

function CalendarPersonalPMM (props)
{
    useEffect(() => {
        // Ajax call to http://paymemobile.fr:443/hdinhthinh and get JSON
        axios.get('http://paymemobile.fr:443/hdinhthinh')
            .then(response => {
                console.log(response.data);
            }
        );
    }, []);

    return(
        <></>
    )
}

export default CalendarPersonalPMM