import moment from 'moment';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button'
import ApiCalendar from 'react-google-calendar-api';
import './Calendar.css'
import { useSelector, useDispatch } from 'react-redux'
import { setPersonal, setDaily, setEventActiveDaily, setEventReadyDaily, addNotification,
setOverlayNotification, setNeTimeDaily } from './calendarReducers'
import formatDuration from './FormatDuration'
import ClockStatus from './ClockStatus';
import formatDurationWithSeconds from './formatDurationWithSeconds';

function CalendarDaily({ onToggleCalendar })
{
    const imminentCalendarBar = useSelector(state => state.calendar.imminentCalendarBar)
    const eventReadyDaily = useSelector(state => state.calendar.eventReadyDaily)

    // Redux states
    const dailyCalendar = useSelector(state => state.calendar.daily)
    const personalCalendar = useSelector(state => state.calendar.personal)
    const dispatch = useDispatch()

    // Internal component states
    const [neTime, setNETime] = useState(null)
    const [neDuration, setNEDuration] = useState(30 * 60 * 1000)
    const [neeTime, setNEETime] = useState(null)
    const [currentTime, setCurrentTime] = useState(moment())
    const [neSummary, setNESummary] = useState('')
    const [readyState, setReadyState] = useState(false)
    const [simultaneousEventTag, setSimultaneousEventTag] = useState('')

    const [eventShowing, setEventShowing] = useState(null)
    const globalClock = useSelector(state => state.calendar.globalClock)

    const setUpFirstEvent = () => {
        // Find the first event that should be displayed
        let searchOn = true
        dailyCalendar.forEach((ev) => {
            if (searchOn)
            {
                let eventStart
                if (ev.start.hasOwnProperty("dateTime"))
                {
                    eventStart = moment(ev.start.dateTime)
                } else {
                    eventStart = moment(ev.start.date)
                }
                let eventDescription = ''
                if (ev.description)
                {
                    eventDescription = ev.description
                }
                if (eventStart > moment())
                {
                    searchOn = false
                    setNETime(eventStart)
                    dispatch(setNeTimeDaily({neTimeDaily: eventStart.toISOString()}))
                    
                    // Count how many events are within 30 minutes from eventStart
                    let simultaenousEvents = countEventsFrom(eventStart.add(-1, 'second')) // to make sure at least 1 event is counted
                    
                    if (simultaenousEvents > 1)
                    {
                        setSimultaneousEventTag(' +' + (simultaenousEvents - 1)) // to make sure it is a string
                    } else {
                        setSimultaneousEventTag('')
                    }

                    setNESummary(ev.summary)
                    setEventShowing(ev)
                    try {
                        let minutesToNotifyInAdvance = Number(eventDescription.match(/\{([^)]+)\}/)[1])
                        if (minutesToNotifyInAdvance > 0 && minutesToNotifyInAdvance < 6 * 60)
                        {
                            setNEDuration(minutesToNotifyInAdvance * 60 * 1000)
                        }
                    } catch (ex)
                    {
                        console.log(ex)
                        console.log("Will set NE duration to 30 minutes")
                        setNEDuration(30 * 60 * 1000)
                    }
                } // eventStart > moment()
            } // searchOn
        })
    }

    const countEventsFrom = (fromTime) => {
        console.log('Counting events from ' + fromTime.toISOString())
        let maxTime = moment(fromTime).add(30, 'minute')

        let eventCount = 0 
        dailyCalendar.forEach((ev) => {
            let eventStart
            if (ev.start.hasOwnProperty("dateTime"))
            {
                eventStart = moment(ev.start.dateTime)
            } else {
                eventStart = moment(ev.start.date)
            }
            if (eventStart > fromTime && eventStart < maxTime)
            {
                eventCount++
            }
        })
        console.log('Found ' + eventCount + ' events')
        return eventCount
    }

    const notifyUpcomingEvent = () => {
        dailyCalendar.forEach((ev) => {
            let eventStart
            let eventEnd
            let eventDescription = ''
            if (ev.description)
            {
                eventDescription = String(ev.description)
            }
            if (ev.start.hasOwnProperty("dateTime"))
            {
                eventStart = moment(ev.start.dateTime)
            } else {
                eventStart = moment(ev.start.date)
            }
            let minutesToNotifyInAdvance = 30
            try {
                minutesToNotifyInAdvance = Number(eventDescription.match(/\{([^)]+)\}/)[1])
                if (minutesToNotifyInAdvance > 0 && minutesToNotifyInAdvance < 6 * 60)
                {
                    // Good! No need to correct the minutesToNotifyInAdvance
                } else {
                    minutesToNotifyInAdvance = 30 // 30 minutes default
                }
            } catch (ex)
            {
                // console.log(ex)
                // console.log('But this is fine, the default value of 30 minutes will still be used')
            }
            if (eventStart < moment().add(minutesToNotifyInAdvance, 'minute'))
            {
                let descriptionToShow = eventDescription
                // Dispatch a notification if the upcoming event is within "range"
                try {
                descriptionToShow = eventDescription.replace(eventDescription.match(/\{([^)]+)\}/)[0], "")
                }
                catch (e) {
                    console.log('The description does not contain any time indicator. Skipping it entirely.')
                }
                let timeToEvent = 'ST'
                let tag2 = ''

                if (moment() < eventStart)
                { // Event has not started yet
                    timeToEvent = formatDuration(moment(), eventStart)
                    // if the different between now and eventStart is less than 1 minute
                    let minuteUntilEvent = moment.duration(eventStart.diff(moment())).asMinutes()
                    // console.log('minuteUntilEvent: ' + minuteUntilEvent)
                    if (minuteUntilEvent < 15 && minuteUntilEvent >= 0)
                    {
                        // To blink notification for imminent events
                        tag2 = 'ST' // first level of notification
                        if (minuteUntilEvent < 1)
                        {
                            tag2 = 'ST2' // second level notification
                        }
                    }
                } else {
                    // Event has already started, dispatch an overlayNotification
                    // If eventStarted is less than 1 minute ago
                    let minuteSinceEvent = moment.duration(moment().diff(eventStart)).asMinutes()
                    if (minuteSinceEvent < 1)
                    {
                        dispatch(setOverlayNotification({
                            title: 'Event ' + ev.summary + ' has started',
                            message: descriptionToShow,
                            show: true
                        }))
                    }
                }
                
                dispatch(addNotification({
                    message: ev.summary,
                    description: descriptionToShow,
                    tag: timeToEvent,
                    tag2: tag2,
                    tag3: eventStart.toISOString()
                }))
            }
        })
    }

    const setUpActiveEvent = () => {
        let searchOn = true
        dailyCalendar.forEach((ev) => {
            let eventStart
            let eventEnd
                if (ev.start.hasOwnProperty("dateTime"))
                {
                    eventStart = moment(ev.start.dateTime)
                } else {
                    eventStart = moment(ev.start.date)
                }
                if (eventStart < moment())
                {
                    if (ev.end.hasOwnProperty("dateTime"))
                    {
                        eventEnd = moment(ev.end.dateTime)
                    } else {
                        eventEnd = moment(ev.end.date)
                    }
                    if ((eventEnd > moment()) && searchOn)
                    {
                        /* console.log('1Set now event')
                        console.log(ev)
                        console.log('---') */
                        setNEETime(eventEnd)
                        searchOn = false
                        // Event is in active

                        dispatch(setEventActiveDaily({
                            event: ev
                        }))
                    }
                }
        })
        if (searchOn)
        {
            /* console.log('0Set now event')
            console.log('---') */
            dispatch(setEventActiveDaily({
                event: null
            }))
            return
        }   
    }

     // When dailyCalendar changes, choose the first event to display and start the timer
    useEffect(() => {
        if (dailyCalendar.length > 0)
        {
            setNEETime(null)
            setUpFirstEvent()
            setUpActiveEvent()
        }
        
    }, [dailyCalendar])

    // When neTime is set (which should be when the event is set to be displayed, start the timer)
    
    useEffect(() => {
        let timerHandler = null
        timerHandler = setInterval(() => {
            setCurrentTime(moment())
        }, 30000)
        return () => clearInterval(timerHandler)
    }, [])

    // Resetup first event if the event expires!
    useEffect(() => {
        if (neTime < currentTime)
        {
            setUpFirstEvent() // refresh the green event, the event about to come up next
        }
        if ((neeTime===null) || (neeTime < currentTime))
        {
            setUpActiveEvent() // refresh the pink event, the event is going on - clear the current event, perhaps?
        }
        try {
            if (neTime.valueOf() - currentTime.valueOf() < neDuration)
            {
                setReadyState(true)
                dispatch(setEventReadyDaily({
                    event: eventShowing
                }))
                /*let descriptionToShow = eventShowing.description
                if (neDuration !== 30 * 60 * 1000)
                {
                    // The description should contain the instruction about the duration to notify in advance
                    descriptionToShow = descriptionToShow.replace(descriptionToShow.match(/\{([^)]+)\}/)[0], "")
                }
                let eventStart
                if (eventShowing.start.hasOwnProperty("dateTime"))
                {
                    eventStart = moment(eventShowing.start.dateTime)
                } else {
                    eventStart = moment(eventShowing.start.date)
                }
                let timeToEvent = 'ST'
                if (moment() < eventStart)
                {
                    timeToEvent = formatDuration(moment(), eventStart)
                }
                dispatch(addNotification({
                    message: eventShowing.summary,
                    description: descriptionToShow,
                    tag: timeToEvent
                }))*/
            } else {
                setReadyState(false)
                dispatch(setEventReadyDaily({
                    event: null
                }))
            }
        } catch (ex)
        {
            // Do nothing
        }
        // Try to dispatch notifications about upcoming events
        notifyUpcomingEvent()
    }, [neTime, neeTime, neDuration, currentTime, eventShowing])

    
    return(
        <div className={`${ readyState ? "TextBarReady" : "TextBar"} ${imminentCalendarBar == 'imminent' ? 'TextBarImminent' : (imminentCalendarBar == 'active' ? 'TextBarActive' : '')}`}>
            <div className='Text Left'>{neTime !== null ? (eventReadyDaily !== null ? formatDurationWithSeconds(globalClock, neTime) : formatDuration(currentTime, neTime)) + ' ' : ''} </div>
            <div className='Text Right'>• {neSummary.length > 18 ? neSummary.substring(0,18) + '...' : neSummary} {simultaneousEventTag}</div>
            <div className='Text Clock'><ClockStatus onToggleCalendar = {onToggleCalendar}></ClockStatus></div>
        </div>
    )
}

export default CalendarDaily