import moment from 'moment';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button'
import ApiCalendar from 'react-google-calendar-api';
import './Calendar.css'
import { useSelector, useDispatch } from 'react-redux'
import formatDuration from './FormatDuration'
import formatDurationWithSeconds from './formatDurationWithSeconds'
import ClockStatus from './ClockStatus';
import { AiOutlineCaretDown } from 'react-icons/ai';

function CalendarNowPersonal({ onToggleCalendar })
{
    // Redux state imminentCalendarBar
    const imminentCalendarBar = useSelector(state => state.calendar.imminentCalendarBar)

    // Redux states
    const eventActivePersonal = useSelector(state => state.calendar.eventActivePersonal)
    const dispatch = useDispatch()

    const [currentTime, setCurrentTime] = useState(moment())
    let timerHandler = null
    const globalClock = useSelector(state => state.calendar.globalClock)

    useEffect(() => {
        if ((eventActivePersonal!==null) && (timerHandler===null))
        {
            timerHandler = setInterval(() => {
                setCurrentTime(moment())
            }, 30000)
        }

        return () => {
            if (timerHandler!==null)
            {
                clearInterval(timerHandler)
                timerHandler = null
            }
        }
    }, [eventActivePersonal])
    
    return(
        <>
            
            { eventActivePersonal!==null &&  
            (<div className={`TextBarNow ${imminentCalendarBar == 'imminent' ? 'TextBarImminent' : (imminentCalendarBar == 'active' ? 'TextBarActive' : '')}`}>
                <div className='Text Left'><AiOutlineCaretDown></AiOutlineCaretDown> {eventActivePersonal !== null && eventActivePersonal.hasOwnProperty('end') && eventActivePersonal.end.hasOwnProperty('dateTime') ? formatDurationWithSeconds(globalClock, moment(eventActivePersonal.end.dateTime)) + ' ' : 'NOW '}</div>
                <div className='Text Right'>• {eventActivePersonal === null ? ' ' : (eventActivePersonal.summary.length > 18 ? eventActivePersonal.summary.substring(0,18) + '...' : eventActivePersonal.summary)}</div>
                <div className='Text Clock'><ClockStatus onToggleCalendar = {onToggleCalendar}></ClockStatus></div>
            </div>)
            }
        </>
    )
}

export default CalendarNowPersonal
// <div className='Text Left'>{eventActivePersonal !== null ? formatDuration(eventActivePersonal.end.dateTime !== null ? moment(eventActivePersonal.end.dateTime, currentTime ) : moment(eventActivePersonal.end.date, currentTime )) + ' ' : ''} </div>