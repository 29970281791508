import "./Home.css"

import Checklist from "./Checklist";

function ChecklistTest () 
{
    return(
        <Checklist show={true}></Checklist>
    )
}

export default ChecklistTest;