import moment from 'moment'
import token from './Token'

export const collectionx = (db, collectionName) => {
    return {
        collectionName: collectionName,
    }
}

export const queryx = (obj) => {
    return obj
}

export const getQuerySnapshots = async (dataJson) => {
    // dataJson is an array of objects
    let result = {
        size: dataJson.length
    }
    for(let i = 0; i < dataJson.length; i++) {
        result[i] = {
            data: () => dataJson[i],
            id: dataJson[i].practicalId,
            exists: true
        }
    }
    result.forEach = (callback) => {
        for(let i = 0; i < dataJson.length; i++) {
            callback(result[i])
        }
    }
    // console.log(result)
    // Object.setPrototypeOf(result, Array.prototype);
    // console.log(result)
    return result
}

export const getDocumentSnapshot = async (dataJson) => {
    return new Promise((resolve, reject) => {
        resolve(
            {
                data: () => dataJson,
                id: dataJson.practicalId,
                exists: () => true
            }
        )
    })
}

const dateWrapper = (dateStr) => {
    return {
        toDate: () => moment(dateStr).toDate(),
        toString: () => {
            if (dateStr == null) {
                return null
            }
            if (dateStr == "") {
                return ""
            }
            return moment(dateStr).toISOString()
        },
        toJSON: () => moment(dateStr).toISOString()
        // date: moment(dateStr).toDate()
    }
}

const parseDateForSubtasks = (jsonObject) => {
    // convert jsonObject.expiredDate to a Date object
    if (jsonObject.hasOwnProperty('expiredDate')) {
        jsonObject.expiredDate = dateWrapper(jsonObject.expiredDate)
    }
    // convert jsonObject.validFrom to a Date object
    if (jsonObject.hasOwnProperty('validFrom')) {
        jsonObject.validFrom = dateWrapper(jsonObject.validFrom)
    }
    if (jsonObject.hasOwnProperty('subs')) {
        jsonObject.subs.forEach((sub) => {
            // convert sub.validFromDate to a Date object
            if (sub.hasOwnProperty('validFromDate')) {
                sub.validFromDate = dateWrapper(sub.validFromDate)
            }
            // convert sub.expiryDate to a Date object
            if (sub.hasOwnProperty('expiryDate')) {
                sub.expiryDate = dateWrapper(sub.expiryDate)
            }
            // convert sub.tuCountedDate to a Date object
            if (sub.hasOwnProperty('tuCountedDate')) {
                sub.tuCountedDate = dateWrapper(sub.tuCountedDate)
            }
            // convert sub.tuUpdatedDate to a Date object
            if (sub.hasOwnProperty('tuUpdatedDate')) {
                sub.tuUpdatedDate = dateWrapper(sub.tuUpdatedDate)
            }
        })
    }
}

const parseDateForReports = (jsonObject) => {
    if (jsonObject.date != null) {
        jsonObject.date = dateWrapper(jsonObject.date)
    }
    return jsonObject
}

export const getDocsx = async (queryObject) => {
    if (queryObject.collectionName == null) {
        throw new Error('collectionName is required')
    }
    if (queryObject.collectionName == "subtasks")
    {
        // perform fetch https://paymemobile.fr/subtasks
        const response = await fetch('https://paymemobile.fr/subtasks?token='+token)
        const data = await response.json()
        // console.log("JSON Response")
        // console.log(data)
        let parsedDateForData = data.map((jsonObject) => {
            parseDateForSubtasks(jsonObject)
            return jsonObject
        })
        // console.log(parsedDateForData)
        return await getQuerySnapshots(parsedDateForData)
    }
    if (queryObject.collectionName == "reports")
    {
        // perform fetch https://paymemobile.fr/reports
        const response = await fetch('https://paymemobile.fr/reports?token='+token)
        const data = await response.json()
        // console.log("JSON Response")
        // console.log(data)
        let parsedDateForData = data.map((jsonObject) => {
            return parseDateForReports(jsonObject)
        })
        return await getQuerySnapshots(parsedDateForData)
    }
    if (queryObject.collectionName == "doneList")
    {
        // perform fetch https://paymemobile.fr/doneList
        const response = await fetch('https://paymemobile.fr/doneList?token='+token)
        const data = await response.json()
        // console.log("JSON Response")
        // console.log(data)
        return await getQuerySnapshots(data)
    }
    if (queryObject.collectionName == "doneListForSubs")
    {
        // perform fetch https://paymemobile.fr/doneListForSubs
        const response = await fetch('https://paymemobile.fr/doneListForSubs?token='+token)
        const data = await response.json()
        // console.log("JSON Response")
        // console.log(data)
        return await getQuerySnapshots(data)
    }
    if (queryObject.collectionName == "templates")
    {
        // perform fetch https://paymemobile.fr/templates
        const response = await fetch('https://paymemobile.fr/templates?token='+token)
        const data = await response.json()
        let parsedDateForData = data.map((jsonObject) => {
            return parseDateForReports(jsonObject)
        })
        // console.log("Parsed JSON Response")
        // console.log(parsedDateForData)
        return await getQuerySnapshots(parsedDateForData)
    }
}

export const getDocx = async (docObject) => {
    if (docObject.collectionName == "templates") {
        let practicalId = docObject.practicalId
        // perform fetch https://paymemobile.fr/templates
        const response = await fetch('https://paymemobile.fr/templateByPracticalId?token='+token+'&practicalId='+practicalId)
        const data = await response.json()
        let parsedDateForData = parseDateForReports(data)
        // console.log("Parsed JSON Response")
        // console.log(parsedDateForData)
        return getDocumentSnapshot(parsedDateForData)
    }
}

export const docx = (db, collectionName, practicalId) => {
    return {
        collectionName: collectionName,
        practicalId: practicalId,
    }
}

export const setDocx = async (docObject, data) => {
    if (docObject.collectionName == null) {
        throw new Error('collectionName is required')
    }
    if (docObject.collectionName == "subtasks")
    {
        data.practicalId = docObject.practicalId
        if (data.hasOwnProperty("_id"))
        {
            delete data._id
        }
        // console.log(JSON.stringify(data))
        // perform post https://paymemobile.fr/subtasks
        const response = await fetch('https://paymemobile.fr/saveSubtask', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: "token=" + token + "&data=" + JSON.stringify(data)
        })
        const responseData = await response.json()
        // console.log(responseData)
    }
    if (docObject.collectionName == "reports")
    {
        data.practicalId = docObject.practicalId
        if (data.hasOwnProperty("_id"))
        {
            delete data._id
        }
        // console.log(JSON.stringify(data))
        // perform post https://paymemobile.fr/reports
        const response = await fetch('https://paymemobile.fr/saveReport', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: "token=" + token + "&data=" + JSON.stringify(data)
        })
        const responseData = await response.json()
        // console.log(responseData)
    }
    if (docObject.collectionName == "doneList")
    {
        data.practicalId = docObject.practicalId
        if (data.hasOwnProperty("_id"))
        {
            delete data._id
        }
        // console.log(JSON.stringify(data))
        // perform post https://paymemobile.fr/doneList
        const response = await fetch('https://paymemobile.fr/saveDoneList', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: "token=" + token + "&data=" + JSON.stringify(data)
        })
        const responseData = await response.json()
        // console.log(responseData)
    }
    if (docObject.collectionName == "doneListForSubs")
    {
        data.practicalId = docObject.practicalId
        if (data.hasOwnProperty("_id"))
        {
            delete data._id
        }
        // console.log(JSON.stringify(data))
        // perform post https://paymemobile.fr/doneList
        const response = await fetch('https://paymemobile.fr/saveDoneListForSubs', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: "token=" + token + "&data=" + JSON.stringify(data)
        })
        const responseData = await response.json()
        // console.log(responseData)
    }
    if (docObject.collectionName == "templates")
    {
        data.practicalId = docObject.practicalId
        if (data.hasOwnProperty("_id"))
        {
            delete data._id
        }
        // console.log(JSON.stringify(data))
        // perform post https://paymemobile.fr/templates
        const response = await fetch('https://paymemobile.fr/saveTemplate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: "token=" + token + "&data=" + JSON.stringify(data)
        })
        const responseData = await response.json()
        // console.log(responseData)
    }
}

export const deleteDocx = async (docObject) => {
    if (docObject.collectionName == null) {
        throw new Error('collectionName is required')
    }
    if (docObject.collectionName == "subtasks")
    {
        // perform delete https://paymemobile.fr/subtasks
        const response = await fetch('https://paymemobile.fr/deleteSubtask?token=' + token + '&practicalId=' + docObject.practicalId)
        const responseData = await response.json()
        // console.log(responseData)
    }
    if (docObject.collectionName == "templates")
    {
        // perform delete https://paymemobile.fr/templates
        const response = await fetch('https://paymemobile.fr/deleteTemplate?token=' + token + '&practicalId=' + docObject.practicalId)
        const responseData = await response.json()
        // console.log(responseData)
    }
}