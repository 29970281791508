import { useSelector, useDispatch } from "react-redux";
import { TiFlash, TiWiFi } from "react-icons/ti"
import { AiFillCloud } from "react-icons/ai"
import { FaHeart, FaSave } from "react-icons/fa"
import { MdRestartAlt } from "react-icons/md";
import moment from 'moment'

function ClockStatus(props) {

    const networkOk = useSelector(state => state.calendar.networkOk)
    const globalClock = useSelector(state => state.calendar.globalClock)
    const mqttOk = useSelector(state => state.calendar.mqttConnected)
    const cloudOk = useSelector(state => state.calendar.cloudSyncing)
    const unsavedChanges = useSelector(state => state.calendar.unsavedChanges)
    const refreshAdvised = useSelector(state => state.calendar.refreshAdvised)
    const showCheckList = useSelector(state => state.calendar.showEmotionalEmergencyChecklist)

    const dispatch = useDispatch()

    return (
        <div className='ClockCorner'>
            {cloudOk && (<AiFillCloud style={{ marginRight: 3, marginBottom: 3 }}></AiFillCloud>)}
            {mqttOk && (<TiFlash style={{ marginRight: 3, marginBottom: 3 }}></TiFlash>)}
            {refreshAdvised && (<MdRestartAlt style={{ marginRight: 3, marginBottom: 3 }}></MdRestartAlt>)}
            {unsavedChanges && (<FaSave style={{ marginRight: 3, marginBottom: 3 }}></FaSave>)}
            {networkOk && (<TiWiFi style={{ marginRight: 6, marginBottom: 3 }}></TiWiFi>)}
            <span onClick={() => { if (props.onToggleCalendar != null) { console.log(props.onToggleCalendar); props.onToggleCalendar() } }}>{moment(globalClock).format('ddd D/M, H:mm')}</span>
            {/* <MdEmergency style={{marginLeft: 3, marginBottom: 3}} onClick={() => dispatch(setShowEmotionalEmergencyChecklist({
                showEmotionalEmergencyChecklist: !showCheckList
            }))}></MdEmergency> */}
        </div>
    )
}

export default ClockStatus