import moment from 'moment'

export const getRemainingDays = (targetDateTime) => {
    try {
        const now = moment();
        const target = moment(targetDateTime);
        const diffInHours = target.diff(now, 'hours', true); // 'true' returns a floating point number
        const daysRemaining = diffInHours / 24;
        return daysRemaining
    } catch (error) {
        console.log(error)
        return -1
    }
}