import { useState, useEffect } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import './LoginScreen.css';

const Login = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleTextChange = (e, target) => {
        if (target === 'username')
        {
            setUsername(e.target.value)
        }
        else if (target === 'password')
        {
            setPassword(e.target.value)
        }
    }

    const handleLogin = async () => {
        try {
            setIsLoading(true);
            const result = await fetch('https://paymemobile.fr/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: "username=" + username + "&password=" + password
            })
            // console.log("Username: " + username)
            // console.log("Password: " + password)
            // console.log(result)
            const data = await result.json();
            // console.log(data)
            if (data.token != null)
            {
                localStorage.setItem('token', data.token);
                window.location.href = '/';
            } else {
                setIsLoading(false);
                alert("Username or password is incorrect")
            }
        } catch (e) {
            setIsLoading(false);
            alert("Error logging in")
            console.log(e)
        }
    }

    return(
        <div className="LoginScreen">
            <div className="LoginBox">
                <Form.Label for>Username</Form.Label>
                <Form.Control type="text" value={username} onChange={(e) => handleTextChange(e, 'username')} />
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" value={password} onChange={(e) => handleTextChange(e, 'password')}/>
                <div className='submitButton'>
                    <Button variant="primary" onClick={handleLogin} style={{display: isLoading ? 'none' : 'block'}}>Login</Button>
                    <Spinner animation="border" role="status" style={{display: isLoading ? 'block' : 'none'}}></Spinner>
                </div>
                
            </div>
        </div>
    )
}

export default Login