import { useEffect, useState } from 'react';

import "./DivWithProgress.css"

function DivWithProgress(props)
{
    
    const colorToBorderClassName = (color) => {
        switch(color)
        {
            case 'red':
                return 'dwp-border-red'
            case 'yellow':
                return 'dwp-border-yellow'
            case 'blue':
                return 'dwp-border-blue'
            default:
                return ''
        }
    }

    const colorToActiveClassName = (color) => {
        switch(color)
        {
            case 'red':
                return 'dwp-active-red'
            case 'yellow':
                return 'dwp-active-yellow'
            case 'blue':
                return 'dwp-active-blue'
            default:
                return 'dwp-active-blue'
        }
    }
    
    return(
        <div className="dwp-wrapper">
            {/* {String(props.color)} {props.p} */}
            <div className={`dwp-progressbar ${colorToBorderClassName(props.color)}`}>
                <div className={`dwp-active ${colorToActiveClassName(props.color)}`} style={{flex: props.p / (1-props.p)}}></div>
                <div className="dwp-inactive"></div>
            </div>
            <div className={`dwp-inner ${props.blinkBorder ? 'BlinkingNotification' : ''}`}><span>{props.text}</span></div>
        </div>
    )
}

export default DivWithProgress