import React from 'react';
import { Form } from "react-bootstrap";

const TaskSwitcher = ({setStampIdToShow, tasksToShowOnProcast}) => {
    return(
        <div className="StampSwitcher">
            <Form.Select onChange={(e) => {setStampIdToShow(e.target.value); window.location.reload()}}>
                <option value="DAILY">Default</option>
                {tasksToShowOnProcast.map((t) => 
                {
                    return(<option value={t.id}>{t.id}</option>)
                })
                }
            </Form.Select>
        </div>
    )
}

export default React.memo(TaskSwitcher);