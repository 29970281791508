import React, { useRef, useEffect } from 'react';
import Gauge from './Gauge';

function GaugeTest({})
{
    
    return(
        <Gauge percentage={10} value={25} label="MIN" color="yellow" />
    )
}

export default GaugeTest;