import React, { useRef, useEffect } from 'react';

function Gauge({percentage, value, label, color, theme})
{
    const gaugeRef = useRef(null);

    const drawGauge = (ctx) => {
        let pctg = Math.min(Math.max(percentage, 0), 1);
        let angleOfIndicator = (Math.PI / 6) + (pctg * 4 * Math.PI / 3);
        // angleOfIndicator = Math.min(Math.max(angleOfIndicator, Math.PI / 6), 7 * Math.PI / 6);
        ctx.canvas.width = 50;
        ctx.canvas.height = 50;
        // outer circle
        if (color == 'yellow')
        {
            if (theme == "light")
            {
                ctx.strokeStyle = "#d59e26";
            } else {
                ctx.strokeStyle = "gold";
            }
        } else if (color == 'red')
        {
            ctx.strokeStyle = "red";
        } else if (color == 'cyan') {
            if (theme == "light")
                ctx.strokeStyle = "#007982";
            else
                ctx.strokeStyle = "cyan";
        } else if (color == 'white') {
            if (theme == "light")
                ctx.strokeStyle = "black";
            else
                ctx.strokeStyle = "white";
        }
        else {
            // green
            if (theme == "light")
                ctx.strokeStyle = "#007982";
            else
                ctx.strokeStyle = "cyan";
        }
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.arc(25, 25, 24, Math.PI / 6, 9 * Math.PI / 6);
        ctx.stroke()
        // ticks
        ctx.lineWidth = 1;
        ctx.beginPath();
        for (let i = 0; i < 6; i++)
        {
            ctx.moveTo(25 + 24 * Math.cos(Math.PI / 6 + i * 4 * Math.PI / 15), 25 + 24 * Math.sin(Math.PI / 6 + i * 4 * Math.PI / 15));
            ctx.lineTo(25 + 18 * Math.cos(Math.PI / 6 + i * 4 * Math.PI / 15), 25 + 18 * Math.sin(Math.PI / 6 + i * 4 * Math.PI / 15));
        }
        ctx.stroke()
        // indicator
        if (theme == "light")
        {
            ctx.strokeStyle = "black";
        } else {
            ctx.strokeStyle = "white";
        }
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.moveTo(25, 25);
        ctx.lineTo(24 + 24 * Math.cos(angleOfIndicator), 24 + 24 * Math.sin(angleOfIndicator));
        ctx.stroke()
    }

    useEffect(() => {
        const ctx = gaugeRef.current.getContext("2d");
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        drawGauge(ctx);
    }, [percentage, value, label, color, theme]);

    return(
        <div style={{flexDirection: 'row', display: 'flex'}}>
            <canvas id="gauge" ref={gaugeRef}></canvas>
            <div style={{flexDirection: 'column', marginLeft: -18, marginTop: -3}}>
                <div style={{fontSize: 18}}>{value}</div>
                <div style={{marginTop: -5, fontSize: 9, width: 40}}>{label}</div>
            </div>
            
        </div>
    )
}

// create a memoized version of the component
// that only rerenders if the props change
export default React.memo(Gauge);