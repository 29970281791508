import { useEffect, useState } from 'react';

import "./DivWithProgress.css"

function DivWithProgress(props)
{
    
    // const propsp = 0.6
    
    return(
        <div className="dwp-wrapper">
            {/* {String(props.color)} {props.p} */}
            <div className={`dwp-progressbar ${props.color && props.p > 0.667 && props.p < 1 ? 'dwp-border-red' : ''}
                ${props.color && props.p > 0.333 && props.p <= 0.667 ? 'dwp-border-yellow' : ''}
                ${props.color && (props.p <= 0.333 || props.p >= 1) ? 'dwp-border-blue' : ''}`}>
                <div className={`dwp-active ${props.color && props.p > 0.667 && props.p < 1 ? 'dwp-active-red' : ''}
                ${props.color && props.p > 0.333 && props.p <= 0.667 ? 'dwp-active-yellow' : ''}
                ${props.color && (props.p <= 0.333 || props.p >= 1) ? 'dwp-active-blue' : ''}
                ${!props.color ? 'dwp-active-blue' : ''}`} style={{flex: props.p / (1-props.p)}}></div>
                <div className="dwp-inactive"></div>
            </div>
            <div className={`dwp-inner ${props.blinkBorder ? 'BlinkingNotification' : ''}`}><span>{props.text}</span></div>
        </div>
    )
}

export default DivWithProgress