import { useEffect, useState } from 'react'
import db from './Firebase'
import { collection, getDocs, query, doc, setDoc, getDoc, deleteDoc } from 'firebase/firestore'
// import { getDoc, deleteDoc } from 'firebase/firestore' // templates
import { collectionx, getDocsx, queryx, docx, setDocx, deleteDocx, getDocx } from './SubtaskNewServer'
import payrate from './payrate'
import './EditAny.css'
import Button from 'react-bootstrap/Button'
import moment from 'moment'
import _ from 'lodash'

import Form from 'react-bootstrap/Form'
import { FaArrowDown, FaArrowUp, FaPlus, FaSave, FaSync } from 'react-icons/fa'
import { MdDelete } from 'react-icons/md'
import { BiCheck, BiReset, BiYen } from 'react-icons/bi'
import { Spinner } from 'react-bootstrap'

function EditAny() {
  const [allSubs, setAllSubs] = useState([])
  const [stamp, setStamp] = useState([])
  const [dailySn, setDailySn] = useState('')
  const [data, setData] = useState({}) // the daily task stamp
  const [totalTime, setTotalTime] = useState(0)
  const [totalTimeReward, setTotalTimeReward] = useState(0)
  const [optUpdateFlag, setOptUpdateFlag] = useState(0)
  const [doneList, setDoneList] = useState([])
  const [doneListForSubs, setDoneListForSubs] = useState([])
  const [templateName, setTemplateName] = useState('')

  const [savingStamp, setSavingStamp] = useState(false)

  const [stampId, setStampId] = useState('')

  const [stampCreate, setStampCreate] = useState({
    id: '',
    description: '',
    expired: '',
    finish: '',
    expiredDate: '',
    sn: '',
  })

  // const payrate = 0.5

  const loadStamp = async () => {
    setSavingStamp(true)
    let subs = []
    let mAllSubs = []
    // Load the tasks
    const querySnapshot = await getDocsx(queryx(collectionx(db, 'subtasks')))
    if (querySnapshot.size == 0) {
      console.log('No subtasks found')
      return
    }
    querySnapshot.forEach((doc) => {
      let data = doc.data()
      console.log('data is')
      console.log(data)
      mAllSubs.push(data)
    })

    // Convert date strings to Date objects
    mAllSubs.forEach((bigsub) => {
      if (bigsub.hasOwnProperty('subs'))
      {
        bigsub.subs.forEach((sub) => {
          if (sub.hasOwnProperty('validFromDate'))
          {
            console.log('Sub: ', sub.sname, sub.validFromDate)
            sub.validFromDate = moment(sub.validFromDate.toDate()).format("DD/MM/YYYY HH:mm:ss")
          }
          if (sub.hasOwnProperty('expiryDate'))
          {
            sub.expiryDate = moment(sub.expiryDate.toDate()).format("DD/MM/YYYY HH:mm:ss")
          }
          if (!sub.hasOwnProperty('tags'))
          {
            sub.tags = ""
          }
          if (sub.hasOwnProperty('allowFeedback'))
          {
            sub.allowFeedback = String(sub.allowFeedback)
          }
          if (sub.hasOwnProperty('subsubs'))
          {
            sub.subsubs.forEach((subsub) => {
              if (subsub.hasOwnProperty('validFrom'))
              {
                subsub.validFrom = moment(subsub.validFrom).format("DD/MM/YYYY HH:mm:ss")
              }
              if (subsub.hasOwnProperty('expiryDate'))
              {
                subsub.expiryDate = moment(subsub.expiryDate).format("DD/MM/YYYY HH:mm:ss")
              }
            })
          }
        })
      }
    })


    setAllSubs(mAllSubs)


    

    // if (dailySn != '') {
    //   // find the index of the dailySn in mAllSubs
    //   let indexOfDailySn = mAllSubs.findIndex((x) => x.sn == dailySn)
    //   setStamp(mAllSubs[indexOfDailySn].subs)
    //   setData(mAllSubs[indexOfDailySn])
    // }



    // Load doneList
    const doneListQuerySnapshot = await getDocsx(queryx(collectionx(db, "doneList")));
    if (doneListQuerySnapshot.size > 0) {
      doneListQuerySnapshot.forEach((doc) => {
        if (doc.id == "default")
        {
          let data = doc.data();
          // console.log(data)
          if (data.hasOwnProperty("doneList"))
          {
            // doneList is not empty set doneList (so that they can be appended, not overwritten)
            setDoneList(data.doneList);
            console.log("doneList set");
          }
        }
      });
    }

    // Load doneListForSubs collection
    const doneListForSubsQuerySnapshot = await getDocsx(queryx(collectionx(db, "doneListForSubs")));
    if (doneListForSubsQuerySnapshot.size > 0) {
      doneListForSubsQuerySnapshot.forEach((doc) => {
        if (doc.id == "default") {
          let data = doc.data();
          // console.log(data)
          if (data.hasOwnProperty("doneListForSubs")) {
            // doneList is not empty set doneList (so that they can be appended, not overwritten)
            setDoneListForSubs(data.doneListForSubs);
            console.log("doneListForSubs set");
          }
        }
      });
    }

    // subs.forEach((sub) => {
    //     sub.initialFinish = sub.finish
    // })

    // console.log(mAllSubs)
    setSavingStamp(false)

    if (dailySn != '') { // if the user has selected a stamp, we load it (this case is the case where user hits refresh)
      // find the index of the dailySn in mAllSubs
      let indexOfDailySn = mAllSubs.findIndex((x) => x.sn == dailySn)
      let subs = mAllSubs[indexOfDailySn].subs
      if (mAllSubs[indexOfDailySn].hasOwnProperty('id')) {
        setTemplateName(mAllSubs[indexOfDailySn].id.toLowerCase())
      }
      setStamp(subs)
      setData(mAllSubs[indexOfDailySn])
      setStampId(mAllSubs[indexOfDailySn].id)
    } 
  }

  useEffect(() => {
    // Set document title
    document.title = 'Edit Tasks'
    
    // If locked redirect to home
    if (localStorage.getItem('locked') == 'true') {
      window.location.href = '/'
    }
    
    loadStamp()
  }, [])

  const isNumeric = (str) => {
    if (typeof str != 'string') return false // we only process strings!
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ) // ...and ensure strings of whitespace fail
  }

  const isNumber = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n)
  }

  const make_serial = (length, terms) => {
    var result = []
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    var charactersLength = characters.length
    for (let term = 0; term < terms; term++) {
      for (var i = 0; i < length; i++) {
        result.push(characters.charAt(Math.floor(Math.random() * charactersLength)))
      }
      if (term != terms - 1) result.push('-')
    }
    return result.join('')
  }

  const handleTextChange = (e, fieldname, sn) => {
    if (fieldname == 'sname') {
      let indexOfSub = stamp.findIndex((x) => x.sn == sn)
      let newStamp = Object.assign([], stamp)
      newStamp[indexOfSub]['sname'] = e.target.value
      setStamp(newStamp)
    }
    if (fieldname == 'pay') {
      let indexOfSub = stamp.findIndex((x) => x.sn == sn)
      let newStamp = Object.assign([], stamp)
      newStamp[indexOfSub]['finish'] = e.target.value
      if (isNumeric(e.target.value)) {
        // newStamp[indexOfSub]["finish"] = Number(e.target.value);
        newStamp[indexOfSub]['initialFinish'] = Number(e.target.value)
      }
      setStamp(newStamp)
    }
    if (fieldname == 'time') {
      let indexOfSub = stamp.findIndex((x) => x.sn == sn)
      let newStamp = Object.assign([], stamp)
      newStamp[indexOfSub]['time'] = e.target.value
      if (isNumeric(e.target.value)) {
        // newStamp[indexOfSub]["time"] = Number(e.target.value);
        newStamp[indexOfSub]['finish'] = Number(e.target.value) * payrate
        newStamp[indexOfSub]['initialFinish'] = Number(e.target.value) * payrate
      }
      setStamp(newStamp)
    }
    if (fieldname == 'bonusCoeff') {
      let indexOfSub = stamp.findIndex((x) => x.sn == sn)
      let newStamp = Object.assign([], stamp)
      newStamp[indexOfSub]['bonusCoeff'] = e.target.value
      /* if (isNumeric(e.target.value)) {
        newStamp[indexOfSub]["bonusCoeff"] = Number(e.target.value);
      } else {
        newStamp[indexOfSub]["bonusCoeff"] = e.target.value;
      } */
      setStamp(newStamp)
    }
    if (fieldname == 'originalTime') {
      let indexOfSub = stamp.findIndex((x) => x.sn == sn)
      let newStamp = Object.assign([], stamp)
      newStamp[indexOfSub]['originalTime'] = e.target.value
      if (isNumeric(e.target.value)) {
        // newStamp[indexOfSub]["originalTime"] = Number(e.target.value);
        newStamp[indexOfSub]['originalFinish'] = Number(e.target.value) * payrate
        newStamp[indexOfSub]['time'] = Number(e.target.value)
        newStamp[indexOfSub]['finish'] = Number(e.target.value) * payrate
        newStamp[indexOfSub]['initialFinish'] = Number(e.target.value) * payrate
      }
      setStamp(newStamp)
    }
    if (fieldname == 'originalFinish') {
      let indexOfSub = stamp.findIndex((x) => x.sn == sn)
      let newStamp = Object.assign([], stamp)
      newStamp[indexOfSub]['originalFinish'] = e.target.value
      if (isNumeric(e.target.value)) {
        newStamp[indexOfSub]['finish'] = Number(e.target.value)
      }
      setStamp(newStamp)
    }
    if (fieldname == "validFromDate") {
      let indexOfSub = stamp.findIndex((x) => x.sn == sn)
      let newStamp = Object.assign([], stamp)
      newStamp[indexOfSub]["validFromDate"] = e.target.value
      setStamp(newStamp)
    }
    if (fieldname == "expiryDate") {
      let indexOfSub = stamp.findIndex((x) => x.sn == sn)
      let newStamp = Object.assign([], stamp)
      newStamp[indexOfSub]["expiryDate"] = e.target.value
      setStamp(newStamp)
    }
    if (fieldname == "allowFeedback")
    {
      let indexOfSub = stamp.findIndex((x) => x.sn == sn);
      let newStamp = Object.assign([], stamp);
      newStamp[indexOfSub]["allowFeedback"] = e.target.value;
      setStamp(newStamp);
    }
    // update optUpdateFlag
    setOptUpdateFlag(optUpdateFlag + 1)
  }

  const setTimeByButton = (sn, value) => {
    let indexOfSub = stamp.findIndex((x) => x.sn == sn)
    let newStamp = Object.assign([], stamp)
    newStamp[indexOfSub]['time'] = value
    newStamp[indexOfSub]['finish'] = value * payrate
    newStamp[indexOfSub]['initialFinish'] = value * payrate
    setStamp(newStamp)
  }

  const deleteSub = (sn) => {
    let newStamp = Object.assign([], stamp)
    newStamp = newStamp.filter((x) => x.sn != sn)
    setStamp(newStamp)
  }

  const addSubSub = (sn) => {
    let indexOfSub = stamp.findIndex((x) => x.sn == sn)
    let newStamp = Object.assign([], stamp)
    newStamp[indexOfSub]['subsubs'].push({
      sn: make_serial(6, 6),
      name: 'Subtask Name',
      finish: 1,
    })
    setStamp(newStamp)
  }

  const deleteSubSub = (sn1, sn2) => {
    let indexOfSub = stamp.findIndex((x) => x.sn == sn1)
    let newStamp = Object.assign([], stamp)
    newStamp[indexOfSub]['subsubs'] = newStamp[indexOfSub]['subsubs'].filter((x) => x.sn != sn2)
    setStamp(newStamp)
  }

  const addToDoneList = (sn1, sn2, ssname, sspay) => {
    let indexOfSub = stamp.findIndex((x) => x.sn == sn1)
    let indexOfSubSub = stamp[indexOfSub]['subsubs'].findIndex((x) => x.sn == sn2)
    let doneListPrime = _.cloneDeep(doneList)
    // if sn1 is not in doneList, add it
    if (!doneListPrime.some((x) => x.sn == sn1)) {
      let sname = stamp[indexOfSub]['sname']
      doneListPrime.push({
        sn: sn1,
        sname: sname,
        stampId: stampId,
        ss: [],
      })
    }
    // Append sn2 to ss of the corresponding element whose sn=sn1
    doneListPrime.forEach((x) => {
      if (x.sn == sn1) {
        x.ss.push({
          sn: sn2,
          name: ssname,
          finish: Number(sspay),
        })
      }
    })
    setDoneList(doneListPrime)
    // Remove sn2 from subsubs of the corresponding element whose sn=sn1
    let newStamp = Object.assign([], stamp)
    newStamp[indexOfSub]['subsubs'] = newStamp[indexOfSub]['subsubs'].filter((x) => x.sn != sn2)
    setStamp(newStamp)
  }

  const addToDoneListForSubs = (sn1) => {
    let indexOfSub = stamp.findIndex((x) => x.sn == sn1);
    let doneListForSubsPrime = _.cloneDeep(doneListForSubs);
    // if sn1 is not in doneListForSubs, add it
    if (!doneListForSubsPrime.some((x) => x.sn == sn1)) {
      let sname = stamp[indexOfSub]["sname"];
      let finish = Number(stamp[indexOfSub]["finish"]);
      doneListForSubsPrime.push({
        sn: sn1,
        sname: sname,
        stampId: stampId,
        finish: finish
      });
    }
    setDoneListForSubs(doneListForSubsPrime);
    console.log(doneListForSubsPrime);
    // Remove sn1 from stamp
    let newStamp = Object.assign([], stamp);
    newStamp = newStamp.filter((x) => x.sn != sn1);
    setStamp(newStamp);
  }

  const handleSubTextChange = (e, sn1, sn2, fieldname) => {
    let indexOfSub = stamp.findIndex((x) => x.sn == sn1)
    let indexOfSubSub = stamp[indexOfSub]['subsubs'].findIndex((x) => x.sn == sn2)
    let newStamp = Object.assign([], stamp)
    if (fieldname == 'name') {
      newStamp[indexOfSub]['subsubs'][indexOfSubSub]['name'] = e.target.value
    }
    if (fieldname == 'finish') {
      newStamp[indexOfSub]['subsubs'][indexOfSubSub]['finish'] = e.target.value
      /* if (isNumeric(e.target.value)) {
        newStamp[indexOfSub]["subsubs"][indexOfSubSub]["finish"] = Number(
          e.target.value
        );
      } else {
        newStamp[indexOfSub]["subsubs"][indexOfSubSub]["finish"] =
          e.target.value;
      } */
    }
    if (fieldname == 'validFrom') {
      newStamp[indexOfSub]['subsubs'][indexOfSubSub]['validFrom'] = e.target.value
    }
    setStamp(newStamp)
  }

  const addSub = () => {
    if (dailySn == '') {
      return
    }
    let newStamp = Object.assign([], stamp)
    newStamp.push({
      bonusCoeff: 1,
      finish: payrate,
      originalFinish: payrate,
      originalTime: 1,
      initialFinish: payrate,
      time: 1,
      sname: 'New task',
      sn: make_serial(6, 6),
      subsubs: [],
      type: 'subtask',
      // validFromDate: moment().format("DD/MM/YYYY HH:mm:ss"),
      validFromDate: "",
      expiryDate: "",
      tags: ""
    })
    setStamp(newStamp)
  }

  const castData = (mData) => {
    let newData = _.cloneDeep(mData)
    newData.subs.forEach((x) => {
      // Convert finish, time, originalFinish, originalTime, initialFinish, bonusCoeff to number
      x.finish = Number(x.finish)
      x.time = Number(x.time)
      x.originalFinish = Number(x.originalFinish)
      x.originalTime = Number(x.originalTime)
      x.initialFinish = Number(x.initialFinish)
      x.bonusCoeff = Number(x.bonusCoeff)

      // Convert allowFeedback which is a string to number
      if (x.hasOwnProperty("allowFeedback"))
      {
        x.allowFeedback = Number(x.allowFeedback)
      }

      // console.log('x.validFromDate', x.validFromDate)
      
      if (x.hasOwnProperty('validFromDate') && x.validFromDate != "")
      {
        // console.log('111')
        x.validFromDate = moment(x.validFromDate, "DD/MM/YYYY HH:mm:ss").toDate()
      } else {
        // console.log('112')
        if (x.hasOwnProperty('validFromDate')) {
          delete x.validFromDate
        }
      }

      // console.log('x.validFromDate', x.validFromDate)

      if (x.hasOwnProperty('expiryDate') && x.expiryDate != "")
      {
        x.expiryDate = moment(x.expiryDate, "DD/MM/YYYY HH:mm:ss").toDate()
      } else {
        if (x.hasOwnProperty('expiryDate')) {
          delete x.expiryDate
        }
      }
      if (x.hasOwnProperty('tags') && x.tags != "")
      {
        // Remove all spaces
        x.tags = x.tags.replace(/\s/g, '')
        // To lower case
        x.tags = x.tags.toLowerCase()
      }
      // if has subsubs, convert finish to number
      if (x.hasOwnProperty('subsubs')) {
        x.subsubs.forEach((y) => {
          y.finish = Number(y.finish)
          if (y.hasOwnProperty('validFrom') && y.validFrom != "")
          {
            y.validFrom = moment(y.validFrom, "DD/MM/YYYY HH:mm:ss").toDate()
          } else {
            delete y.validFrom
          }
        })
      }
    })
    newData.totalReward = totalTimeReward
    return newData
  }

  const getCastedDataToSave = () => {
    let newData = _.cloneDeep(data)
    newData.subs = _.cloneDeep(stamp)
    let castedData = castData(newData)

    // Recalculate totalReward
    let totalReward = 0
    castedData.subs.forEach((x) => {
      totalReward += x.finish
    })

    castedData.totalReward = totalReward

    return castedData
  }

  const save = async () => {
    if (dailySn == '') {
      return
    }
    setSavingStamp(true)
    // console.log(doneList);

    let castedData = getCastedDataToSave()

    await setDocx(docx(db, 'subtasks', dailySn), castedData)

    // save doneList if there is any
    if (doneList.length > 0) {
      saveDoneList()
    }

    // save doneListForSubs if there is any
    if (doneListForSubs.length > 0) {
      saveDoneListForSubs()
    }

    // alert('Save completed!')
    await loadStamp()

    setSavingStamp(false)
  }

  const saveDoneList = async () => {
    // console.log(doneList)
    await setDocx(docx(db, 'doneList', 'default'), {
      doneList: _.cloneDeep(doneList),
    })
    // alert('Done list was updated!')
    setDoneList([])
  }

  const saveDoneListForSubs = async () => {
    console.log(doneListForSubs);
    await setDocx(docx(db, "doneListForSubs", 'default'), {
      doneListForSubs: _.cloneDeep(doneListForSubs)
    });
    // alert("Done list for subs was updated!");
    setDoneListForSubs([]);
  }

  const resetTime = async () => {
    if (dailySn == '') {
      return
    }
    let docContent = _.cloneDeep(data)
    let totalReward = 0
    if (docContent.subs) {
      docContent.subs.forEach((s) => {
        if (s.hasOwnProperty('countUp') && s.countUp == 1) {
          console.log('countUp task found, skip reset time for ' + s.sname)
          totalReward += s.finish
          return // we do not perform update on countUp tasks
        }
        if (s.hasOwnProperty('originalFinish')) {
          s.finish = s.originalFinish
          s.initialFinish = s.finish
        } else {
          console.log(
            'Did not update stamp reward for sub ' + s.sname + ' because the originalFinish field was not found',
          )
        }
        if (s.hasOwnProperty('originalTime')) {
          s.time = s.originalTime
        } else {
          console.log(
            'Did not update stamp reward for sub ' + s.sname + ' because the originalTime field was not found',
          )
        }
        totalReward += s.finish
      })
      // console.log(docContent.expired)
      docContent.totalReward = totalReward
      docContent.expiredDate = moment().add(3, 'day').toDate()
      docContent.expired = moment().add(3, 'day').toISOString()
      console.log('Extend expiredDate to 3 days from today')
      docContent.description = 'Task plan for ' + moment().format('DD/MM/YYYY')
      let newData = Object.assign({}, docContent)
      await setDocx(docx(db, 'subtasks', dailySn), newData)
      alert('Reset OK!')
      await loadStamp()
      // db.collection('subtasks').doc(dbSn).set(docContent).then(() => console.log('Database updated for ' + dbSn))
    } else {
      console.log('This stamp does not contain any subsubtasks')
    }
  }

  const resetZero = () => {
    if (dailySn == '') {
      alert('No stamp selected!')
      return
    }
    let docContent = _.clone(data)
    let totalReward = 0
    if (docContent.subs) {
      docContent.subs.forEach((s) => {
        if (s.hasOwnProperty('countUp') && s.countUp == 1) {
          return
        }
        s.finish = 0
        s.initialFinish = 0
        s.time = 0
      })
    }
    totalReward = 0
    setData({ ...data, totalReward: totalReward, subs: docContent.subs })
    setStamp(docContent.subs)
    setOptUpdateFlag(optUpdateFlag + 1)
    // alert("Reset OK!")
  }

  const deleteStamp = () => {
    if (dailySn == '') {
      return
    }
    if (window.confirm('Are you sure you want to delete this stamp?')) {
      // Delete the stamp
      deleteDocx(docx(db, 'subtasks', dailySn)).then(() => {
        alert('Stamp deleted!')
        loadStamp()
      })}
  }

  const saveToTemplate = async () => {
    let castedData = getCastedDataToSave()
    await setDocx(docx(db, 'templates', templateName), castedData)
    alert('Stamp saved to template library under the name ' + templateName + '!')
  }

  const loadFromTemplate = async () => {
    let docRef = docx(db, 'templates', templateName)
    let docSnap = await getDocx(docRef)
    if (docSnap.exists()) {
      let mData = docSnap.data()
      if (mData.subs != null)
      {
        mData.subs.forEach((s) => {
          if (s.hasOwnProperty('validFromDate'))
          {
            s.validFromDate = moment(s.validFromDate).format("DD/MM/YYYY HH:mm:ss")
          }
          if (s.hasOwnProperty('expiryDate'))
          {
            s.expiryDate = moment(s.expiryDate.toDate()).format("DD/MM/YYYY HH:mm:ss")
          }
        })
      }
      setStamp(mData.subs)
      if (mData.hasOwnProperty('sn')) {
        delete mData.sn
      }
      if (mData.hasOwnProperty('expired')) {
        delete mData.expired
      }
      if (mData.hasOwnProperty('expiredDate')) {
        delete mData.expiredDate
      }
      setData({
        ...mData,
        sn: dailySn,
        expired: data.expired,
        expiredDate: data.expiredDate,
      })
      alert('Stamp loaded from template library under the name ' + templateName + '!')
    } else {
      alert('No template found under the name ' + templateName + '!')
    }
  }

  // const loadTimeAndFinishFromTemplate = async () => {
  //   let docRef = docx(db, 'templates', templateName)
  //   let docSnap = await getDoc(docRef)
  //   if (docSnap.exists()) {
  //     let mData = docSnap.data()
  //     if (!mData.hasOwnProperty('subs')) {
  //       return
  //     }
  //     let templateSubs = mData.subs
  //     let dataClone = _.cloneDeep(data)
  //     dataClone.subs.forEach((s) => {
  //       let templateSub = templateSubs.find((x) => x.sname == s.sname)
  //       // console.log('x', templateSubs)
  //       // console.log('s', s)
  //       if (templateSub) {
  //         s.time = templateSub.time
  //         s.finish = templateSub.finish
  //         s.initialFinish = templateSub.finish
  //       } else {
  //         s.time = 0
  //         s.finish = 0
  //         s.initialFinish = 0
  //       }
  //     })
  //     setStamp(dataClone.subs)
  //     setData(dataClone)

  //     alert('Time and Reward Info Transferred from ' + templateName + '!')
  //   } else {
  //     alert('No template found under the name ' + templateName + '!')
  //   }
  // }

  useEffect(() => {
    console.log('Stamp updated')
    // Refresh the total time units label
    // alert('Stamp change')
    let totalTu = 0
    let totalReward = 0
    stamp.forEach((s) => {
      /* if (s.hasOwnProperty('countUp') && s.countUp == 1) {
        return // skip countUp tasks
      } */
      if (s.time) {
        if (isNumber(s.time)) {
          totalTu += Number(s.time)
        }
      }
      if (s.finish) {
        if (isNumber(s.finish)) {
          totalReward += Number(s.finish)
        }
      }
    })
    setTotalTime(totalTu)
    setTotalTimeReward(totalReward)
  }, [stamp, optUpdateFlag])

  const handleCountUpCheckbox = (sn, checked) => {
    let newStamp = _.cloneDeep(stamp)
    // find the index of property sn in newStamp
    let index = newStamp.findIndex((x) => x.sn === sn)

    if (checked) {
      newStamp[index].countUp = 1
      newStamp[index].time = 0
      newStamp[index].finish = payrate
      newStamp[index].initialFinish = payrate
      newStamp[index].originalFinish = payrate
      newStamp[index].originalTime = 0
    } else {
      if (newStamp[index].hasOwnProperty('countUp')) {
        delete newStamp[index].countUp
      }
    }
    setStamp(newStamp)
    setOptUpdateFlag(optUpdateFlag + 1)
  }

  const handleTemplateNameChange = (e) => {
    setTemplateName(e.target.value)
  }

  const createNewStamp = () => {
    // Cast data to appropriate type
    let mNewStamp = _.cloneDeep(stampCreate)
    mNewStamp.finish = Number(mNewStamp.finish)
    if (mNewStamp.expired == '') {
      mNewStamp.expiredDate = moment().add(14, 'day').toDate()
      mNewStamp.expired = moment().add(14, 'day').toISOString()
    } else {
      mNewStamp.expiredDate = moment(mNewStamp.expired, 'DD/MM/YYYY HH:mm:ss').toDate()
      mNewStamp.expired = moment(mNewStamp.expired, 'DD/MM/YYYY HH:mm:ss').toISOString()
    }
    mNewStamp.subs = []
    mNewStamp.id = mNewStamp.id.toUpperCase()
    mNewStamp.sn = mNewStamp.id + '-' + make_serial(6, 6)
    mNewStamp.tname = mNewStamp.description
    mNewStamp.totalReward = 0
    mNewStamp.validFrom = moment().toDate()

    // Save to database
    setDocx(docx(db, 'subtasks', mNewStamp.sn), mNewStamp).then(() => {
      alert('New stamp created!')
      loadStamp()
    })
  }

  const handleStampCreateChange = (e, fieldname) => {
    let mStampCreate = _.cloneDeep(stampCreate)
    if (fieldname == 'id') {
      mStampCreate.id = e.target.value
    }
    if (fieldname == 'description') {
      mStampCreate.description = e.target.value
    }
    if (fieldname == 'expired') {
      mStampCreate.expired = e.target.value
    }
    if (fieldname == 'finish') {
      mStampCreate.finish = e.target.value
    }
    setStampCreate(mStampCreate)
  }

  const appendFromTemplate = async () => {
    let docRef = docx(db, 'templates', templateName)
    let docSnap = await getDocx(docRef)
    if (docSnap.exists()) {
    let mData = docSnap.data()

    console.log('mData', mData)

    // Fix the datetime in the template first
    try {
      mData.subs.forEach((x) => {
        if (x.hasOwnProperty('validFromDate')) {
          x.validFromDate = moment(x.validFromDate).format("DD/MM/YYYY HH:mm:ss")
        }
        if (x.hasOwnProperty('expiryDate')) {
          x.expiryDate = moment(x.expiryDate.toDate()).format("DD/MM/YYYY HH:mm:ss")
        }
      })
    } catch (e) {
      console.log('Error in fixing datetime in the template')
      console.log(e)
    }
      
    let newStampFromScratch = _.cloneDeep(stamp)

    // Iterate through each subtasks in the template mData 
    if (mData.subs != null)
    {
      mData.subs.forEach((s) => {
        // Check if s.sname (subtask in template) is already in newStamp
        // if not, append it to newStamp
        let found = stamp.findIndex((x) => x.sname == s.sname)
        if (found == -1)
        {
          // Append s to stamp
          newStampFromScratch.push(s)
        }
      })
    }

    setStamp(newStampFromScratch)

    let newData = _.cloneDeep(data)
    newData.subs = newStampFromScratch

      // if (mData.hasOwnProperty('sn')) {
      //   delete mData.sn
      // }
      // if (mData.hasOwnProperty('expired')) {
      //   delete mData.expired
      // }
      // if (mData.hasOwnProperty('expiredDate')) {
      //   delete mData.expiredDate
      // }
      
      setData({
        ...newData,
        sn: dailySn,
        expired: data.expired,
        expiredDate: data.expiredDate,
      })
      alert('Stamp appended from template library under the name ' + templateName + '!')
    } else {
      alert('No template found under the name ' + templateName + '!')
    }
  }

  const promoteToTop = (sname) => {
    let newStamp = _.cloneDeep(stamp)
    let found = newStamp.findIndex((x) => x.sname == sname)
    if (found != -1) {
      let s = newStamp.splice(found, 1)
      newStamp.unshift(s[0])
      setStamp(newStamp)
      setData({ ...data, subs: newStamp })
    }
  }

  const demoteToBottom = (sname) => {
    let newStamp = _.cloneDeep(stamp)
    let found = newStamp.findIndex((x) => x.sname == sname)
    if (found != -1) {
      let s = newStamp.splice(found, 1)
      newStamp.push(s[0])
      setStamp(newStamp)
      setData({ ...data, subs: newStamp })
    }
  }

  const moveUp = (sname) => {
    let newStamp = _.cloneDeep(stamp)
    let found = newStamp.findIndex((x) => x.sname == sname)
    if (found != -1 && found != 0) {
      let s = newStamp.splice(found, 1)
      newStamp.splice(found - 1, 0, s[0])
      setStamp(newStamp)
      setData({ ...data, subs: newStamp })
    }
  }

  const moveDown = (sname) => {
    let newStamp = _.cloneDeep(stamp)
    let found = newStamp.findIndex((x) => x.sname == sname)
    if (found != -1 && found != newStamp.length - 1) {
      let s = newStamp.splice(found, 1)
      newStamp.splice(found + 1, 0, s[0])
      setStamp(newStamp)
      setData({ ...data, subs: newStamp })
    }
  }

  const handleStampSelectFromDropDown = (subSn) => {
    if (subSn == '') {
      return
    }
    console.log('subSn: ' + subSn)
    setDailySn(subSn)
    // Find the sub with sn in allSubs
    let sub = allSubs.find((x) => x.sn == subSn)
    // if subSn contains "DAILY"
    if (subSn.includes('DAILY')) {
      alert('To edit the daily stamp, you will be redirected to the daily stamp editor')
      window.location.href = '/edit'
      return
    }
    let subs = sub.subs
    // console.log(sub)
    if (sub.hasOwnProperty('id')) {
      setTemplateName(sub.id.toLowerCase())
    }
    setStamp(subs) // is the subs of the daily stamp
    setData(sub) // is the daily stamp
    setStampId(sub.id)
  }

  return (
    <div className='AppRootDesktop'>
      {savingStamp && <div className='LoadingIcon'>
        <Spinner animation='border' role='status'></Spinner>
      </div>}
      <div className='AppRootInnerOverlay'>
      <div className="EditAnySticky">
        <div>
          <div>Total time units: {totalTime.toFixed(0)}</div>
          <div>Total pay: {totalTimeReward.toFixed(2)}</div>
        </div>
        <div style={{flex: 1}}></div>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Button variant='success' onClick={() => loadStamp()}><FaSync></FaSync></Button>
        </div>
      </div>
      <div className="EditAnyBody">
        <div>
          {/* Show a combo box to choose the SubTask to edit */}
          <div className="TaskSelector">
            <Form.Group controlId="formSubTaskSelect">
              <Form.Label>Choose a SubTask to edit</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => {
                  let subSn = e.target.value
                  handleStampSelectFromDropDown(subSn)
                }}>
                <option value="">Choose a SubTask</option>
                {allSubs.map((sub) => (
                  <option value={sub.sn}>{sub.tname}</option>
                ))}
              </Form.Control>
            </Form.Group>
            <div className="EditAnySns">
              <div>Data SN: {data.sn}</div>
              <div>Current SN: {dailySn}</div>
            </div>
            <div>
              <Button
                variant="danger"
                onClick={() => {
                  resetZero()
                }}>
                Reset to zero
              </Button>
            </div>
          </div>
        </div>

        <div></div>
        {stamp.map((sub) => (
          <>
            <div className="EditAnyEdit">
              <div>
                <Button
                  variant={sub.time == 0 ? 'danger' : 'outline-primary'}
                  onClick={() => setTimeByButton(sub.sn, 0)}>
                  0
                </Button>
                <Button
                  variant={sub.time == 1 ? 'primary' : 'outline-primary'}
                  onClick={() => setTimeByButton(sub.sn, 1)}>
                  1
                </Button>
                <Button
                  variant={sub.time == 2 ? 'primary' : 'outline-primary'}
                  onClick={() => setTimeByButton(sub.sn, 2)}>
                  2
                </Button>
                <Button
                  variant={sub.time == 3 ? 'primary' : 'outline-primary'}
                  onClick={() => setTimeByButton(sub.sn, 3)}>
                  3
                </Button>
                <Button
                  variant={sub.time == 4 ? 'primary' : 'outline-primary'}
                  onClick={() => setTimeByButton(sub.sn, 4)}>
                  4
                </Button>
                <Button
                  variant={sub.time == 5 ? 'primary' : 'outline-primary'}
                  onClick={() => setTimeByButton(sub.sn, 5)}>
                  5
                </Button>
              </div>
              <div className="Field SubTaskTitleEdit">
                <div>Name: </div>
                <div>
                  <input
                    type="text"
                    value={sub.sname}
                    onChange={(e) => {
                      handleTextChange(e, 'sname', sub.sn)
                    }}></input>
                </div>
              </div>
              <div className="Field">
                <div>Time units: </div>
                <div>
                  <input
                    type="text"
                    value={sub.time}
                    onChange={(e) => {
                      handleTextChange(e, 'time', sub.sn)
                    }}></input>
                </div>
              </div>
              <div className="Field">
                <div>Pay: ({sub.hasOwnProperty('initialFinish') ? sub.initialFinish : 'N/A'})</div>
                <div>
                  <input
                    type="text"
                    value={sub.finish}
                    onChange={(e) => {
                      handleTextChange(e, 'pay', sub.sn)
                    }}></input>
                </div>
              </div>

              <div className="Field">
                <div>Bonus coeff: </div>
                <div>
                  <input
                    type="text"
                    value={sub.bonusCoeff}
                    onChange={(e) => {
                      handleTextChange(e, 'bonusCoeff', sub.sn)
                    }}></input>
                </div>
              </div>
              <div className="Field">
                <div>Orig time: </div>
                <div>
                  <input
                    type="text"
                    value={sub.originalTime}
                    onChange={(e) => {
                      handleTextChange(e, 'originalTime', sub.sn)
                    }}></input>
                </div>
              </div>
              <div className="Field">
                <div>Orig pay: </div>
                <div>
                  <input
                    type="text"
                    value={sub.originalFinish}
                    onChange={(e) => {
                      handleTextChange(e, 'originalFinish', sub.sn)
                    }}></input>
                </div>
              </div>
              <div className="Field">
                <div>Count up:</div>
                <div>
                  <input
                    type="checkbox"
                    checked={sub.hasOwnProperty('countUp') && sub.countUp == 1}
                    onChange={(e) => {
                      handleCountUpCheckbox(sub.sn, e.target.checked)
                    }}></input>
                </div>
              </div>

              <div className="Field">
                <div>Valid from: </div>
                <div>
                  <input
                    type="text"
                    value={sub.validFromDate ? sub.validFromDate : ""}
                    onChange={(e) => {
                      handleTextChange(e, 'validFromDate', sub.sn)
                    }}></input>
                </div>
              </div>

              <div className="Field">
                <div>Valid until: </div>
                <div>
                  <input
                    type="text"
                    value={sub.expiryDate ? sub.expiryDate : ""}
                    onChange={(e) => {
                      handleTextChange(e, 'expiryDate', sub.sn)
                    }}></input>
                </div>
              </div>

              <div className="Field">
                <div>
                  Min Minutes for Feedback Inquiry:
                </div>
                <div>
                <input
                  type="text"
                  value={sub.allowFeedback ? sub.allowFeedback : ''}
                  onChange={(e) => {handleTextChange(e, "allowFeedback", sub.sn)}}
                ></input>
                </div>
              </div>

              <div className="Field">
                <div>Tags: </div>
                <div>
                  <input
                    type="text"
                    value={sub.tags ? sub.tags : ""}
                    onChange={(e) => {
                      handleTextChange(e, 'tags', sub.sn)
                    }}></input>
                </div>
              </div>

              <div>
                {sub.subsubs.map((ss) => (
                  <div className="EditAnySubSubTask">
                    <div>
                      <input
                        type="text"
                        value={ss.name}
                        onChange={(e) => handleSubTextChange(e, sub.sn, ss.sn, 'name')}></input>
                    </div>
                    <div>
                      <input
                        type="text"
                        value={ss.finish}
                        onChange={(e) => handleSubTextChange(e, sub.sn, ss.sn, 'finish')}></input>
                    </div>
                    <div>
                      <input
                        type="text"
                        value={ss.validFrom ? ss.validFrom : ""}
                        placeholder='Valid from DMY HMS'
                        onChange={(e) => handleSubTextChange(e, sub.sn, ss.sn, 'validFrom')}></input>
                    </div>
                    <div>
                      <Button
                        variant="danger"
                        onClick={() => {
                          deleteSubSub(sub.sn, ss.sn)
                        }}>
                        <MdDelete></MdDelete> Delete
                      </Button>
                      <Button
                        variant="success"
                        onClick={() => {
                          addToDoneList(sub.sn, ss.sn, ss.name, ss.finish)
                        }}
                        style={{ marginLeft: 2 }}>
                        <BiCheck></BiCheck> Mark as done
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
              <div className='TaskCommandButtons'>
                <div>
                  <Button
                    variant="success"
                    onClick={() => {
                      addSubSub(sub.sn)
                    }}>
                    <FaPlus></FaPlus> Add
                  </Button>
                </div>
                <div>
                  <Button
                    variant="danger"
                    onClick={() => {
                      deleteSub(sub.sn)
                    }}
                    style={{ marginLeft: 2 }}>
                    <MdDelete></MdDelete> Delete
                  </Button>
                </div>
                <div>
                <Button
                    variant="success"
                    onClick={() => {
                      addToDoneListForSubs(sub.sn)
                    }}
                    style={{ marginLeft: 2 }}>
                    <BiCheck></BiCheck> Done
                  </Button>
                </div>
                <div>
                  <Button
                    variant="primary"
                    onClick={() => {
                      promoteToTop(sub.sname)
                    }}
                    style={{ marginLeft: 2 }}>
                    <FaArrowUp></FaArrowUp> Top
                  </Button>
                </div>
                <div>
                  <Button
                    variant="primary"
                    onClick={() => {
                      demoteToBottom(sub.sname)
                    }}
                    style={{ marginLeft: 2 }}>
                    <FaArrowDown></FaArrowDown> Bottom
                  </Button>
                </div>
                <div>
                  <Button
                    variant="primary"
                    onClick={() => {
                      moveUp(sub.sname)
                    }}
                    style={{ marginLeft: 2 }}>
                    <FaArrowUp></FaArrowUp>
                  </Button>
                </div>
                <div>
                  <Button
                    variant="primary"
                    onClick={() => {
                      moveDown(sub.sname)
                    }}
                    style={{ marginLeft: 2 }}>
                    <FaArrowDown></FaArrowDown>
                  </Button>
                </div>
              </div>

            </div>
          </>
        ))}

        <div>
          <div className="EditAnyTemplate" style={{ marginTop: 12 }}>
            <div>Template Name</div>
            <input type="text" onChange={(e) => handleTemplateNameChange(e)} value={templateName}></input>
            {(templateName && templateName.toLowerCase() == 'daily') && <div style={{ marginTop: 8 }}>
              For the daily stamp, please use the other <a href='/edit'>edit</a> interface!
              </div>}
            {(templateName && templateName.toLowerCase() != 'daily') && <div style={{ marginTop: 8 }}>
              <Button
                variant="success"
                onClick={() => {
                  saveToTemplate()
                }}
                style={{ marginLeft: 2 }}>
                <FaSave></FaSave> Save to Template
              </Button>
              <Button
                variant="warning"
                onClick={() => {
                  loadFromTemplate()
                }}
                style={{ marginLeft: 2 }}>
                <FaPlus></FaPlus> Load from Template
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  appendFromTemplate()
                }}
                style={{ marginLeft: 2 }}>
                <FaPlus></FaPlus> Append from Template
              </Button>
            </div>}
          </div>
        </div>

        <div className="EditAnyButtonControls" style={{ display: 'flex', flexDirection: 'row' }}>
          <div>
            <Button
              variant="success"
              onClick={() => {
                addSub()
              }}>
              <FaPlus></FaPlus> Add
            </Button>
          </div>
          {!savingStamp && <div>
            <Button
              variant="success"
              onClick={() => {
                save()
              }}
              style={{ marginLeft: 2 }}>
              <FaSave></FaSave> Save
            </Button>
          </div>}
          <div>
            <Button
              variant="warning"
              onClick={() => {
                resetTime()
              }}
              style={{ marginLeft: 2 }}>
              <BiReset></BiReset> Reset to default
            </Button>
          </div>
          <div>
            <Button
              variant="primary"
              onClick={() => {
                resetZero()
              }}
              style={{ marginLeft: 2 }}>
              <BiReset></BiReset> Reset to zero
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                deleteStamp()
              }}
              style={{ marginLeft: 2 }}>
                <MdDelete></MdDelete> Delete Stamp
            </Button>
          </div>
        </div>
        
        <div className="NewStamp">
          <h1>Add a new stamp</h1>
          <div style={{height: 12}}></div>
          <div className="NewStampField">
            <div>ID (5 capital characters)</div>
            <div>
              <input type="text" value={stampCreate.id} onChange={(e) => handleStampCreateChange(e, 'id')}></input>
            </div>
          </div>
          <div className="NewStampField">
            <div>Description</div>
            <div>
              <input
                type="text"
                value={stampCreate.description}
                onChange={(e) => handleStampCreateChange(e, 'description')}></input>
            </div>
          </div>
          <div className="NewStampField">
            <div>Expiry date (DD/MM/YYYY HH:mm:ss)</div>
            <div>
              <input
                type="text"
                value={stampCreate.expired}
                onChange={(e) => handleStampCreateChange(e, 'expired')}></input>
            </div>
          </div>
          <div className="NewStampField">
            <div>Finish Reward</div>
            <div>
              <input
                type="text"
                value={stampCreate.finish}
                onChange={(e) => handleStampCreateChange(e, 'finish')}></input>
            </div>
          </div>
          <div>
            <Button variant="success" onClick={() => createNewStamp()}>
              <FaPlus></FaPlus> Create
            </Button>
          </div>
        </div>
        <div style={{ paddingTop: 16, paddingBottom: 16, marginLeft: 4 }}>
          {doneList.length} tasks marked as completed (include many subsubs)
        </div>
      </div>
    </div>
    </div>
  )
}

export default EditAny
