import './MyForm.css'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { useState, useEffect } from 'react'

import token from './Token';
import { MdDelete, MdDownload } from 'react-icons/md';
import { Spinner } from 'react-bootstrap';

import moment from 'moment'

function MyForm() {
    const demoForm = [
        {
            "q": "Repeat: I will behave in a way that is respectful to others: my family, my girlfriend.",
            "t": "textarea",
            "a": ""
        },
        {
            "q": "Repeat: I will listen to my family and my girlfriend when they speak to me.",
            "t": "text",
            "a": ""
        },
        {
            "q": "What is your energy level today?",
            "t": "button",
            "a": "1",
            "c": ["1", "2", "3", "4", "5"]
        },
        {
            "q": "What is your mood today?",
            "t": "select",
            "a": "Happy",
            "c": ["Happy", "Sad", "Angry", "Anxious", "Neutral"]
        },
        {
            "q": "Check here if you have completed your homework.",
            "t": "check",
            "a": false
        }
    ]

    const formOath = [
        {
            "q": "Repeat: I will behave in a way that is respectful to others: my family, my girlfriend.",
            "t": "textarea",
            "a": ""
        },
        {
            "q": "Write an example scenario that you may encounter where you will need to be respectful to others.",
            "t": "textarea",
            "a": ""
        },
    ]

    const [loading, setLoading] = useState(false)
    const [formTemplates, setFormTemplates] = useState([])
    const [formName, setFormName] = useState('')
    const [data, setData] = useState([])
    const [pastResponses, setPastResponses] = useState([])

    useEffect(() => {
        // set data to the corresponding formName
        // find the form with form.name === formName

        let form = formTemplates.find((ft) => ft.name === formName)
        console.log(form)
        if (form) {
            setData(form.form)
        } else {
            setData([])
        }
    }, [formName])

    const handleTextChange = (q, a) => {
        const newData = data.map((d) => {
            if (d.q === q) {
                d.a = a
            }
            return d
        })
        setData(newData)
    }

    const submitForm = async () => {
        setLoading(true)
        let jsonFormData = JSON.stringify(data)
        const result = await fetch('https://paymemobile.fr/savediary?token=' + token, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: 'data=' + jsonFormData + '&formName=' + formName
        })
        await getPastResponses()
        setLoading(false)
    }

    const getPastResponses = async () => {
        const result = await fetch('https://paymemobile.fr/diary?token=' + token)
        const jsonResult = await result.json()
        setPastResponses(jsonResult)
    }

    const getFormTemplates = async () => {
        const result = await fetch('https://paymemobile.fr/diaryTemplates?token=' + token)
        const jsonResult = await result.json()
        setFormTemplates(jsonResult)
    }

    useEffect(() => {
        setLoading(true)
        document.title = 'Ordo Declaration Services'
        getPastResponses()
        getFormTemplates()
        setLoading(false)
    }, [])


return (
    <div className='AppRootDesktop'>
        <div className='AppRootInnerOverlay'>
            <div className='MyFormContainer'>
                <h1>Ordo Declaration Services</h1>
                <div className='QAFormSelector'>
                    <h2>Select a form:</h2>
                    <Form.Select onChange={(e) => {
                        setFormName(e.target.value)
                    }}>
                        <option value=''>Select a form...</option>
                        {formTemplates.map((ft, i) => (
                            <option key={i} value={ft.name}>{ft.name}</option>
                        ))}
                    </Form.Select>
                </div>
                <div className="QAForm">
                    {data.map((q, i) => (
                        <div key={i} className="QAFormRow">
                            <div className="QAFormQuestion">{q.q}</div>
                            <div className="QAFormAnswer">
                                {q.t === 'text' &&
                                    <Form.Control type='text' onChange={(e) => {
                                        handleTextChange(q.q, e.target.value)
                                    }} value={q.a}></Form.Control>
                                }
                                {q.t === 'textarea' &&
                                    <Form.Control as='textarea' onChange={(e) => {
                                        handleTextChange(q.q, e.target.value)
                                    }} value={q.a}></Form.Control>
                                }
                                {q.t === 'button' &&
                                    <ButtonGroup>
                                        {q.c.map((c, j) => (
                                            <Button key={j} onClick={() => {
                                                handleTextChange(q.q, c)
                                            }} variant={q.a === c ? 'primary' : 'secondary'}>{c}</Button>
                                        ))}
                                    </ButtonGroup>
                                }
                                {q.t === 'select' &&
                                    <Form.Select onChange={(e) => {
                                        handleTextChange(q.q, e.target.value)
                                    }} value={q.a}>
                                        {q.c.map((c, j) => (
                                            <option key={j} value={c}>{c}</option>
                                        ))}
                                    </Form.Select>
                                }
                                {q.t === 'check' &&
                                    <Form.Check onChange={(e) => {
                                        handleTextChange(q.q, e.target.checked)
                                    }} checked={q.a}></Form.Check>
                                }
                            </div>
                        </div>
                    ))}
                </div>
                <div className='QAFormSubmit'>
                    {!loading && <Button onClick={submitForm}>Submit</Button> }
                    <Spinner animation="border" role="status" style={{display: loading ? '' : 'none'}}></Spinner>
                </div>

                <div className='QAPastResponses'>
                    <h2>Past Responses</h2>
                    <table className="bordered-table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Form</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pastResponses.map((pr, i) => (
                                <tr key={i}>
                                    <td>{moment(pr.date).format('MMMM Do YYYY, h:mm:ss a')}</td>
                                    <td>{pr.name}</td>
                                    <td>
                                        <MdDownload onClick={() => window.location.href='https://paymemobile.fr/diarypdf?token='+token+'&id='+pr._id}></MdDownload>
                                        <MdDelete onClick={async () => {
                                            const result = await fetch('https://paymemobile.fr/deletediary?token='+token+'&id='+pr._id)
                                            getPastResponses()
                                        }} style={{marginLeft: '10px'}}></MdDelete>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>
)
}

export default MyForm;