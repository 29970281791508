import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import Button from 'react-bootstrap/Button'
import ApiCalendar from 'react-google-calendar-api';
import './Calendar.css'
import { useSelector, useDispatch } from 'react-redux'
import CalendarPersonal from './CalendarPersonal'
import CalendarDaily from './CalendarDaily';
import CalendarNowPersonal from './CalendarNowPersonal';
import CalendarNowDaily from './CalendarNowDaily';
import CalendarMedicine from './CalendarMedicine';
import { setImminentCalendarBar } from './calendarReducers'
import CalendarEmpty from './CalendarEmpty';


function Calendar({ onToggleCalendar })
{
    // Redux states
    const dailyCalendar = useSelector(state => state.calendar.daily)
    const personalCalendar = useSelector(state => state.calendar.personal)
    const eventActivePersonal = useSelector(state => state.calendar.eventActivePersonal)
    const eventActiveDaily = useSelector(state => state.calendar.eventActiveDaily)
    const eventReadyPersonal = useSelector(state => state.calendar.eventReadyPersonal)
    const eventReadyDaily = useSelector(state => state.calendar.eventReadyDaily)

    const dispatch = useDispatch()
    
    const [slide, setSlide] = useState(0)
    const [slideshow, setSlideShow] = useState([0,1])
    const [showEmptyCalendarBar, setShowEmptyCalendarBar] = useState(false)

    const getSlideIndices = () => {
        let indices = []
        if (dailyCalendar && dailyCalendar.length > 0)
        {
            // console.log('daily calendar')
            // console.log(dailyCalendar)
            // if there is at least one daily event in the future
            for (let i = 0; i < dailyCalendar.length; i++)
            {
                let event = dailyCalendar[i]
                let now = moment()
                if (event.start.dateTime)
                {
                    let start = moment(event.start.dateTime)
                    if (start > now)
                    {
                        indices.push(1)
                        break
                    }
                } else {
                    let start = moment(event.start.date)
                    if (start > now)
                    {
                        indices.push(1)
                        break
                    }
                }
            }
        }
        if (personalCalendar && personalCalendar.length > 0)
        {
            // console.log('personal calendar')
            // console.log(personalCalendar)
            // if there is at least one personal event in the future
            for (let i = 0; i < personalCalendar.length; i++)
            {
                let event = personalCalendar[i]
                let now = moment()
                if (event.start.dateTime)
                {
                    let start = moment(event.start.dateTime)
                    if (start > now)
                    {
                        indices.push(0)
                        break
                    }
                } else {
                    let start = moment(event.start.date)
                    if (start > now)
                    {
                        indices.push(0)
                        break
                    }
                }
            }
        }
        if (eventActivePersonal)
        {
            // console.log('active personal')
            indices.push(2)
        }
        if (eventActiveDaily)
        {
            // console.log('active daily')
            indices.push(3)
        }
        // sort the indices
        indices.sort((a, b) => a - b)
        return indices
    }

    useEffect(() => {
        //console.log('Daily calendar trigger')
        //console.log(dailyCalendar)
        let timerHandler
        timerHandler = !timerHandler && setInterval(()=>{
            // console.log('Tick')
            setSlide(prevSlide => (prevSlide + 1))
        }, 5000)
        return () => clearInterval(timerHandler);
    }, [])

    useEffect(() => {
        let indices = getSlideIndices()
        if (indices.length === 0)
        {
            setShowEmptyCalendarBar(true)
        } else {
            setShowEmptyCalendarBar(false)
        }
        setSlideShow(indices)
        // console.log(eventActivePersonal)
        // if ((eventActivePersonal===null) && (eventActiveDaily===null))
        // {
        //     // setSlide(prevSlide => prevSlide + 2)
        //     setSlideShow([0,1])
        // }
        // if ((eventActivePersonal!==null) && (eventActiveDaily!==null))
        // {
        //     setSlideShow([0,1,2,3])
        // }
        // if ((eventActivePersonal!==null) && (eventActiveDaily===null))
        // {
        //     setSlideShow([0,1,2])
        // }
        // if ((eventActivePersonal===null) && (eventActiveDaily!==null))
        // {
        //     // setSlide(prevSlide => prevSlide + 1)
        //     setSlideShow([0,1,3])
        // }
    }, [eventActivePersonal, eventActiveDaily, dailyCalendar, personalCalendar])

    useEffect(() => {
        if (eventReadyPersonal || eventReadyDaily || eventActiveDaily || eventActivePersonal)
        {
            if (eventActiveDaily || eventActivePersonal)
            {
                dispatch(setImminentCalendarBar({
                    imminentCalendarBar: 'active'
                }))
            } else {
                dispatch(setImminentCalendarBar({
                    imminentCalendarBar: 'imminent'
                }))
            }
        } else {
            dispatch(setImminentCalendarBar({
                imminentCalendarBar: ''
            }))
        }
    }, [eventReadyPersonal, eventReadyDaily, eventActiveDaily, eventActivePersonal])

    const getSlide = (slide) => {
        // console.log('getSlide called')
        // Return the modded slide
        let slideGroup = slide % 4 
        if (slideGroup>=2) // 2 and 3
        {
            /* console.log('inside getSlide')
            console.log(eventActivePersonal)
            console.log(eventActiveDaily) */
            // Display the "now event"
            if ((eventActivePersonal===null) && (eventActiveDaily===null))
            {
                // setSlide(prevSlide => prevSlide + 2)
                return 0
            }
            if ((eventActivePersonal!==null) && (eventActiveDaily!==null))
            {
                return slideGroup
            }
            if ((eventActivePersonal!==null) && (eventActiveDaily===null))
            {
                // console.log('hit return 2')
                // setSlide(prevSlide => prevSlide + 1)
                return 2
            }
            if ((eventActivePersonal===null) && (eventActiveDaily!==null))
            {
                // setSlide(prevSlide => prevSlide + 1)
                return 3
            }
        } else {
            return slideGroup
        }
    }
    
    return(
        <div className={`CalendarBarRounded`}>
            {showEmptyCalendarBar && <CalendarEmpty></CalendarEmpty>}
            <div className={`${showEmptyCalendarBar ? "CalendarBarHidden" : ""}`}>
                <CalendarMedicine></CalendarMedicine>
                {
                    (<div style={{display: slideshow[slide % slideshow.length]==0 ? 'flex' : 'none', width: '100%'}}><CalendarPersonal onToggleCalendar={onToggleCalendar}></CalendarPersonal></div>)
                }
                {
                    (<div style={{display: slideshow[slide % slideshow.length]==1 ? 'flex' : 'none', width: '100%'}}><CalendarDaily onToggleCalendar={onToggleCalendar}></CalendarDaily></div>)
                }
                {
                    (<div style={{display: slideshow[slide % slideshow.length]==2 ? 'flex' : 'none', width: '100%'}}><CalendarNowPersonal onToggleCalendar={onToggleCalendar}></CalendarNowPersonal></div>)
                }
                {
                    (<div style={{display: slideshow[slide % slideshow.length]==3 ? 'flex' : 'none', width: '100%'}}><CalendarNowDaily onToggleCalendar={onToggleCalendar}></CalendarNowDaily></div>)
                }
            </div>
        </div>
    )
}

// export a memoized version of the component
export default React.memo(Calendar);