import { useEffect, useState } from 'react';
import React from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import "./Feedback.css"
import moment from 'moment';
import { FaQuestionCircle } from 'react-icons/fa';
import token from './Token';

const Feedback = ({taskId, duration, submitSuccessfulHandler, cancelHandler}) => {
    const [feedback, setFeedback] = useState({
        datetime: moment().toISOString(),
        taskId: '',
        duration: 0, // in minutes
        concentration: 0, // -1, 0, 1
        diy: false, 
        challenging: false,
        content: false
    });

    // Sync state with props
    useEffect(() => {
        setFeedback({...feedback,
            taskId: taskId,
            duration: duration
        })
    }, [taskId, duration])

    const handleButtonClick = (key, value) => {
        setFeedback({...feedback, [key]: value})
    }

    const submitForm = () => {
        const formData = new URLSearchParams();
        formData.append('token', token);
        for (const [key, value] of Object.entries(feedback)) {
            if (value !== null && value !== undefined)
            {
                formData.append(key, value);
            } else {
                formData.append(key, "");
            }
        }

        fetch('https://paymemobile.fr/saveSessionProgress', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: formData.toString()
        })
        .then(response => {
            // Handle the response
            console.log(response)
            if (submitSuccessfulHandler) {
                submitSuccessfulHandler();
            }
        })
        .catch(error => {
            // Handle the error
            console.log(error)
        });
    }

    return (
        <div className="feedbackForm">
            <div className="feedbackTaskInfo">
                Feedback for: {feedback.taskId ? feedback.taskId : "No task selected"}
            </div>
            <div className="question">
                <div className="question-text">
                    <FaQuestionCircle></FaQuestionCircle> Focused well?
                </div>
                <div className="feedback">
                    <ButtonGroup aria-label="focus_lvl">
                        <Button variant={feedback.concentration == 1 ? "primary" : "secondary"} onClick={() => handleButtonClick("concentration", 1)}>Better</Button>
                        <Button variant={feedback.concentration == 0 ? "primary" : "secondary"} onClick={() => handleButtonClick("concentration", 0)}>Same</Button>
                        <Button variant={feedback.concentration == -1 ? "primary" : "secondary"} onClick={() => handleButtonClick("concentration", -1)}>Worse</Button>
                    </ButtonGroup>
                </div>
            </div>

            <div className="question">
                <div className="question-text">
                    <FaQuestionCircle></FaQuestionCircle> Do something yourself (not just watch or read)
                </div>
                <div className="feedback">
                    <ButtonGroup aria-label="focus_lvl">
                        <Button variant={feedback.diy ? "primary" : "secondary"}  onClick={() => handleButtonClick("diy", true)}>Yes</Button>
                        <Button variant={!feedback.diy ? "primary" : "secondary"} onClick={() => handleButtonClick("diy", false)}>No</Button>
                    </ButtonGroup>
                </div>
            </div>

            <div className="question">
                <div className="question-text">
                    <FaQuestionCircle></FaQuestionCircle> Anything challenging?
                </div>
                <div className="feedback">
                    <ButtonGroup aria-label="focus_lvl">
                        <Button variant={feedback.challenging ? "primary" : "secondary"} onClick={() => handleButtonClick("challenging", true)}>Yes</Button>
                        <Button variant={!feedback.challenging ? "primary" : "secondary"} onClick={() => handleButtonClick("challenging", false)}>No</Button>
                    </ButtonGroup>
                </div>
            </div>

            <div className="question">
                <div className="question-text">
                    <FaQuestionCircle></FaQuestionCircle> Are you content?
                </div>
                <div className="feedback">
                    <ButtonGroup aria-label="focus_lvl">
                        <Button variant={feedback.content ? "primary" : "secondary"} onClick={() => handleButtonClick("content", true)}>Yes</Button>
                        <Button variant={!feedback.content ? "primary" : "secondary"} onClick={() => handleButtonClick("content", false)}>No</Button>
                    </ButtonGroup>
                </div>
            </div>

            <div className="question">
                <div className="question-text">
                    Note down if you need to adjust plans, concretize goals, the difficulties you encountered, and what you learned.
                </div>
            </div>

            <div className="feedbackSubmit">
                <Button variant="primary" onClick={() => submitForm()}>Submit</Button>
                <Button variant="secondary" onClick={() => {if (cancelHandler) cancelHandler()}}>Cancel</Button>
            </div>
        </div>
    );
}

export default React.memo(Feedback);