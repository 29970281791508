import { useEffect, useState } from "react";
import token from './Token'
import { Button, Form } from "react-bootstrap";
import './Scheduler.css';
import moment from 'moment';
import _ from 'lodash';

function Scheduler() {
    const [schedules, setSchedules] = useState([]);
    const [templates, setTemplates] = useState(null);
    const [templateSNames, setTemplateSNames] = useState([]);
    const [selectedTemplateId, setSelectedTemplateId] = useState('');

    const [programme, setProgramme] = useState(null);

    const fetchSchedules = async () => {
        const response = await fetch('https://paymemobile.fr/schedules?token=' + token);
        const data = await response.json();
        console.log(data);
        setSchedules(data);

        const responseTemplate = await fetch('https://paymemobile.fr/templates?token=' + token);
        const dataTemplate = await responseTemplate.json();
        console.log(dataTemplate);
        setTemplates(dataTemplate);
    }

    useEffect(() => {
        fetchSchedules();
    }, []);

    const handleTemplateChange = (event) => {
        setSelectedTemplateId(event.target.value);
    }

    const displayProgramme = () => {
        // Find the corresponding programme
        let programme = schedules.find(schedule => schedule.stampId === selectedTemplateId);
        if (!programme) {
            return;
        }
        programme.subs.forEach(sub => {
            sub.dayOfWeekText = sub.dayOfWeek.join(', ');
            sub.dayOfMonthText = sub.dayOfMonth.join(', ');
        });
        setProgramme(programme);
        if (templates && programme) {
            let template = templates.find(template => template.id === selectedTemplateId);
            setTemplateSNames(template.subs.map(sub => sub.sname));
        }

    }

    useEffect(() => {
        displayProgramme();
    }, [selectedTemplateId]);

    const handleTextChange = (index, value, type) => {
        const newProgramme = { ...programme };
        if (type === 'sname') {
            newProgramme.subs[index].sname = value;
        } else if (type === 'frequencyType') {
            newProgramme.subs[index].frequencyType = value;
        } else if (type === 'dayOfWeek') {
            newProgramme.subs[index].dayOfWeekText = value;
            newProgramme.subs[index].dayOfWeek = value.split(',').map(day => parseInt(day));
        } else if (type === 'dayOfMonth') {
            newProgramme.subs[index].dayOfMonthText = value;
            newProgramme.subs[index].dayOfMonth = value.split(',').map(day => parseInt(day));
        } else if (type === 'lastEvaluationDate') {
            newProgramme.subs[index].lastEvaluationDate = value;
        } else if (type === 'forceEvaluation') {
            newProgramme.subs[index].forceEvaluation = value;
        }
        setProgramme(newProgramme);
    }

    const get2AMOfDate = (date) => { // timezone incorporated
        return moment(date).add(-2, "hours").startOf('day').add(2, 'hours') // closest 2AM in the past of lastEvaluationDate
    }

    const getNFromFrequencyTypeForWeek = (frequencyType) => {
        // extract N from "everyNWeeks" of frequencyType
        const regex = /every(\d)Weeks/;
        const match = regex.exec(frequencyType);

        // Check if there's a match
        if (match) {
            // Return the captured digit
            return Number(match[1]);
        } else {
            // Return undefined if no match
            return undefined;
        }
    }

    const getNFromFrequencyTypeForMonth = (frequencyType) => {
        // extract N from "everyNMonths" of frequencyType
        const regex = /every(\d)Months/;
        const match = regex.exec(frequencyType);

        // Check if there's a match
        if (match) {
            // Return the captured digit
            return Number(match[1]);
        } else {
            // Return undefined if no match
            return undefined;
        }
    }

    const getNextEvaluationDate = (frequencyType, dayOfWeek, dayOfMonth, lastEvaluationDate, forceEvaluation = false) => {
        /* Frequency type: daily, weekly, monthly
        dayOfWeek: [] // 0 = Sunday, 1 = Monday, 2 = Tuesday, 3 = Wednesday, 4 = Thursday, 5 = Friday, 6 = Saturday - for weekly type
        dayOfMonth: [] // 1-31 - for monthly type
        lastEvaluationDate: an ISOString
        */
        //    console.log('Timezone: ', timezone)
        try {
            if (forceEvaluation) { // force evaluation => just return the lastEvaluationDate
                return lastEvaluationDate
            }
            const lastEvaluationDateMoment = moment(lastEvaluationDate) // take lastEvaluationDate, convert to Europe/Paris timezone
            //    console.log('lastEvaluationDateMoment: ', lastEvaluationDateMoment.toISOString())
            const twoAMOfLastEvaluationDate = get2AMOfDate(lastEvaluationDateMoment) // closest 2AM in the past of lastEvaluationDate
            //    console.log('@2AM: ', twoAMOfLastEvaluationDate.toISOString())
            //    console.log('lastEvaluationDateMoment: ', lastEvaluationDateMoment.toISOString())
            if (frequencyType == "daily") {
                return twoAMOfLastEvaluationDate.add(1, 'days').toISOString()
            }
            if (frequencyType == "weekly") {
                const dayOfWeekOfTwoAMOfLastEvaluationDate = twoAMOfLastEvaluationDate.day()
                let dayOfWeekPlusOneWeek = dayOfWeek.map(day => day + 7)
                let dayOfWeekExtended = dayOfWeek.concat(dayOfWeekPlusOneWeek)
                dayOfWeekExtended = dayOfWeekExtended.sort((a, b) => a - b) // sort the array in ascending order
                // There are two possibilities: (1) the deltaDays (see below) is zero, (2) the deltaDays is greater than zero
                let nextDayOfWeek = dayOfWeek.find(day => day >= dayOfWeekOfTwoAMOfLastEvaluationDate)
                let deltaDays = nextDayOfWeek - dayOfWeekOfTwoAMOfLastEvaluationDate // >=0 
                let nextEvaluationDate = moment(twoAMOfLastEvaluationDate).add(deltaDays, 'days')
                // Here, if the deltaDays is zero, we need to find the next dayOfWeek that is greater than the dayOfWeekOfTwoAMOfLastEvaluationDate
                // console.log('nextEvaluationDate: ', nextEvaluationDate.toISOString())
                // console.log('lastEvaluationDateMoment: ', lastEvaluationDateMoment.toISOString())
                // console.log('nextEvaluationDate.isBefore(lastEvaluationDateMoment): ', !nextEvaluationDate.isBefore(lastEvaluationDateMoment))
                if (!nextEvaluationDate.isAfter(lastEvaluationDateMoment)) {
                    nextDayOfWeek = dayOfWeekExtended.find(day => day > dayOfWeekOfTwoAMOfLastEvaluationDate) // > not >=
                    deltaDays = nextDayOfWeek - dayOfWeekOfTwoAMOfLastEvaluationDate // >0 
                    nextEvaluationDate = twoAMOfLastEvaluationDate.add(deltaDays, 'days')
                }
                return nextEvaluationDate.toISOString()
            }

            if (frequencyType == "every1Weeks" || frequencyType == "every2Weeks" || frequencyType == "every3Weeks" || frequencyType == "every4Weeks" || frequencyType == "every5Weeks" || frequencyType == "every6Weeks" || frequencyType == "every7Weeks" || frequencyType == "every8Weeks" || frequencyType == "every9Weeks" || frequencyType == "every10Weeks" || frequencyType == "every11Weeks" || frequencyType == "every12Weeks") {
                // extract N from "everyNWeeks" of frequencyType
                // Example: if every2Weeks and dayOfWeek=[1, 3, 5] (Monday, Wednesday, Friday)
                // if the lastEvaluationDate is on a Monday, the next evaluation date is Wednesday
                // if the lastEvaluationDate is on a Wednesday, the next evaluation date is Friday
                // if the lastEvaluationDate is on a Friday, the next evaluation date is Monday of the next 2 weeks
                const N = getNFromFrequencyTypeForWeek(frequencyType)
                let dayOfWeekOfTwoAMOfLastEvaluationDate = twoAMOfLastEvaluationDate.day()

                // Change Sunday to 7 instead of 0
                if (dayOfWeekOfTwoAMOfLastEvaluationDate == 0) {
                    dayOfWeekOfTwoAMOfLastEvaluationDate = 7
                }
                if (dayOfWeek.includes(0)) {
                    dayOfWeek = dayOfWeek.map(day => day == 0 ? 7 : day)
                }

                // Sort dayOfWeek in ascending order
                dayOfWeek = dayOfWeek.sort((a, b) => a - b)

                // console.log('dayOfWeekOfTwoAMOfLastEvaluationDate: ', dayOfWeekOfTwoAMOfLastEvaluationDate)
                // console.log('dayOfWeek: ', dayOfWeek)

                let nextEvaluationDate
                if (dayOfWeekOfTwoAMOfLastEvaluationDate < Math.max(...dayOfWeek)) {
                    // console.log('dayOfWeekOfTwoAMOfLastEvaluationDate < Math.max(...dayOfWeek)')
                    // This is Monday or Wednesday case in the example above
                    // The logic is similar to the weekly case
                    let nextDayOfWeek = dayOfWeek.find(day => day >= dayOfWeekOfTwoAMOfLastEvaluationDate)
                    // console.log('nextDayOfWeek: ', nextDayOfWeek)
                    let deltaDays = nextDayOfWeek - dayOfWeekOfTwoAMOfLastEvaluationDate
                    nextEvaluationDate = twoAMOfLastEvaluationDate.add(deltaDays, 'days')
                    if (!nextEvaluationDate.isAfter(lastEvaluationDateMoment)) {
                        nextDayOfWeek = dayOfWeek.find(day => day > dayOfWeekOfTwoAMOfLastEvaluationDate)
                        deltaDays = nextDayOfWeek - dayOfWeekOfTwoAMOfLastEvaluationDate
                        nextEvaluationDate = twoAMOfLastEvaluationDate.add(deltaDays, 'days')
                    }
                } else { // dayOfWeekOfTwoAMOfLastEvaluationDate >= Math.max(...dayOfWeek)
                    // console.log('dayOfWeekOfTwoAMOfLastEvaluationDate >= Math.max(...dayOfWeek)')
                    // This is Friday case in the example above
                    let firstDayOfWeek = Math.min(...dayOfWeek)
                    let deltaDays = - (dayOfWeekOfTwoAMOfLastEvaluationDate - firstDayOfWeek) + 7 * N
                    nextEvaluationDate = twoAMOfLastEvaluationDate.add(deltaDays, 'days')
                    // No need to check if nextEvaluationDate is before lastEvaluationDate
                }
                return nextEvaluationDate.toISOString()
            }

            if (frequencyType == "monthly") {
                const dayOfMonthOfTwoAMOfLastEvaluationDate = twoAMOfLastEvaluationDate.date() // 1-31
                let daysInMonth = moment(lastEvaluationDate).daysInMonth()
                let dayOfMonthPlusOneMonth = dayOfMonth.map(day => day + daysInMonth)
                let dayOfMonthExtended = dayOfMonth.concat(dayOfMonthPlusOneMonth)
                dayOfMonthExtended = dayOfMonthExtended.sort((a, b) => a - b) // sort the array in ascending order
                // There are two possibilities: (1) the deltaDays (see below) is zero, (2) the deltaDays is greater than zero
                let nextDayOfMonth = dayOfMonth.find(day => day >= dayOfMonthOfTwoAMOfLastEvaluationDate)
                let deltaDays = nextDayOfMonth - dayOfMonthOfTwoAMOfLastEvaluationDate // >=0 
                let nextEvaluationDate = twoAMOfLastEvaluationDate.add(deltaDays, 'days')
                // Here, if the deltaDays is zero, we need to find the next dayOfMonth that is greater than the dayOfMonthOfTwoAMOfLastEvaluationDate
                if (!nextEvaluationDate.isAfter(lastEvaluationDateMoment)) {
                    nextDayOfMonth = dayOfMonthExtended.find(day => day > dayOfMonthOfTwoAMOfLastEvaluationDate) // > not >=
                    deltaDays = nextDayOfMonth - dayOfMonthOfTwoAMOfLastEvaluationDate // >0 
                    nextEvaluationDate = twoAMOfLastEvaluationDate.add(deltaDays, 'days')
                }
                return nextEvaluationDate.toISOString()
            }

            if (frequencyType == "every1Months" || frequencyType == "every2Months" || frequencyType == "every3Months" || frequencyType == "every4Months" || frequencyType == "every5Months" || frequencyType == "every6Months" || frequencyType == "every7Months" || frequencyType == "every8Months" || frequencyType == "every9Months" || frequencyType == "every10Months" || frequencyType == "every11Months" || frequencyType == "every12Months") {
                // extract N from "everyNMonths" of frequencyType
                // Example: if every2Months and dayOfMonth=[1, 15] (1st and 15th of the month)
                // if the lastEvaluationDate is on the 1st, the next evaluation date is the 15th
                // if the lastEvaluationDate is on the 15th, the next evaluation date is the 1st of the next 2 months
                const N = getNFromFrequencyTypeForMonth(frequencyType) // e.g., 2
                let dayOfMonthOfTwoAMOfLastEvaluationDate = twoAMOfLastEvaluationDate.date() // e.g., 1

                // maxDayOfMonth is the maximum of dayOfMonth or the number of days in the month
                let maxDayOfMonth = Math.max(...dayOfMonth)
                if (maxDayOfMonth > moment(lastEvaluationDate).daysInMonth()) {
                    maxDayOfMonth = moment(lastEvaluationDate).daysInMonth()
                }

                // if dayOfMonthOfTwoAMOfLastEvaluationDate equals maximum of dayOfMonth, schedule for the next 2 months
                if (dayOfMonthOfTwoAMOfLastEvaluationDate >= maxDayOfMonth) {
                    // console.log('>=')
                    let dayOfTwoAMOfNextEvaluationDate = Math.min(...dayOfMonth)
                    // console.log('day of month: ', dayOfMonth)
                    let nextEvaluationDate = twoAMOfLastEvaluationDate.set('date', dayOfTwoAMOfNextEvaluationDate).set('month', twoAMOfLastEvaluationDate.month() + N)
                    // console.log('date set to: ', dayOfTwoAMOfNextEvaluationDate)
                    // console.log(twoAMOfLastEvaluationDate.month())
                    // console.log('month set to: ', twoAMOfLastEvaluationDate.month() + N)
                    // console.log('nextEvaluationDate: ', nextEvaluationDate.toISOString())
                    return nextEvaluationDate.toISOString()
                } else {
                    // console.log('<')
                    // if dayOfMonthOfTwoAMOfLastEvaluationDate is less than maximum of dayOfMonth, schedule for the current month
                    let nextEvaluationDate
                    let daysInMonth = moment(lastEvaluationDate).daysInMonth() // e.g., 30
                    let dayOfMonthPlusOneMonth = dayOfMonth.map(day => day + daysInMonth) // e.g., [31, 45]
                    let dayOfMonthExtended = dayOfMonth.concat(dayOfMonthPlusOneMonth) // e.g., [1, 15, 31, 45]
                    dayOfMonthExtended = dayOfMonthExtended.sort((a, b) => a - b) // sort the array in ascending order
                    let nextDayOfMonth = dayOfMonth.find(day => day >= dayOfMonthOfTwoAMOfLastEvaluationDate) // e.g., 15
                    let deltaDays = nextDayOfMonth - dayOfMonthOfTwoAMOfLastEvaluationDate // >=0 
                    nextEvaluationDate = twoAMOfLastEvaluationDate.add(deltaDays, 'days')
                    // Here, if the deltaDays is zero, we need to find the next dayOfMonth that is greater than the dayOfMonthOfTwoAMOfLastEvaluationDate
                    if (!nextEvaluationDate.isAfter(lastEvaluationDateMoment)) {
                        nextDayOfMonth = dayOfMonthExtended.find(day => day > dayOfMonthOfTwoAMOfLastEvaluationDate) // > not >=
                        deltaDays = nextDayOfMonth - dayOfMonthOfTwoAMOfLastEvaluationDate // >0 
                        nextEvaluationDate = twoAMOfLastEvaluationDate.add(deltaDays, 'days')
                    }
                    // console.log('nextEvaluationDate: ', nextEvaluationDate.toISOString())
                    return nextEvaluationDate.toISOString()
                }
            }
        } catch (error) {
            console.log('Error: ', error)
            return 'Error'
        }
    }

    const addSubtask = () => {
        const newProgramme = { ...programme };
        newProgramme.subs.push({
            sname: '',
            frequencyType: 'daily',
            dayOfWeek: [],
            dayOfMonth: [],
            lastEvaluationDate: moment().toISOString(),
            dayOfWeekText: '',
            dayOfMonthText: '',
        });
        setProgramme(newProgramme);
    }

    const deleteSubtask = (index) => {
        const newProgramme = { ...programme };
        newProgramme.subs.splice(index, 1);
        setProgramme(newProgramme);
    }

    const save = async () => {
        let mProgramme = _.cloneDeep(programme);
        if (mProgramme.hasOwnProperty("_id")) {
            delete mProgramme._id;
        }
        mProgramme.subs.forEach(sub => {
            delete sub.dayOfWeekText;
            delete sub.dayOfMonthText;
            if (sub.hasOwnProperty("forceEvaluation")) {
                if (!sub.forceEvaluation) {
                    delete sub.forceEvaluation;
                }
            }
        });

        let result = await fetch('https://paymemobile.fr/editschedule?token=' + token, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: "data=" + JSON.stringify(mProgramme)
        });

        let data = await result.json();
        alert(JSON.stringify(data));
    }



    return (
        <div className="AppRootDesktop">
            <div className='AppRootInnerOverlay'>
                <div className='BodyEdit'>
                    <div className='SchedulePicker'>
                        <h1>Programmes</h1>
                        <div>
                            {/* <div>Selected programme: {selectedTemplateId}</div> */}
                            <Form.Select aria-label="Select a schedule programme" onChange={handleTemplateChange}>
                                <option>Select a schedule programme</option>
                                {schedules.map((schedule, index) => (
                                    <option key={index} value={schedule.stampId}>{schedule.stampId}</option>
                                ))}
                            </Form.Select>
                        </div>
                    </div>

                    <div className='ScheduleEditor'>
                        <h1>Editor</h1>
                        <p>Available subtask names: {templateSNames.join(', ')}</p>
                        <p>Available frequency types: daily, weekly, everyNWeeks, monthly, everyNMonths</p>
                        {programme && (
                            <div>
                                {programme.subs.map((sub, index) => (
                                    <div className='ScheduleItem' key={index}>
                                        <div className='ItemItem'>
                                            <div>Subtask name: </div>
                                            <div>
                                                <input type='text' value={sub.sname} onChange={(event) => handleTextChange(index, event.target.value, 'sname')} />
                                            </div>
                                        </div>
                                        <div className='ItemWarn'>
                                            {templateSNames.includes(sub.sname) ? (
                                                <></>
                                            ) : (
                                                <span>Subtask name is invalid</span>
                                            )}
                                        </div>
                                        <div className='ItemItem'>
                                            <div>Frequency type: </div>
                                            <div><input type='text' value={sub.frequencyType} onChange={(event) => handleTextChange(index, event.target.value, 'frequencyType')} /></div>
                                        </div>
                                        <div className='ItemItem'>
                                            <div>Day of week: </div>
                                            <div><input type='text' value={sub.dayOfWeekText} onChange={(event) => handleTextChange(index, event.target.value, 'dayOfWeek')} /></div>
                                        </div>
                                        <div className='ItemItem'>
                                            <div>Day of month: </div>
                                            <div><input type='text' value={sub.dayOfMonthText} onChange={(event) => handleTextChange(index, event.target.value, 'dayOfMonth')} /></div>
                                        </div>
                                        <div className="ItemItem">
                                            <div>Last evaluation: </div>
                                            <div><input type='text' value={sub.lastEvaluationDate} onChange={(event) => handleTextChange(index, event.target.value, 'lastEvaluationDate')} /></div>
                                        </div>
                                        <div className='ItemItem'>
                                            <div>Next evaluation: </div>
                                            <div className="NextEvaGreen">{moment(getNextEvaluationDate(sub.frequencyType, sub.dayOfWeek, sub.dayOfMonth, sub.lastEvaluationDate, sub.forceEvaluation)).format('dddd, MMMM Do YYYY, HH:mm')}</div>
                                        </div>
                                        <div className="ItemItem">
                                            <div>Force eval on LED: </div>
                                            <div><Form.Check type='switch'
                                                id={`forceEvaluationSwitch-${index}`}
                                                label=''
                                                onChange={(event) => handleTextChange(index, event.target.checked, 'forceEvaluation')}
                                                checked={sub.forceEvaluation}
                                            /></div>
                                        </div>
                                        <div className='ButtonRow'>
                                            <Button variant='danger' onClick={() => deleteSubtask(index)}>Delete</Button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        <div className='ButtonRow'>
                            <Button variant='success' onClick={addSubtask}>Add subtask</Button>
                            <Button variant='primary' onClick={save}>Save</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Scheduler;