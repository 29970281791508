const ellipsizeString = (str) => {
    if (str === undefined)
    {
        return ''
    }
    // if string is longer than 10 characters, only keep the first 10 characters
    if (str.length > 10)
    {
        return str.substring(0, 10) + '...'
    }
    return str
}

export default ellipsizeString