import React, { useState, useEffect } from 'react';
import './Advice.css'
import { Button } from 'react-bootstrap';

const Advice = ({callback}) => {
    return(<>
        <div className="advisory">
            <div className="advisoryCell">
                <div className="advisoryPic">
                    <img src={require("./AdviceIcons/phoneoff.png")} alt='advisory1' style={{width: 60}}></img>
                </div>
                <div className="advisoryText">Put your phones away</div>
            </div>
            <div className="advisoryCell">
                <div className="advisoryPic">
                    <img src={require("./AdviceIcons/siteoff.png")} alt='advisory1' style={{width: 60}}></img>
                </div>
                <div className="advisoryText">Close unnecessary tabs and apps</div>
            </div>
            <div className="advisoryCell">
                <div className="advisoryPic">
                    <img src={require("./AdviceIcons/lock.png")} alt='advisory1' style={{width: 60}}></img>
                </div>
                <div className="advisoryText">Environment: clothing, bed and silence</div>
            </div>
        </div>
        <div className='advisoryAction'>
            <Button variant="primary" size="lg" onClick={() => {
                if (callback) {
                    callback();
                    if (window.adviceTimer != null)
                    {
                        window.clearTimeout(window.adviceTimer)
                        window.adviceTimer = undefined
                    }
                }
            }}>Let's Go</Button>
        </div>
    </>)
}

export default React.memo(Advice);