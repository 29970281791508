import React, { useEffect } from "react";
import { collection, getDocs, query, doc, setDoc, getDoc} from "firebase/firestore"; 
import db from './Firebase'

function Backup() {

	const getSubtasks  = () => {
		// getDocs
		const q = query(collection(db, "subtasks"));
		getDocs(q).then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				let dat = doc.data();
				console.log(JSON.stringify(dat));
			});
		}
		);
	}

	const getSubSubHistory = () => {
		// getDocs
		const q = query(collection(db, "subsubHistory"));
		getDocs(q).then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				let dat = doc.data();
				console.log(JSON.stringify(dat));
			});
		}
		);
	}

	const getTimerHistory = () => {
		// getDocs
		const q = query(collection(db, "timerHistory"));
		getDocs(q).then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				let dat = doc.data();
				console.log(JSON.stringify(dat));
			});
		}
		);
	}

	const getTodos = () => {
		// getDocs
		const q = query(collection(db, "todo"));
		getDocs(q).then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				let dat = doc.data();
				console.log(JSON.stringify(dat));
			});
		}
		);
	}

	useEffect(() => {
		getSubtasks();
		getSubSubHistory();
		getTimerHistory();
		getTodos();
	}, []);

	return <div>Backup</div>;
}

export default Backup;
