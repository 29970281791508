const convertToHourMinute = (minutex, elapsed = false) => {
    try {
        minutex = Math.round(minutex * 100000) / 100000
        let minute = Math.abs(minutex)
        let hour = Math.floor(minute / 60)
        let min = Math.round(minute - hour * 60)

        if (min == 60)
        {
            hour += 1
            min = 0
        }
        
        if (min < 10)
        {
            if (minutex < 0)
            {
                return "-" + String(hour) + ":0" + String(min)
            } else {
                return String(hour) + ":0" + String(min)
            }
        } else {
            if (minutex < 0)
            {
                return "-" + String(hour) + ":" + String(min)
            } else {
                return String(hour) + ":" + String(min)
            }
        }
    } catch (e) 
    {
        return "-"
    }
}

export default convertToHourMinute