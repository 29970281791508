import React, { useState, useEffect } from 'react';
import { FaRegClock } from 'react-icons/fa';
import formatDurationWithSeconds from './formatDurationWithSeconds';
import moment from 'moment';

const Chronometer = ({ idleSince, currentTime, setIdleSince }) => {
    const [idleDuration, setIdleDuration] = useState('')
    
    useEffect(() => {
        setIdleDuration(formatDurationWithSeconds(moment(idleSince), moment(currentTime)))
    }, [idleSince, currentTime])

    return (
        <><FaRegClock></FaRegClock><span style={{marginLeft: 5, cursor: 'pointer'}} onClick={() => setIdleSince(moment().toISOString())}>{idleDuration}</span></>
    )
}

export default React.memo(Chronometer);