import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {resetNotifications, setCalendarUpdateToken} from './calendarReducers'
import {Button} from 'react-bootstrap';
import {BsPlusCircle, BsFillPatchMinusFill, BsFillCapslockFill, BsFillGrid3X2GapFill, BsFillMoonFill, BsDashCircle} from 'react-icons/bs';
import {BiReset, BiTask, BiCheck, BiRefresh} from 'react-icons/bi';
import {MdTimer} from 'react-icons/md';
import { AiFillCaretUp, AiFillClockCircle } from 'react-icons/ai';

import moment from 'moment';

const HomeControlPanel = ({addToTimer, setFlaggedSubTasks, setFlaggedDoneSubTasks, setShowFlaggedTasksOnly, finalize, toggleCalendar, showProcastScreen,
toggleProcrastinate, loadData, markAllFlagsOfCurrentSubSubAsDone, revertDoneFlagsBackToFlagged, setAllowCalendarAdjustedTimer, setCountUpMode, setDisplayTimerOptions,
allowCalendarAdjustedTimer, countUpMode, showFlaggedTasksOnly, displayTimerOptions, toggleAutoTodo, autoTodo, allowTimerAdjustForEndOfTask, toggleTimerAdjustForEndOfTask,
toggleShowOnlyNonZerosAndCountUp, showOnlyNonZerosAndCountUp, showProcastinateCountdown, ordoscopeVersion}) => {

    const dispatch = useDispatch();

    return(
        <div className="TimerCommandButtons TimerDashboard">
            <div className="TimerCommandButtonsRows CommandButtonsAux">
                <Button variant="dark" size='sm' onClick={() => {addToTimer(1)}}><div className="ButtonAlign"><BsPlusCircle className="ButtonGlyph"></BsPlusCircle><span>1</span></div></Button>
                <Button variant="dark" size='sm' onClick={() => {addToTimer(2)}}><div className="ButtonAlign"><BsPlusCircle className="ButtonGlyph"></BsPlusCircle><span>2</span></div></Button>
                <Button variant="dark" size='sm' onClick={() => {addToTimer(5)}}><div className="ButtonAlign"><BsPlusCircle className="ButtonGlyph"></BsPlusCircle><span>5</span></div></Button>
                <Button variant="dark" size='sm' onClick={() => {addToTimer(10)}}><div className="ButtonAlign"><BsPlusCircle className="ButtonGlyph"></BsPlusCircle><span>10</span></div></Button>
            </div>
            <div className="TimerCommandButtonsRows CommandButtonsAux">
                <Button variant="dark" size='sm' onClick={() => {addToTimer(-1)}}><div className="ButtonAlign"><BsDashCircle className="ButtonGlyph"></BsDashCircle><span>1</span></div></Button>
                <Button variant="dark" size='sm' onClick={() => {addToTimer(-2)}}><div className="ButtonAlign"><BsDashCircle className="ButtonGlyph"></BsDashCircle><span>2</span></div></Button>
                <Button variant="dark" size='sm' onClick={() => {addToTimer(-5)}}><div className="ButtonAlign"><BsDashCircle className="ButtonGlyph"></BsDashCircle><span>5</span></div></Button>
                <Button variant="dark" size='sm' onClick={() => {addToTimer(-10)}}><div className="ButtonAlign"><BsDashCircle className="ButtonGlyph"></BsDashCircle><span>10</span></div></Button>
            </div>
            <div className="TimerCommandButtonsRows CommandButtonsAux">
                <Button variant="dark" size='sm' onClick={() => {dispatch(resetNotifications())}}><div className="ButtonAlign"><BiReset className="ButtonGlyph"></BiReset><span>NTF</span></div></Button>
                <Button variant="dark" size='sm' onClick={() => { setFlaggedSubTasks([]); setFlaggedDoneSubTasks([]) }}><div className="ButtonAlign"><BiReset className="ButtonGlyph"></BiReset><span>FLG</span></div></Button>
                <Button variant={`${showFlaggedTasksOnly ? 'primary' : 'dark'}`} size='sm' onClick={() => { setShowFlaggedTasksOnly(!showFlaggedTasksOnly) }}><div className="ButtonAlign"><BsFillGrid3X2GapFill className="ButtonGlyph"></BsFillGrid3X2GapFill><span>OFL</span></div></Button>
                <Button variant="dark" size='sm' onClick={() => {dispatch(setCalendarUpdateToken())}}><div className="ButtonAlign"><BsFillGrid3X2GapFill className="ButtonGlyph"></BsFillGrid3X2GapFill><span>CLD</span></div></Button>
            </div>
            <div className="TimerCommandButtonsRows CommandButtonsAux">
                <Button variant="success" size='sm' onClick={() => {finalize()}}><div className="ButtonAlign"><BsFillCapslockFill className="ButtonGlyph"></BsFillCapslockFill><span>SND</span></div></Button>
                <Button variant="success" size='sm' onClick={() => {finalize(true)}}><div className="ButtonAlign"><BsFillCapslockFill className="ButtonGlyph"></BsFillCapslockFill><span>SNF</span></div></Button>
                <Button variant="dark" size='sm' onClick={() => {toggleCalendar(1)}}><div className="ButtonAlign"><BsFillGrid3X2GapFill className="ButtonGlyph"></BsFillGrid3X2GapFill><span>VW+</span></div></Button>
                <Button variant="dark" size='sm' onClick={() => {toggleCalendar(-1)}}><div className="ButtonAlign"><BsFillGrid3X2GapFill className="ButtonGlyph"></BsFillGrid3X2GapFill><span>VW-</span></div></Button>
            </div>
            <div className="TimerCommandButtonsRows CommandButtonsAux">
                <Button variant={`${!displayTimerOptions ? 'primary' : 'dark'}`} size='sm' onClick={() => {setDisplayTimerOptions(!displayTimerOptions)}}><div className="ButtonAlign"><BsFillMoonFill className="ButtonGlyph"></BsFillMoonFill><span>FCS</span></div></Button>
                <Button variant={`${showProcastinateCountdown ? 'primary' : 'dark'}`} size='sm' onClick={() => {toggleProcrastinate()}}><div className="ButtonAlign"><BiReset className="ButtonGlyph"></BiReset><span>PRC</span></div></Button>
                <Button variant="dark" size='sm' onClick={() => {loadData()}}><div className="ButtonAlign"><BiRefresh className="ButtonGlyph"></BiRefresh><span>LTS</span></div></Button>
                <Button variant="dark" size='sm' onClick={() => {markAllFlagsOfCurrentSubSubAsDone()}}><div className="ButtonAlign"><BiCheck className="ButtonGlyph"></BiCheck><span>DST</span></div></Button>
            </div>
            <div className="TimerCommandButtonsRows CommandButtonsAux">
                <Button variant="dark" size='sm' onClick={() => {revertDoneFlagsBackToFlagged()}}><div className="ButtonAlign"><BiReset className="ButtonGlyph"></BiReset><span>RVF</span></div></Button>
                <Button variant="dark" size='sm' onClick={() => {showProcastScreen()}}><div className="ButtonAlign"><BiTask className="ButtonGlyph"></BiTask><span>TDO</span></div></Button>
                <Button variant="danger" size='sm' onClick={() => {
                    if (ordoscopeVersion && ordoscopeVersion !== '' && ordoscopeVersion !== null && ordoscopeVersion !== undefined) {
                        window.location.href = `/?scope=${ordoscopeVersion}`;
                    } else {
                        window.location.href = '/';
                    }
                }}><div className="ButtonAlign"><BsFillGrid3X2GapFill className="ButtonGlyph"></BsFillGrid3X2GapFill><span>SPL</span></div></Button>
                <Button variant={`${allowCalendarAdjustedTimer ? 'primary' : 'dark'}`} size='sm' onClick={() => {setAllowCalendarAdjustedTimer(!allowCalendarAdjustedTimer)}}><div className="ButtonAlign"><MdTimer className="ButtonGlyph"></MdTimer><span>CTM</span></div></Button>
            </div>
            <div className="TimerCommandButtonsRows CommandButtonsAux">
                <Button variant={`${countUpMode ? 'primary' : 'dark'}`} size='sm' onClick={() => {
                    document.documentElement.scrollTop = 0;
                    setCountUpMode(!countUpMode);
                    }}><div className="ButtonAlign"><AiFillCaretUp className="ButtonGlyph"></AiFillCaretUp><span>UP</span></div></Button>
                <Button variant={`${autoTodo ? 'primary' : 'dark'}`} size='sm' onClick={() => {toggleAutoTodo()}}><div className="ButtonAlign"><BiTask className="ButtonGlyph"></BiTask><span>ATD</span></div></Button>
                <Button variant={`${allowTimerAdjustForEndOfTask ? 'primary' : 'dark'}`} size='sm' onClick={() => {toggleTimerAdjustForEndOfTask()}}><div className="ButtonAlign"><AiFillClockCircle className="ButtonGlyph"></AiFillClockCircle><span>AET</span></div></Button>
                <Button variant={`${showOnlyNonZerosAndCountUp ? 'primary' : 'dark'}`} size='sm' onClick={toggleShowOnlyNonZerosAndCountUp}><BsFillGrid3X2GapFill className="ButtonGlyph"></BsFillGrid3X2GapFill> NZC</Button>
            </div>
            
            <div className="TimerCommandButtonsRows">
                {/* <Button onClick={() => {
                    dispatch(setOverlayNotification({
                        title: "Test Notification",
                        message: "This is a test notification",
                        show: true
                    }))}}>Test overlay notification</Button> */}
            </div>
        </div>
    )
}

export default React.memo(HomeControlPanel);