import './Checklist.css'
import Dropdown from 'react-bootstrap/Dropdown'
import React from 'react'
import { useEffect, useState } from 'react'

import { FaCheck, FaInfoCircle, FaToggleOn } from 'react-icons/fa'
import { MdClose } from 'react-icons/md'
import { Button } from 'react-bootstrap'

function Checklist ({show, onClose}) 
{
    let dotsString = ".................................................................................................................................................................................................................................................................................................................................................................................................................................................................."
    const [checklist, setChecklist] = useState("anger")

    const angerChecklist = [
        {
            'comment': 'Unfairness is unpleasant, but this broken world is like that. But these emotions will pass.'
        },
        {
            'item': 'IMPORTANT MEETINGS',
            'action': 'ABSTAIN & CLEAR SCHEDULE'
        }, 
        {
            'item': 'IMPORTANT DECISIONS',
            'action': 'WITHHOLD'
        },
        {
            'item': 'EMAIL REPLIES',
            'action': 'WITHHOLD'
        },
        {
            'item': 'CASUAL MEETINGS',
            'action': 'RECONSIDER'
        },
        {
            'item': 'MEDITATION',
            'action': '3M BREATHING'
        },
        {
            'item': 'DAILY WORK PLAN',
            'action': 'CLEAR'
        },
        {
            'item': 'DIARY',
            'action': 'WRITE'
        },
        {
            'item': 'CBT AUTOMATIC THOUGHTS',
            'action': 'RESTRUCTURE'
        },
        {
            'item': 'BREAK / EXERCISE',
            'action': 'TAKE'
        }
    ]

    const anxietyChecklist = [
        {
            'comment': 'These are just thoughts and emotions. They are often not reality. They do not threaten you or your goals. They will go away quickly.',
        },
        {
            'item': 'MEDITATION',
            'action': '3M BREATHING'
        },
        {
            'item': 'DIARY',
            'action': 'WRITE'
        },
        {
            'item': 'CBT AUTOMATIC THOUGHTS',
            'action': 'RESTRUCTURE'
        },
        {
            'item': 'BREAK / EXERCISE',
            'action': 'TAKE'
        }
    ]

    const procrastinationChecklist = [
        {
            "comment": "It takes time to get started. The key is to let go of perfectionism."
        },
        {
            'item': 'PAST TODOS',
            'action': 'FORGET & FORGIVE'
        }, 
        {
            'item': 'PHONES',
            'action': 'SILENT & PUT AWAY'
        },
        {
            'item': 'COMPUTERS',
            'action': 'TURN OFF'
        },
        {
            'item': 'MUSIC',
            'action': 'AS ADVISED'
        },
        {
            'item': 'TIMER',
            'action': '15 MIN'
        },
        {
            'item': 'TIMER',
            'action': 'START'
        }
    ]

    const [checklistItems, setChecklistItems] = useState(angerChecklist)

    useEffect(() => {
        if (checklist === "anger")
        {
            setChecklistItems(angerChecklist)
        }
        if (checklist === "procrastination")
        {
            setChecklistItems(procrastinationChecklist)
        }
        if (checklist === "anxiety")
        {
            setChecklistItems(anxietyChecklist)
        }
    }, [checklist])

    const checkItem = (index) => {
        console.log(index)
        let newChecklistItems = [...checklistItems]
        newChecklistItems[index].checked = newChecklistItems[index].checked != null ? !newChecklistItems[index].checked : true
        setChecklistItems(newChecklistItems)
    }

    const toggleCheckAll = () => {
        let newChecklistItems = [...checklistItems]
        let checkBoolean = newChecklistItems[0].checked != null ? !newChecklistItems[0].checked : true
        newChecklistItems.forEach((item) => {
            item.checked = checkBoolean
        })
        setChecklistItems(newChecklistItems)
    }

    return(
        <div className={`AppV AppOverlay ChecklistApp`} style={{display: show ? '' : 'none'}} >
            <div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                <Dropdown size="sm">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {checklist}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setChecklist("anger")}>Anger</Dropdown.Item>
                        <Dropdown.Item onClick={() => setChecklist("procrastination")}>Procrastination</Dropdown.Item>
                        <Dropdown.Item onClick={() => setChecklist("anxiety")}>Anxiety</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <Button variant="primary" size="sm" style={{marginLeft: 4}} onClick={toggleCheckAll}><FaToggleOn></FaToggleOn> Toggle</Button>
                <Button variant="danger" size="sm" style={{marginLeft: 4}} onClick={onClose}><MdClose></MdClose> Close</Button>
                </div>

                {
                    <div>

                        <div style={{ marginTop: 16 }}></div>
                        {checklistItems.map((item, index) => {
                            if (item.action == null)
                            {
                                return <></>
                            }
                            return (
                                <div className={`ChecklistItem`} onClick={() => checkItem(index)}>
                                    <div className={`${item.checked ? 'checkeditem' : 'item'}`}>{item.item}</div>
                                    <div className="dots">{dotsString}</div>
                                    <div className={`${item.checked ? 'checkedaction' : 'action'}`}>{item.action}</div>
                                    <div className="check">{item.checked ? <FaCheck></FaCheck> : ''}</div>
                                </div>
                            )
                        })}

                        {checklistItems[0].comment != null &&
                            <div className='checklistComment'>
                                <FaInfoCircle></FaInfoCircle> {checklistItems[0].comment}
                            </div>
                        }

                    </div> 
                }

            </div>
        </div>
    )
}

export default React.memo(Checklist)