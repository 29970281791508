import {FaTemperatureHigh, FaWater, FaWind, FaSun, FaCloudRain, FaCloud} from 'react-icons/fa';
import React from 'react';

import './WeatherDashboard.css';
import { useSelector } from 'react-redux';

import moment from 'moment';

const WeatherDashboard = ({weather}) => {

    return(
        <>
        <div class="VSpacing"></div>
        <div className="LargeWeatherDiv">
            <div className="WeatherAtAGlance">
                <div className="WeatherAtAGlanceIcon">
                    <img src={weather.icon} alt="Weather Icon" style={{width: '96px'}} />
                </div>
                <div className="BigTemperature">{Number(weather.temp).toFixed(0)}°C</div>
            </div>
            <div>
                <div className="WeatherAtAGlanceDesc">{weather.description.length > 20 ? weather.description.substring(0, 20) + '...' : weather.description}</div>
            </div>
        </div>
        <div class="VSpacing"></div>
        <div className="ClockWeatherInfo">
            <div className="ClockWeatherInfoRow">
                <div className="ClockWeatherInfoCell">
                    <div className="ClockWeatherInfoCellIcon"><FaTemperatureHigh /></div>
                    <div className="ClockWeatherInfoCellText">
                        <div className="ClockWeatherInfoCellTitle">FEELS</div>
                        <div className="ClockWeatherInfoCellDesc">{weather.feelslike} °C</div>
                    </div>
                </div>

                <div className="ClockWeatherInfoCell">
                    <div className="ClockWeatherInfoCellIcon"><FaWater /></div>
                    <div className="ClockWeatherInfoCellText">
                        <div className="ClockWeatherInfoCellTitle">HUMID</div>
                        <div className="ClockWeatherInfoCellDesc">{weather.humidity} %</div>
                    </div>
                </div>

                <div className="ClockWeatherInfoCell">
                    <div className="ClockWeatherInfoCellIcon"><FaWind /></div>
                    <div className="ClockWeatherInfoCellText">
                        <div className="ClockWeatherInfoCellTitle">WIND</div>
                        <div className="ClockWeatherInfoCellDesc">{weather.wind}</div>
                    </div>
                </div>
            </div>
            <div className="ClockWeatherInfoRow">
                <div className="ClockWeatherInfoCell">
                    <div className="ClockWeatherInfoCellIcon"><FaSun /></div>
                    <div className="ClockWeatherInfoCellText">
                        <div className="ClockWeatherInfoCellTitle">UV</div>
                        <div className="ClockWeatherInfoCellDesc">{weather.uvIndex}</div>
                    </div>
                </div>

                <div className="ClockWeatherInfoCell">
                    <div className="ClockWeatherInfoCellIcon"><FaCloudRain /></div>
                    <div className="ClockWeatherInfoCellText">
                        <div className="ClockWeatherInfoCellTitle">RAIN</div>
                        <div className="ClockWeatherInfoCellDesc">{weather.precipitation}</div>
                    </div>
                </div>

                <div className="ClockWeatherInfoCell">
                    <div className="ClockWeatherInfoCellIcon"><FaCloud /></div>
                    <div className="ClockWeatherInfoCellText">
                        <div className="ClockWeatherInfoCellTitle">CLOUD</div>
                        <div className="ClockWeatherInfoCellDesc">{weather.cloud} %</div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default React.memo(WeatherDashboard);
